import BaseButton from '@/components/base/button.component';
import DialogSignUp from '@/components/dialogs/sign-up-dialog.component';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';

// TODO: make render of mobile elements more concise
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '300px',
    margin: '0 auto',
    display: 'flex',
    marginBottom: 48,
    background: 'linear-gradient(81.45deg, #004FC6 0%, #0AFB4C 100%)',
  },
  heroContainer: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    height: '100%',

    margin: '0 auto',

    [theme.breakpoints.down('md')]: {
      marginBottom: 96,
    },
  },
  heroContainerInner: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    position: 'relative',

    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      justifyContent: 'center',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      justifyContent: 'space-around',
      maxWidth: 928,
      margin: '0 auto',
    },
  },
  heroCta: {
    order: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'center',
      display: 'block',
    },
  },
  heroBannerTitle: {
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '36px !important',
    },
  },
  ctaButtonStyle: {
    width: 190,
    // marginTop: 20,
  },
  ctaTextStyle: {
    fontSize: '18px !important',
    fontWeight: '700 !important',
  },
  heroBannerSubtitle: {
    display: 'block',
    marginTop: '-15px !important',

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '48px !important',
    },

    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
  heroTitle: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    marginBottom: 12,
  },
  bannerElementsDesktopInner: {
    display: 'block',
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
      maxHeight: 428,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 605,
    },
  },
}));

type DialogTypes = 'sign-up';

const ShopBlock = () => {
  const classes = useStyles();
  const [openSignUp, setOpenSignUp] = useState(false);
  const dialogOpenHandlers = {
    'sign-up': () => setOpenSignUp(true),
  };

  const openDialogHandler = (type: DialogTypes) => dialogOpenHandlers[type]();

  const closeDialogHandler = useCallback(() => {
    setOpenSignUp(false);
  }, []);

  return (
    <>
      <section className={classes.container}>
        <Box className={classes.heroContainer}>
          <div className={classes.heroContainerInner}>
            <div className={classes.heroCta}>
            <div className={classes.heroTitle}>
              <Typography variant='heroBannerTitle' className={classes.heroBannerTitle}>
                Hurry Up! Special Deals on 
                <Divider component='br' />
                <Typography variant='heroBannerSubtitle' className={classes.heroBannerSubtitle}>
                  Premium Bundles
                </Typography>
              </Typography>
            </div>
            <BaseButton
              color='primary'
              size='large'
              text='Visit the store'
              className={classes.ctaButtonStyle}
              textStyle={classes.ctaTextStyle}
              onClick={() => openDialogHandler('sign-up')}
            />
          </div>
          </div>
        </Box>
      </section>
      <DialogSignUp open={openSignUp} closeHandler={closeDialogHandler} />
    </>
  );
};

export default ShopBlock;
