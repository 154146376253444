// import { subscribeOnTeleport } from 'actions/explorers/thunk-actions';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  sendInvites,
  disconnectChat,
  setDisconnectedAvailableUsers,
  setUpdateAvailableUsers,
  acceptInvite,
  declineInvite,
  incomingInvite,
  assume,
  rightNumbers,
  youWonRequest,
  sendGameId,
} from '@/app/users/users.actions';
import { StorageService } from '@/services/storage.service';
import { useCallback, useEffect, useState } from 'react';
import Socket from 'services/Socket';
import useSound from 'use-sound';
import letterSound from '@/assets/sounds/letter.mp3';
import messageSound from '@/assets/sounds/massage.mp3';
import { useLocation } from 'react-router-dom';
import { EventTypeEnum } from '@/utils/ws.enum';
import { countMatchingCharacters } from '@/helpers/helpers';

const SocketSubscriber = () => {
  // const [isLoaded, setLoading] = useState(false);
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const currentGame = useAppSelector((state) => state.users.currentGame);
  const gameHistory = useAppSelector((state) => state.users.gameHistory);
  const dispatch = useAppDispatch();

  const [playbackRate] = useState(1);

  const [play] = useSound(letterSound, {
    playbackRate,
    interrupt: true,
  });

  const [playMessage] = useSound(messageSound, {
    playbackRate,
    // `interrupt` ensures that if the sound starts again before it's
    // ended, it will truncate it. Otherwise, the sound can overlap.
    interrupt: true,
  });

  const receiveLetterSound = () => {
    const isAudioTurnOn = StorageService.getAudio();
    if (isAudioTurnOn) {
      play();
    }
  };

  const connectToSocket = useCallback(async () => {
    const token = StorageService.getToken();
    if (token && currentUser) {
      await Socket.connect();
      init();
    } else {
      Socket.disconnect();
    }
  }, [currentUser]);

  useEffect(() => {
    connectToSocket();
  }, [connectToSocket, currentUser]);

  const location = useLocation();

  const dataReceiveOwnCallback = useCallback(
    (data: any) => {
      switch (data.type) {
        case EventTypeEnum.invite: {
          dispatch(
            incomingInvite({
              userId: data.data.opponentUserId,
              opponentUserId: data.data.userId,
              credits: data.data.credits,
              isMyInvite: true,
            })
          );
          break;
        }
        case EventTypeEnum.accept: {
          dispatch(
            acceptInvite({
              userId: data.data.opponentUserId,
              opponentUserId: data.data.userId,
              credits: data.data.credits,
              isMyInvite: true,
            })
          );

          break;
        }
        case EventTypeEnum.decline: {
          dispatch(
            declineInvite({
              userId: data.data.userId,
              opponentUserId: data.data.opponentUserId,
              credits: data.data.credits,
              isMyInvite: true,
            })
          );

          break;
        }
        case EventTypeEnum.proposeNumber: {
          dispatch(
            assume({
              userId: data.data.userId,
              opponentUserId: data.data.opponentUserId,
              proposeValue: data.data.proposeValue,
              isMy: true,
            })
          );

          break;
        }

        default:
          break;
      }
      console.log('data', data);
      receiveLetterSound();
    },
    [dispatch, receiveLetterSound]
  );

  const dataReceiveCallback = useCallback(
    (data: any) => {
      switch (data.type) {
        case EventTypeEnum.invite: {
          dispatch(
            incomingInvite({
              userId: data.data.userId,
              opponentUserId: data.data.opponentUserId,
              credits: data.data.credits,
              isMyInvite: false,
            })
          );
          break;
        }
        case EventTypeEnum.accept: {
          dispatch(
            acceptInvite({
              userId: data.data.userId,
              opponentUserId: data.data.opponentUserId,
              credits: data.data.credits,
              isMyInvite: false,
            })
          );

          break;
        }
        case EventTypeEnum.decline: {
          dispatch(
            declineInvite({
              userId: data.data.userId,
              opponentUserId: data.data.opponentUserId,
              credits: data.data.credits,
              isMyInvite: false,
            })
          );

          break;
        }

        case EventTypeEnum.proposeNumber: {
          dispatch(
            assume({
              userId: data.data.opponentUserId,
              opponentUserId: data.data.userId,
              proposeValue: data.data.proposeValue,
              isMy: false,
            })
          );

          break;
        }

        case EventTypeEnum.rightNumbers: {
          dispatch(
            rightNumbers({
              rightNumbers: data.data.rightNumbers,
            })
          );

          break;
        }

        case EventTypeEnum.youWon: {
          dispatch(
            youWonRequest({
              rightNumbers: data.data.rightNumbers,
            })
          );

          break;
        }

        case EventTypeEnum.sendGameId: {
          dispatch(
            sendGameId({
              gameId: data.data.gameId,
            })
          );

          break;
        }

        default:
          break;
      }
      receiveLetterSound();
    },
    [dispatch, receiveLetterSound, gameHistory]
  );

  const init = useCallback(() => {
    if (Socket && Socket.socket) {
      Socket.on(
        'dataReceiveOwn',
        (data: {
          toUserId: string;
          data: {
            type: string;
            data: any;
          };
        }) => {
          dataReceiveOwnCallback(data.data);
        }
      );
      Socket.on(
        'dataReceive',
        (data: {
          id: string;
          data: {
            type: string;
            data: any;
          };
        }) => {
          dataReceiveCallback(data.data);
        }
      );

      Socket.on('onlineConnected', (data: any) => {
        dispatch(setUpdateAvailableUsers(data));
      });
      Socket.on('onlineDisconnected', (data: any) => {
        dispatch(setDisconnectedAvailableUsers(data));
      });
    }
  }, [dataReceiveCallback, dataReceiveOwnCallback, dispatch, gameHistory]);

  return null;
};

export default SocketSubscriber;
