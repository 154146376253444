import { ISettings, UserProfile } from '@/types/user-profile.types';
import {
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  GET_OWNER_PROFILE_FAILURE,
  GET_OWNER_PROFILE_REQUEST,
  GET_OWNER_PROFILE_SUCCESS,
  GET_SEARCH_USERS_FAILURE,
  GET_SEARCH_USERS_REQUEST,
  GET_SEARCH_USERS_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  UPDATE_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE_REQUEST,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  GET_AVAILABLE_USERS_REQUEST,
  GET_AVAILABLE_USERS_SUCCESS,
  GET_AVAILABLE_USERS_FAILURE,
  SET_BOOKMARKS_REQUEST,
  SET_BOOKMARKS_SUCCESS,
  SET_BOOKMARKS_FAILURE,
  GET_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_FAILURE,
  DELETE_BOOKMARKS_REQUEST,
  DELETE_BOOKMARKS_SUCCESS,
  DELETE_BOOKMARKS_FAILURE,
  SET_UPDATE_AVAILABLE_USERS,
  SET_DISCONNECTED_AVAILABLE_USERS,
  DISCONNECT_CHAT,
  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_FAILED,
  CHARGE_BALANCE_REQUEST,
  CHARGE_BALANCE_SUCCESS,
  CHARGE_BALANCE_FAILED,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  USER_BALANCE_CHARGED_SUCCESS,
  SET_USER_HEADER_SEARCH,
  CHANGE_USER_PASSWORD,
  UPDATE_CURRENT_USER_AVATAR_REQUEST,
  CLEAR_AVAILABLE_USERS_SUCCESS,
  SEND_INVITES_SUCCESS,
  INCOMING_INVITES_SUCCESS,
  ACCEPT_INVITE_SUCCESS,
  DECLINE_INVITE_SUCCESS,
  START_GAME_REQUEST,
  START_GAME_SUCCESS,
  END_GAME_SUCCESS,
  ASSUME_SUCCESS,
  USER_RIGHT_NUMBERS,
  YOU_WON_SUCCESS,
  YOU_WON_REQUEST,
  SEND_GAME_ID,
} from './users.types';

//GET OWNER

export const getOwnerProfileRequest = (id: string) => ({
  type: GET_OWNER_PROFILE_REQUEST,
  id,
});

export const getOwnerProfileSuccess = (user: UserProfile) => ({
  type: GET_OWNER_PROFILE_SUCCESS,
  user,
});

export const getSearchUsersRequest = (config: any) => ({
  type: GET_SEARCH_USERS_REQUEST,
  config,
});

export const getSearchUsersSuccess = (users: Array<UserProfile>) => ({
  type: GET_SEARCH_USERS_SUCCESS,
  users,
});

export const getSearchUsersFailure = (error: unknown) => ({
  type: GET_SEARCH_USERS_FAILURE,
  error,
});

export const setBookmarksRequest = (config: UserProfile) => ({
  type: SET_BOOKMARKS_REQUEST,
  config,
});

export const setBookmarksSuccess = (user: UserProfile) => ({
  type: SET_BOOKMARKS_SUCCESS,
  user,
});

export const setBookmarksFailure = (error: unknown) => ({
  type: SET_BOOKMARKS_FAILURE,
  error,
});

export const getBookmarksRequest = (config: any) => ({
  type: GET_BOOKMARKS_REQUEST,
  config,
});

export const getBookmarksSuccess = (users: Array<UserProfile>) => ({
  type: GET_BOOKMARKS_SUCCESS,
  users,
});

export const getBookmarksFailure = (error: unknown) => ({
  type: GET_BOOKMARKS_FAILURE,
  error,
});

export const deleteBookmarksRequest = (config: UserProfile) => ({
  type: DELETE_BOOKMARKS_REQUEST,
  config,
});

export const deleteBookmarksSuccess = (id: string) => ({
  type: DELETE_BOOKMARKS_SUCCESS,
  id,
});

export const deleteBookmarksFailure = (error: unknown) => ({
  type: DELETE_BOOKMARKS_FAILURE,
  error,
});

export const updateCurrentUserRequest = (user: UserProfile) => ({
  type: UPDATE_CURRENT_USER_PROFILE_REQUEST,
  user,
});

export const updateAvatarRequest = (body: { avatar: string }) => ({
  type: UPDATE_CURRENT_USER_AVATAR_REQUEST,
  body,
});

export const updateCurrentUserSuccess = (user: UserProfile) => ({
  type: UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  user,
});
export const updateCurrentUserFailure = (error: unknown) => ({
  type: UPDATE_CURRENT_USER_PROFILE_FAILURE,
  error,
});

export const getOwnerProfileFailure = (error: unknown) => ({
  type: GET_OWNER_PROFILE_FAILURE,
  error,
});

export const getUserProfileRequest = (userId: string) => ({
  type: GET_USER_PROFILE_REQUEST,
});

export const getUserProfileSuccess = (user: UserProfile) => ({
  type: GET_USER_PROFILE_SUCCESS,
  user,
});

export const getUserProfileFailure = (error: unknown) => ({
  type: GET_USER_PROFILE_FAILURE,
  error,
});

// CLEAR USER
export const clearUserRequest = () => ({
  type: CLEAR_USER_REQUEST,
});

export const clearUserSuccess = () => ({
  type: CLEAR_USER_SUCCESS,
});

//GET AVAILABLE USERS FOR CHAT PAGE
export const getAvailableUsersRequest = (config: { name?: string; id?: string }) => ({
  type: GET_AVAILABLE_USERS_REQUEST,
  config,
});

export const getAvailableUsersSuccess = (users: Array<UserProfile>) => ({
  type: GET_AVAILABLE_USERS_SUCCESS,
  users,
});

export const getAvailableUsersFailure = (error: unknown) => ({
  type: GET_AVAILABLE_USERS_FAILURE,
  error,
});

export const clearAvailableUsersSuccess = () => ({
  type: CLEAR_AVAILABLE_USERS_SUCCESS,
});

export const setUpdateAvailableUsers = (payload: UserProfile) => ({
  type: SET_UPDATE_AVAILABLE_USERS,
  payload,
});

export const setDisconnectedAvailableUsers = (payload: UserProfile) => ({
  type: SET_DISCONNECTED_AVAILABLE_USERS,
  payload,
});

export const disconnectChat = (payload: string) => ({
  type: DISCONNECT_CHAT,
  payload,
});

export const updateBalanceRequest = () => ({
  type: UPDATE_BALANCE_REQUEST,
});

export const updateBalanceSuccess = (payload: { balance: number }) => ({
  type: UPDATE_BALANCE_SUCCESS,
  payload,
});

export const updateBalanceFailed = (error: unknown) => ({
  type: UPDATE_BALANCE_FAILED,
  error,
});

export const chargeBalanceRequest = (config: string, id: string) => ({
  type: CHARGE_BALANCE_REQUEST,
  payload: { config, id },
});

export const chargeBalanceSuccess = (payload: { balance: number }) => ({
  type: CHARGE_BALANCE_SUCCESS,
  payload,
});

export const chargeBalanceFailed = (error: unknown) => ({
  type: CHARGE_BALANCE_FAILED,
  error,
});

export const getSettingsRequest = () => ({
  type: GET_SETTINGS_REQUEST,
});

export const getSettingsSuccess = (settings: ISettings) => ({
  type: GET_SETTINGS_SUCCESS,
  payload: { settings },
});

export const getSettingsFailed = (error: unknown) => ({
  type: GET_SETTINGS_FAILED,
  error,
});

export const userBalanceChargedSuccess = (payload: { id: string }) => ({
  type: USER_BALANCE_CHARGED_SUCCESS,
  payload,
});

export const setHeaderUserSearch = (id: string | null) => ({
  type: SET_USER_HEADER_SEARCH,
  payload: id,
});

export const changeUserPasswordRequest = (password: string) => ({
  type: CHANGE_USER_PASSWORD,
  password,
});

export const sendInvites = (payload: {
  userId: string;
  opponentUserId: string;
  credits: number;
  isMyInvite: boolean;
}) => ({
  type: SEND_INVITES_SUCCESS,
  payload,
});

export const incomingInvite = (payload: {
  userId: string;
  opponentUserId: string;
  credits: number;
  isMyInvite: boolean;
}) => ({
  type: INCOMING_INVITES_SUCCESS,
  payload,
});

export const acceptInvite = (payload: {
  userId: string;
  opponentUserId: string;
  credits: number;
  isMyInvite: boolean;
}) => ({
  type: ACCEPT_INVITE_SUCCESS,
  payload,
});

export const declineInvite = (payload: {
  userId: string;
  opponentUserId: string;
  credits: number;
  isMyInvite: boolean;
}) => ({
  type: DECLINE_INVITE_SUCCESS,
  payload,
});

export const startGameRequest = (payload: {
  currentValue: string;
  userId: string;
  opponentUserId: string;
  credits: number;
  isMy: boolean;
}) => ({
  type: START_GAME_REQUEST,
  payload,
});

export const startGameSuccess = (payload: {
  gameId: string;
  currentValue: string;
  userId: string;
  opponentUserId: string;
  credits: number;
}) => ({
  type: START_GAME_SUCCESS,
  payload,
});

export const endGame = (payload: {
  currentValue: string;
  userId: string;
  opponentUserId: string;
  credits: number;
}) => ({
  type: END_GAME_SUCCESS,
  payload,
});

export const assume = (payload: {
  userId: string;
  opponentUserId: string;
  proposeValue: string;
  isMy: boolean;
}) => ({
  type: ASSUME_SUCCESS,
  payload,
});

export const rightNumbers = (payload: { rightNumbers: number;}) => ({
  type: USER_RIGHT_NUMBERS,
  payload,
});


export const sendGameId = (payload: { gameId: number;}) => ({
  type: SEND_GAME_ID,
  payload,
});

export const youWonRequest = (payload: {}) => ({
  type: YOU_WON_REQUEST,
  payload,
});

export const youWonSuccess = (payload: {}) => ({
  type: YOU_WON_SUCCESS,
  payload,
});
