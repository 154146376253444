import {
  Badge, Theme
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";

type BaseBadgeProps = {
  color?: string;
  value?: number | string;
  show?: boolean;
  size?: "small" | "default" | string;
  badgeClass?: string;
  children?: React.ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  badge: {
    borderRadius: 9999,
    fontWeight: 700,
    lineHeight: 1,
  },
}));

// :class="[badgeColor, badgeSize, badgeClass]"

const BaseBadge = ({
  color = "#004FC6",
  value = 0,
  show = true,
  size = "default",
  badgeClass = "",
  children = null,
}: BaseBadgeProps) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <div className={classes.container}>
      {show && value && (
        <Badge
          sx={{
            "& .MuiBadge-badge": {
              color: "#fff",
              backgroundColor: color,
              top: 15,
              right: -24,
              fontWeight: 600,

              [theme.breakpoints.down("lg")]: {
                top: 12,
              },
            },
          }}
          badgeContent={value}
          max={999}
        >
          {children}
        </Badge>
      )}
    </div>
  );
};

export default BaseBadge;
