export enum EventNamesEnum {
  onlineConnected = 'onlineConnected',
  onlineDisconnected = 'onlineDisconnected',
  onlineDisconnectedOwn = 'onlineDisconnectedOwn',
  msgSend = 'msgSend',
  msgReceive = 'msgReceive',
  msgReceiveOwn = 'msgReceiveOwn',
  dataSend = 'dataSend',
  dataReceive = 'dataReceive',
  dataReceiveOwn = 'dataReceiveOwn',
}

export enum EventTypeEnum {
  invite = 'invite',
  accept = 'accept',
  decline = 'decline',
  proposeNumber = 'proposeNumber',
  rightNumbers = 'rightNumbers',
  sendGameId = 'sendGameId',
  youWon = 'youWon',
  endGame = 'endGame',
}