import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Grid, Card, CardMedia, CardActionArea } from '@mui/material';
import malePicture1 from '@/assets/avatars/man1.png';
import malePicture2 from '@/assets/avatars/man2.png';
import malePicture3 from '@/assets/avatars/man3.png';
import malePicture4 from '@/assets/avatars/man4.png';
import malePicture5 from '@/assets/avatars/man5.png';
import femalePicture1 from '@/assets/avatars/woman1.png';
import femalePicture2 from '@/assets/avatars/woman2.png';
import femalePicture3 from '@/assets/avatars/woman3.png';
import femalePicture4 from '@/assets/avatars/woman4.png';
import { updateAvatarRequest } from '@/app/users/users.actions';


const avatarNameToImage = {
  man1: malePicture1,
  man2: malePicture2,
  man3: malePicture3,
  man4: malePicture4,
  man5: malePicture5,
  woman1: femalePicture1,
  woman2: femalePicture2,
  woman3: femalePicture3,
  woman4: femalePicture4,
};

const AvatarSelectionModal = ({ isOpen, handleClose }) => {
  const dispatch = useDispatch();

  const handleAvatarClick = (avatarName) => {
    dispatch(updateAvatarRequest({ avatar: avatarName }));
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="avatar-selection-modal"
      aria-describedby="select-an-avatar-from-the-list"
    >
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <Grid container spacing={2} style={{ width: '80%', maxWidth: '600px', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
          {Object.entries(avatarNameToImage).map(([avatarName, avatarImage]) => (
            <Grid item xs={4} key={avatarName}>
              <Card>
                <CardActionArea onClick={() => handleAvatarClick(avatarName)}>
                  <CardMedia
                    component="img"
                    alt={avatarName}
                    height="140"
                    image={avatarImage}
                    title={avatarName}
                  />
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Modal>
  );
};

export default AvatarSelectionModal;
