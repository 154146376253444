import AboutUs from '@/components/additional/about-us.component';
import AntiScamPolicy from '@/components/additional/anti-scam-policy';
import ContactUs from '@/components/additional/contact-us';
import CookiePolicy from '@/components/additional/cookie-policy';
import FAQ from '@/components/additional/faq';
import PrivacyPolicy from '@/components/additional/privacy-policy';
import RefundPolicy from '@/components/additional/refund-policy';
import TermsOfUse from '@/components/additional/terms-of-service.component';
import DrawerContainer from '@/components/drawer';
import MainLayout from '@/components/layouts/main.layout';
import UnsecureRoute from '@/hocs/unsecured-route.hoc';
import EditProfile from '@/pages/edit-profile';
import EditMainInfo from '@/pages/edit-profile/components/edit-main-info.component';
import Home from '@/pages/home';
import SearchUsers from '@/pages/search-users';
import UserSettings from '@/pages/settings';
import AccountInfo from '@/pages/settings/components/general-info.component';
import UserProfile from '@/pages/user-profle';
import Contacts from '@/pages/contacts';
import { Outlet, Route, Routes } from 'react-router-dom';
import OrderHistory from '@/pages/order-history';
import Credits from '@/pages/credits';
import PaymentHistory from '@/pages/payment-history';
import ComplaintPolicy from '@/components/additional/complaint-policy';
import CancellationPolicy from '@/components/additional/cancellation-policy';

export const MainRouter = () => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <Home />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/terms-of-service'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <TermsOfUse />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/privacy-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <PrivacyPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/complaint-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <ComplaintPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/anti-scam-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <AntiScamPolicy />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/cookie-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <CookiePolicy />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/faq'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <FAQ />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/refund-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <RefundPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/cancellation-policy'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <CancellationPolicy />
              </MainLayout>
            )}
          />
        }
      ></Route>

      <Route
        path='/about'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <AboutUs />
              </MainLayout>
            )}
          />
        }
      ></Route>
      <Route
        path='/contacts'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <ContactUs />
              </MainLayout>
            )}
          />
        }
      />
      <Route
        path='/user'
        element={
          <UnsecureRoute
            component={() => (
              <MainLayout>
                <DrawerContainer>
                  <Outlet />
                </DrawerContainer>
              </MainLayout>
            )}
          />
        }
      >
        <Route path='/user/search' element={<SearchUsers />} />
        <Route path='/user/wallet' element={<SearchUsers />} />
        <Route path='/user/:id' element={<UserProfile />} />
        <Route path='/user/credits' element={<Credits />} />

        <Route path='/user/contacts' element={<Contacts />} />

        <Route path='/user/order-history' element={<OrderHistory />} />
        <Route path='/user/payments' element={<PaymentHistory />} />

        <Route path='/user/edit' element={<EditProfile />}>
          <Route index element={<EditMainInfo />} />
        </Route>

        <Route path='/user/settings' element={<UserSettings />}>
          <Route index element={<AccountInfo />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRouter;
