import malePicture from "@/assets/avatars/man1.png";
import getImageByAvatar from './getImageByAvatar';


const useBlankAvatar = (avatarName: string) => {
  return {
    currentUserPicture: malePicture,
    oppositeUserPicture: getImageByAvatar(avatarName),
  };
};

export default useBlankAvatar;
