import malePicture1 from '@/assets/avatars/man1.png';
import malePicture2 from '@/assets/avatars/man2.png';
import malePicture3 from '@/assets/avatars/man3.png';
import malePicture4 from '@/assets/avatars/man4.png';
import malePicture5 from '@/assets/avatars/man5.png';
import femalePicture1 from '@/assets/avatars/woman1.png';
import femalePicture2 from '@/assets/avatars/woman2.png';
import femalePicture3 from '@/assets/avatars/woman3.png';
import femalePicture4 from '@/assets/avatars/woman4.png';

const avatarNameToImage = {
  man1: malePicture1,
  man2: malePicture2,
  man3: malePicture3,
  man4: malePicture4,
  man5: malePicture5,
  woman1: femalePicture1,
  woman2: femalePicture2,
  woman3: femalePicture3,
  woman4: femalePicture4,
};

const getImageByAvatar = (avatarName: string) => {
  if (avatarNameToImage[avatarName]) {
    return avatarNameToImage[avatarName];
  }
  return malePicture1;
};

export default getImageByAvatar;
