import DesktopImg from '@/assets/images/desktop-banner.png';
import TabletImg from '@/assets/images/tablet-banner.png';
import MobileImg from '@/assets/images/mobile-banner.png';
import BaseButton from '@/components/base/button.component';
import DialogSignUp from '@/components/dialogs/sign-up-dialog.component';
import { Box, Divider, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useState } from 'react';
import { ReviewBlock } from './review-block';
import ShopBlock from './shop-block';

// TODO: make render of mobile elements more concise
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    maxHeight: 700,
    minHeight: 500,
    margin: '0 auto',
    display: 'flex',
    marginBottom: 48,
    [theme.breakpoints.up('desktop')]: {
      maxWidth: `calc(${theme.breakpoints.values.xl}px * 1.22)`,
    },

    [theme.breakpoints.up('lg')]: {
      minHeight: 0,
      height: 400,
      maxHeight: '100%',
      padding: '0 !important',
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: '100%',
      padding: '64px 0',
    },
    [theme.breakpoints.down('md')]: {
      minHeight: '714px',
    },
  },
  bannerElements: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    overflow: 'hidden',
    display: 'block',
  },

  elementBase: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
  },

  heroBg: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundPosition: 'top',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      zIndex: -1,
      [theme.breakpoints.up('lg')]: {
        borderTopLeftRadius: 90,
        borderBottomLeftRadius: 90,
        backgroundImage: `url(${DesktopImg})`,
        height: '457px',
      },
      [theme.breakpoints.between('md', 'lg')]: {
        backgroundImage: `url(${TabletImg})`,
        height: '700px',
      },
      [theme.breakpoints.between('xs', 'md')]: {
        backgroundImage: `url(${MobileImg})`,
        height: '714px',
      },
    },
    width: '100%',
    position: 'absolute',
    zIndex: 0,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  heroContainer: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    height: '100%',

    margin: '0 auto',

    [theme.breakpoints.down('md')]: {
      marginBottom: 96,
    },
  },
  heroContainerInner: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    position: 'relative',

    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },

    [theme.breakpoints.between('lg', 'xl')]: {
      justifyContent: 'space-around',
      maxWidth: 928,
      margin: '0 auto',
    },
  },
  heroCta: {
    order: 2,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',

    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      display: 'block',
    },
  },
  heroBannerTitle: {
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '36px !important',
    },
  },
  ctaButtonStyle: {
    width: 190,
    // marginTop: 20,
  },
  ctaTextStyle: {
    fontSize: '18px !important',
    fontWeight: '700 !important',
  },
  heroBannerSubtitle: {
    display: 'block',
    marginTop: '-15px !important',

    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '48px !important',
    },

    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
    },
  },
  heroTitle: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    marginBottom: 12,
  },
  bannerElementsDesktopInner: {
    display: 'block',
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.breakpoints.values.lg,
      maxHeight: 428,
    },

    [theme.breakpoints.up('xl')]: {
      maxWidth: 605,
    },
  },
}));

type DialogTypes = 'sign-up';

const HomeBanner = () => {
  const classes = useStyles();
  const [openSignUp, setOpenSignUp] = useState(false);
  const dialogOpenHandlers = {
    'sign-up': () => setOpenSignUp(true),
  };

  const openDialogHandler = (type: DialogTypes) => dialogOpenHandlers[type]();

  const closeDialogHandler = useCallback(() => {
    setOpenSignUp(false);
  }, []);

  return (
    <>
      <section className={classes.container}>
        <Box className={classes.heroContainer}>
          <Box className={classes.heroBg}></Box>
          <div className={classes.heroContainerInner}>
            {/* <div className={classes.heroCta}>
            <div className={classes.heroTitle}>
              <Typography variant='heroBannerTitle' className={classes.heroBannerTitle}>
                Brake the Code
                <Divider component='br' />
                <Typography variant='heroBannerSubtitle' className={classes.heroBannerSubtitle}>
                  With Friends
                </Typography>
              </Typography>
            </div>
            <BaseButton
              color='primary'
              size='large'
              text='Join Now'
              className={classes.ctaButtonStyle}
              textStyle={classes.ctaTextStyle}
              onClick={() => openDialogHandler('sign-up')}
            />
          </div> */}
          </div>
        </Box>
      </section>
      <DialogSignUp open={openSignUp} closeHandler={closeDialogHandler} />
      <ReviewBlock />
      <ShopBlock />
    </>
  );
};

export default HomeBanner;
