import { countMatchingCharacters, normalizePatchProfileData } from '@/helpers/helpers';

import ProfilesService from '@/services/profiles.service';
import SettingsService from '@/services/settings.service';
import WalletsService from '@/services/wallets.service';
import { ISettings, UserProfile } from '@/types/user-profile.types';
import { call, fork, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { toggleSnackbarOpen } from '../ui/ui.actions';
import {
  deleteBookmarksFailure,
  deleteBookmarksRequest,
  deleteBookmarksSuccess,
  getAvailableUsersFailure,
  getAvailableUsersRequest,
  getAvailableUsersSuccess,
  getBookmarksFailure,
  getBookmarksRequest,
  getBookmarksSuccess,
  getSearchUsersFailure,
  getSearchUsersRequest,
  getSearchUsersSuccess,
  setBookmarksFailure,
  setBookmarksRequest,
  setBookmarksSuccess,
  updateBalanceRequest,
  updateBalanceSuccess,
  updateBalanceFailed,
  chargeBalanceRequest,
  chargeBalanceSuccess,
  chargeBalanceFailed,
  updateCurrentUserFailure,
  updateCurrentUserRequest,
  updateCurrentUserSuccess,
  getSettingsRequest,
  getSettingsSuccess,
  getSettingsFailed,
  userBalanceChargedSuccess,
  changeUserPasswordRequest,
  updateAvatarRequest,
  assume,
  youWonRequest,
  startGameRequest,
  startGameSuccess,
  youWonSuccess,
} from './users.actions';
import {
  CHARGE_BALANCE_REQUEST,
  DELETE_BOOKMARKS_REQUEST,
  GET_AVAILABLE_USERS_REQUEST,
  GET_BOOKMARKS_REQUEST,
  GET_SEARCH_USERS_REQUEST,
  GET_SETTINGS_REQUEST,
  SET_BOOKMARKS_REQUEST,
  UPDATE_BALANCE_REQUEST,
  UPDATE_CURRENT_USER_PROFILE_REQUEST,
  CHANGE_USER_PASSWORD,
  UPDATE_CURRENT_USER_AVATAR_REQUEST,
  ASSUME_SUCCESS,
  YOU_WON_REQUEST,
  START_GAME_REQUEST,
} from './users.types';
import { StorageService } from '@/services/storage.service';
import { EventNamesEnum, EventTypeEnum } from '@/utils/ws.enum';
import Socket from '@/services/Socket';

function* getSearchedUsersWorkerSaga(action: ReturnType<typeof getSearchUsersRequest>) {
  try {
    const { config } = action;

    const users: UserProfile[] = yield call(() => ProfilesService.getUsers(config));

    yield put(getSearchUsersSuccess(users));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSearchUsersFailure(e));
  }
}

function* updateCurrentAvatarWorkerSaga(action: ReturnType<typeof updateAvatarRequest>) {
  try {
    const { body } = action;

    const patchedUser: UserProfile = yield call(() => ProfilesService.updateAvatar(body));

    yield put(updateCurrentUserSuccess(patchedUser as unknown as UserProfile));
    yield put(toggleSnackbarOpen('Settings saved', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* assumeRightWorkerSaga(action: ReturnType<typeof assume>) {
  try {
    const currentGame = yield select((state) => state.users.currentGame);
    const matchingCount = countMatchingCharacters(action.payload.proposeValue, currentGame.currentValue);
    if (Socket && Socket.socket) {
      if (matchingCount < 4) {
        Socket.emit(EventNamesEnum.dataSend, {
          toUserId: currentGame.opponentUserId,
          data: {
            type: EventTypeEnum.rightNumbers,
            data: {
              rightNumbers: matchingCount,
            },
          },
        });
      } else if (matchingCount === 4) {
        Socket.emit(EventNamesEnum.dataSend, {
          toUserId: currentGame.opponentUserId,
          data: {
            type: EventTypeEnum.youWon,
            data: {
              rightNumbers: matchingCount,
            },
          },
        });
      }
    }

    yield put(toggleSnackbarOpen('Assume saved', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* startGameWorkerSaga(action: ReturnType<typeof startGameRequest>) {
  try {
    if (!action.payload.isMy) {
      const game = yield call(() =>
        ProfilesService.startGameRequest({
          hostUserId: action.payload.userId,
          guestUserId: action.payload.opponentUserId,
          reward: action.payload.credits,
        })
      );
      if (Socket && Socket.socket) {
        Socket.emit(EventNamesEnum.dataSend, {
          toUserId: action.payload.opponentUserId,
          data: {
            type: EventTypeEnum.sendGameId,
            data: {
              gameId: game.id
            },
          },
        });
      }
      yield put(
        startGameSuccess({
          gameId: game.id,
          currentValue: action.payload.currentValue,
          userId: action.payload.userId,
          opponentUserId: action.payload.opponentUserId,
          credits: action.payload.credits,
        })
      );
    } else {
      yield put(startGameSuccess({
        gameId: null,
        currentValue: action.payload.currentValue,
        userId: action.payload.userId,
        opponentUserId: action.payload.opponentUserId,
        credits: action.payload.credits,
      }));
    }

    yield put(toggleSnackbarOpen('Assume saved', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* youWonWorkerSaga(action: ReturnType<typeof youWonRequest>) {
  try {
    const currentGame = yield select((state) => state.users.currentGame);

    yield call(() =>
      ProfilesService.wonGame({
        gameId: currentGame.gameId,
        winnerUserId: currentGame.userId,
        status: 'win',
      })
    );

    if (Socket && Socket.socket) {
      Socket.emit(EventNamesEnum.dataSend, {
        toUserId: currentGame.opponentUserId,
        data: {
          type: EventTypeEnum.endGame,
          data: {},
        },
      });
    }
    yield put(youWonSuccess({}));
    yield put(toggleSnackbarOpen('You WON', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* updateCurrentUserWorkerSaga(action: ReturnType<typeof updateCurrentUserRequest>) {
  try {
    const { user: patchUserData } = action;

    const normalizedData = normalizePatchProfileData(patchUserData);

    const patchedUser: UserProfile = yield call(() => ProfilesService.updateProfile(normalizedData as UserProfile));

    yield put(updateCurrentUserSuccess(patchedUser as unknown as UserProfile));
    yield put(toggleSnackbarOpen('Settings saved', 'success'));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateCurrentUserFailure(e));
  }
}

function* getAvailableUsersWorkerSaga(action: ReturnType<typeof getAvailableUsersRequest>) {
  try {
    const { config } = action;
    const token = StorageService.getToken();
    if (!token) {
      return;
    }

    const users: UserProfile[] = yield call(() => ProfilesService.getAvailableUsers(config));

    yield put(getAvailableUsersSuccess(users));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getAvailableUsersFailure(e));
  }
}

function* getBalanceWorkerSaga(action: ReturnType<typeof updateBalanceRequest>) {
  try {
    const wallet: { balance: number } = yield call(() => WalletsService.getBalance());

    yield put(updateBalanceSuccess(wallet));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(updateBalanceFailed(e));
  }
}

function* chargeBalanceWorkerSaga(action: ReturnType<typeof chargeBalanceRequest>) {
  try {
    const wallet: { balance: number } = yield call(() =>
      WalletsService.chargeBalance({
        config: action.payload.config,
        targetId: action.payload.id,
      })
    );

    yield put(chargeBalanceSuccess(wallet));
    yield put(userBalanceChargedSuccess({ id: action.payload.id }));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(chargeBalanceFailed(e));
  }
}

function* getSettingsWorkerSaga(action: ReturnType<typeof getSettingsRequest>) {
  try {
    const data: ISettings = yield call(() => SettingsService.getSettings());

    yield put(getSettingsSuccess(data));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSettingsFailed(e));
  }
}

function* changeUserPassword(action: ReturnType<typeof changeUserPasswordRequest>) {
  try {
    const res = yield call(() => SettingsService.changePassword(action.password));

    if (res) {
      yield put(toggleSnackbarOpen('Password has been changed', 'success'));
    }
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getSettingsFailed(e));
  }
}

function* getBookmarksSaga(action: ReturnType<typeof getBookmarksRequest>) {
  try {
    const { config } = action;
    const users: UserProfile[] = yield call(() => ProfilesService.getBookmarks(config));

    yield put(getBookmarksSuccess(users));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(getBookmarksFailure(e));
  }
}

function* setBookmarksSaga(action: ReturnType<typeof setBookmarksRequest>) {
  try {
    const { config } = action;

    yield call(() => ProfilesService.setBookmarks(config.id));

    yield put(setBookmarksSuccess(config));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(setBookmarksFailure(e));
  }
}

function* deleteBookmarksSaga(action: ReturnType<typeof deleteBookmarksRequest>) {
  try {
    const { config } = action;

    yield call(() => ProfilesService.deleteBookmarks(config.id));

    yield put(deleteBookmarksSuccess(config.id));
  } catch (e) {
    yield put(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
    yield put(deleteBookmarksFailure(e));
  }
}

function* assumeRightWatcherSaga() {
  yield takeEvery(ASSUME_SUCCESS, assumeRightWorkerSaga);
}

function* startGameWatcherSaga() {
  yield takeEvery(START_GAME_REQUEST, startGameWorkerSaga);
}

function* youWonWatcherSaga() {
  yield takeEvery(YOU_WON_REQUEST, youWonWorkerSaga);
}

function* updateCurrentUserWatcherSaga() {
  yield takeEvery(UPDATE_CURRENT_USER_PROFILE_REQUEST, updateCurrentUserWorkerSaga);
}

function* updateCurrentAvatarWatcherSaga() {
  yield takeEvery(UPDATE_CURRENT_USER_AVATAR_REQUEST, updateCurrentAvatarWorkerSaga);
}

function* getSearchUsers() {
  yield takeLatest(GET_SEARCH_USERS_REQUEST, getSearchedUsersWorkerSaga);
}

function* getAvailableUsers() {
  yield takeLatest(GET_AVAILABLE_USERS_REQUEST, getAvailableUsersWorkerSaga);
}

function* getBookmarks() {
  yield takeLatest(GET_BOOKMARKS_REQUEST, getBookmarksSaga);
}

function* setBookmarks() {
  yield takeLatest(SET_BOOKMARKS_REQUEST, setBookmarksSaga);
}

function* deleteBookmarks() {
  yield takeLatest(DELETE_BOOKMARKS_REQUEST, deleteBookmarksSaga);
}

function* getWalletBalance() {
  yield takeEvery(UPDATE_BALANCE_REQUEST, getBalanceWorkerSaga);
}

function* chargeBalance() {
  yield takeEvery(CHARGE_BALANCE_REQUEST, chargeBalanceWorkerSaga);
}

function* getSettings() {
  yield takeEvery(GET_SETTINGS_REQUEST, getSettingsWorkerSaga);
}

function* changePassword() {
  yield takeEvery(CHANGE_USER_PASSWORD, changeUserPassword);
}

const userSagas = [
  fork(updateCurrentUserWatcherSaga),
  fork(getSearchUsers),
  fork(getAvailableUsers),
  fork(getBookmarks),
  fork(setBookmarks),
  fork(deleteBookmarks),
  fork(getWalletBalance),
  fork(getSettings),
  fork(chargeBalance),
  fork(changePassword),
  fork(updateCurrentAvatarWatcherSaga),
  fork(assumeRightWatcherSaga),
  fork(youWonWatcherSaga),
  fork(startGameWatcherSaga),
];

export default userSagas;
