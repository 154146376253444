import {
  getAvailableUsersRequest,
  updateBalanceRequest,
  getSettingsRequest,
} from '@/app/users/users.actions';

import { useAppBreakpoints } from '@/hooks';
import { Box, Container, SxProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Drawer from './components/drawer.component';

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawerWrapper: {
    width: '100%',
  },
  sidebar: {
    display: 'flex',
    marginTop: 60,
    position: 'sticky',
    top: 0,
    alignSelf: 'flex-start',

    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  abstract: {
    width: '100%',
    position: 'relative',
    marginBottom: 60,
    '&:after': {
      content: "''",
      width: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      height: 60,
      background: 'linear-gradient(81.45deg, #004FC6 0%, #0AFB4C 100%)',
    },
  },
  drawerWrapper: {
    width: '100%',
    // maxWidth: 240,
    // minWidth: 240,
  },
  backdrop: {
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: -30,
    left: 0,
    background: 'rgba(0, 0, 0, .5)',
    zIndex: 40,
    outline: 'none',
    border: 'none',
    transition: 'all .5s',
  },
}));

const overrideStyles = {
  container: {
    display: 'flex',
    gridTemplateColumns: 'auto minmax(0,1fr)',
    columnGap: '2.5rem',
  },
};

type DrawerContainerProps = {
  children: React.ReactNode;
};

const DrawerContainer = ({ children }: DrawerContainerProps) => {
  const classes = useStyles();
  const { lg } = useAppBreakpoints();
  // const isOpen = useAppSelector((state: any) => state.layout.menuIsOpen);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateBalanceRequest());
    dispatch(getSettingsRequest());
  }, []);

  const drawerDispalyOptionsMobile: SxProps<Theme> = {
    display: {
      md: 'none',
      xs: 'block',
    },
  };

  const drawerDispalyOptionsDesktop: SxProps<Theme> = {
    display: {
      md: 'block',
      xs: 'none',
    },
  };

  return (
    <div className={classes.abstract}>
      <Container maxWidth={lg ? 'lg' : 'xl'} style={overrideStyles.container}>
        <aside className={classes.sidebar}>
          <Box
            sx={drawerDispalyOptionsDesktop}
            className={classes.drawerWrapper}
          >
            <Drawer />
          </Box>
          <Box
            sx={drawerDispalyOptionsMobile}
            className={classes.mobileDrawerWrapper}
            style={{ marginLeft: true ? '0%' : '-100%' }}
          >
            <Drawer />
          </Box>
        </aside>
        {children}
      </Container>
    </div>
  );
};

export default DrawerContainer;
