import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { ErrorWrapper } from './utils';

export default class WalletsService {
  static get entity(): string {
    return 'wallets';
  }

  static async getBalance(): Promise<{ balance: number }> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return { balance: 0 };
      }
      const response = await HttpService.get(`/${this.entity}/balance`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async chargeBalance(payload: { config: string; targetId: string }): Promise<{ balance: number }> {
    try {
      const response = await HttpService.post(`/transactions/${payload.config}`, {
        targetId: payload.targetId,
      });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
