import { Container, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { ReactComponent as QuestionIcon } from '@/assets/icons/question.svg';
import { ReactComponent as ExpandMoreIcon } from '@/assets/icons/expand-more-items.svg';
import { useState } from 'react';
import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '44px',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    color: '#004FC6',
    paddingInlineStart: '20px',
    '& > ::marker': {
      color: '#0AFB4C',
    },
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  questionTitle: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '18px',

    color: '#000000',
    paddingBottom: '8px',
    display: 'flex',
    '& svg': {
      paddingRight: '10px',
    },
  },
  questionBody: {
    fontSize: '14px',
    lineHeight: '20px',

    color: '#808080',
    paddingBottom: '16px',
    paddingLeft: '28px',
  },
  styledOl: {
    paddingInlineStart: '14px',
    '& > ::marker': {
      color: '#004FC6',
    },
  },
  link: {
    display: 'inline',
    color: '#0AFB4C',
  },
}));

const FAQ = () => {
  const classes = useStyles();
  const { supportEmail, siteUrl } = useAppSelector<ISettings>((state) => state.users.settings);
  const [expanded, setExpanded] = useState<string | false>('panel1');
  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Default FAQ
  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>FAQ</div>
        <div className={classes.paragraphFirst}>
          This page displays the most frequently asked questions from our users. The answers presented on this page are
          indicative and recommendatory and do not constitute any legal force.
        </div>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={1} className={classes.headline}>
                <li>Registration & Authorization</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>I want to read the Service Agreement and other documents, where can I find them?:</div>
              </div>
              <div className={classes.questionBody}>
                <div>You can find any documents at:</div>
                <ul className={classes.styledOl}>
                  <li>
                    Site Terms of Service, at
                    <Link href={'/terms-of-service'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  {/* <li>
                    Site Refund Policy, at{' '}
                    <Link href={'/refund-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li> */}
                  <li>
                    Site Privacy Policy, at{' '}
                    <Link href={'/privacy-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  {/* <li>
                    Site Complaint Policy, at{' '}
                    <Link href={'/complaint-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Anti scam-policy, at{' '}
                    <Link href={'/anti-scam-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li>
                  <li>
                    Site Cookie Policy, at{' '}
                    <Link href={'/cookie-policy'} underline='none'>
                      <div className={classes.link}> link</div>
                    </Link>
                  </li> */}
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why should I register on the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Our website administration needs to identify You as a particular user in order You could perform
                  actions on our service and preserve Your results. Example: when You have no registration on our site
                  You can’t play game, and we can’t do this instead of You.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What can I do without registering?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  All the necessary information is available on the website for Your review. You are able to observe
                  information on the main page of the service. Furthermore, You can send Your requests and proposals to
                  the support service.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Is the registration paid on the website?</div>
              </div>
              <div className={classes.questionBody}>
                <div>Registration on the service is absolutely free of charge. </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do in case I forgot password from my account?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  For this purpose, please, use the password recovery form, that is easily to find in the authorization
                  block. In addition, You can use the feedback form addressing this issue to us.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do if I want to change the e-mail address to which my account is registered?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Write a letter to the support service using the feedback form. In Your letter, enter the data
                  confirming that You own the account on our service. We will examine this data when receive the letter,
                  in case this information is true, we will contact You.
                </div>
                <div>
                  Important! After registration, the e-mail which You have linked Your account with is impossible to
                  change.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>My account was stolen, what should I do?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  It is necessary to write a letter about this to the support service of our site via the feedback form
                  or by e-mail. Once we receive Your letter, we will verify all the information provided by You and give
                  You the detailed instructions on Your next actions.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How can I delete my account?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Write a letter to the support service, using the feedback form or by e-mail. In Your e-mail attach
                  video-message with intention to delete Your account and the reason for removal. You have indicate the
                  ID of your account and e-mail of registration. When we receive the e-mail, we will contact You in
                  order to confirm the removal of Your account.
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={2} className={classes.headline}>
                <li> Services and payments</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do after registration?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  To make Your profile looks more attractive in the eyes of users, You can pick avatar. After that You can search for users to play with.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What information can I provide in my profile?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  While using our service, the only information required to provide from You is the e-mail address and
                  password. You give us this information during Your registration.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Do I have to upload my photos?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  No
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Why do I need a list of contacts?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can add a users to Your list of contacts. We suggest You to add users with whom you often
                  play to this list or You may add those users
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I turn off notifications?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Sure, it is possible! In the “Settings” You are free to turn off the visual or sound notifications. To
                  perform this, please, click on Your avatar in the upper right corner and select “Settings”.
                </div>
                <div>Visualization of system notifications is impossible to turn off.</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What are the particularities of the service?</div>
              </div>
              <div className={classes.questionBody}>
                <div>By the use of our service, You can:</div>
                <ul>
                  <li>Look for users;</li>
                  <li>Play game with users</li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Which services are paid? And how much do they cost?</div>
              </div>
              <div className={classes.questionBody}>
                <div>The list of paid services includes:</div>
                <ul>
                  <li>
                    Game - 2 credits
                  </li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How can I buy credits?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  By clicking on the coin image at the top of the website page, You have the ability to buy the virtual
                  currency of our website, which is called “credit”. Once you click on it, You will go to the list of
                  credit packages. You may choose the one that is suitable for You and then click to buy. Right after
                  this You will be transferred to the payment system.
                </div>
                <div>Please, note, the more credits You buy at a time, the lower the price of each credit is!</div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How much does 1 credit cost?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  By mischance, we can not accurately answer this question. Credits are sold both in standard and in
                  promotional packages. The cost of each credits depends on the quantity of credits in the package.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What payment methods and payment systems does the service support?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  On our website, all payments are made by means of ccbill service. You can reach for more detailed
                  information about payment systems supported by this service, if You address their website.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What to do if I have difficulty when buying credits?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can describe this issue in written using the feedback form or e-mail. Payment issues are always
                  prioritized, so once we receive Your letter, we will contact You as soon as possible!
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>How long are the credits accrued to the account?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Once the payment service confirmed Your payment, credits are accrued immediately after such
                  confirmation.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Can I return my money?</div>
              </div>
              <div className={classes.questionBody}>
                <div>The license agreement provides all cases, in which we can refund Your invested amounts.</div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={3} className={classes.headline}>
                <li>Communication and relationships between participants on the site</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>Are the users on Your website real?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  Yes, of course! All the participants at our site are real user.
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          style={{ boxShadow: 'none', borderBottom: '1px solid #E7E6E6' }}
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
            <Typography>
              <ol start={4} className={classes.headline}>
                <li>Technical issues</li>
              </ol>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What are the system requirements for the website?</div>
              </div>
              <div className={classes.questionBody}>
                <div>The necessary connection speed is up to 50 Mbps and ping 100ms.</div>
                <div>To use the site it is preferably to use one of the browsers:</div>
                <ul>
                  <li>Edge 10 and higher</li>
                  <li>Opera 88.0 and higher</li>
                  <li>Google Chrome 103.0 and higher</li>
                  <li>Mozilla Firefox 102.0 and higher</li>
                  <li>Safari 15.5 and higher</li>
                </ul>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>What if I found an error on the site?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You should inform the support service of our website on this problem in Your letter using a feedback
                  form or by e-mail. Once we receive letter from You, we will verify all the information provided.
                </div>
              </div>
              <div className={classes.questionTitle}>
                <QuestionIcon />
                <div>I want to call and report an error, how do I call you?</div>
              </div>
              <div className={classes.questionBody}>
                <div>
                  You can write a letter to the support team of our website requesting our contacts details, using a
                  feedback form or by e-mail. Once we receive letter from You, we will contact You directly by phone or
                  we will send You our contact phone number.
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
};

export default FAQ;
