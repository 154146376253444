import React, { useState, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { startGameRequest } from '@/app/users/users.actions';
import { Modal, Box, Typography, FormControl, Theme, CircularProgress } from '@mui/material';
import BaseTextField from './base/text-field.component';
import { Formik } from 'formik';
import BaseButton from './base/button.component';
import { makeStyles } from '@mui/styles';
import * as yup from 'yup';
import { IGameHistory } from '@/app/users/users.reducer';
import Socket from '@/services/Socket';
import { EventNamesEnum, EventTypeEnum } from '@/utils/ws.enum';

const codeSchema = yup.object().shape({
  currentValue: yup.string().min(4, 'Seems a bit short').max(4, 'Seems to big').required(),
});

const proposeValueSchema = yup.object().shape({
  proposeValue: yup.string().min(4, 'Seems a bit short').max(4, 'Seems to big').required(),
});

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  formLabelText: {
    fontSize: 14,
    color: '#000',
  },
  formLabelButton: {
    '&:focus': {
      outline: `2px solid ${theme.palette.radioSelected.main}`,
      outlineOffset: 2,
    },
  },
  formRadioContainer: {},
  formTextFieldsContainer: {
    minWidth: 322,
    marginTop: 20,
    marginBottom: 20,
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  formTextField: {},
  formActions: {
    marginTop: 30,
    width: '100%',
  },
  formBtn: {
    width: '100%',
  },
  proposalsWrapper: {
    display: 'flex',
  },
  isMyProposals: {
    width: '50%',
  },
  isOpponentProposals: {
    width: '50%',
  },
  proposeWrapper: {
    display: 'flex',
  },
  proposeValueWrapper: {
    paddingRight: '10px',
  },
  rightNumbersWrapper: {
    paddingRight: '10px',
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const GamesSubscriber = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentGame = useAppSelector((state) => state.users.currentGame);
  const gameHistory: IGameHistory[] = useAppSelector((state) => state.users.gameHistory);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentGame) {
      setOpen(true);
    }
  }, [currentGame]);

  const setNumber = useCallback(
    (currentValue: string) => {
      dispatch(
        startGameRequest({
          userId: currentGame.userId,
          opponentUserId: currentGame.opponentUserId,
          credits: currentGame.credits,
          currentValue: currentValue,
          isMy: currentGame.isMy,
        })
      );
    },
    [dispatch, currentGame?.userId, currentGame?.opponentUserId, currentGame?.credits]
  );

  const proposeNumber = useCallback(
    (proposeValue: string) => {
      if (Socket && Socket.socket) {
        Socket.emit(EventNamesEnum.dataSend, {
          toUserId: currentGame.opponentUserId,
          data: {
            type: EventTypeEnum.proposeNumber,
            data: {
              proposeValue,
              userId: currentGame.userId,
              opponentUserId: currentGame.opponentUserId,
            },
          },
        });
      }
    },
    [dispatch, currentGame?.userId, currentGame?.opponentUserId, currentGame?.credits]
  );

  if (!currentGame) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='invite-modal-title'
      aria-describedby='invite-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id='invite-modal-title' variant='h6' component='h2'>
          Game{currentGame.currentValue && `, Your Code: ${currentGame.currentValue}`}
        </Typography>

        <Box>
          {!currentGame.currentValue ? (
            <>
              <Typography id='invite-modal-description' sx={{ mt: 2 }}>
                You wanna start game with: {currentGame[0]?.userId}
              </Typography>
              <Formik
                initialValues={{
                  currentValue: '',
                }}
                validationSchema={codeSchema}
                onSubmit={(values, { setSubmitting }) => {
                  setNumber(values.currentValue);
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <form onSubmit={handleSubmit} className={classes.formContainer}>
                    <FormControl>
                      <div className={classes.formTextFieldsContainer}>
                        <BaseTextField
                          label='currentValue'
                          onChange={handleChange}
                          type='text'
                          placeholder={'Put Your Code here'}
                          baseClass={classes.formTextField}
                        />
                      </div>

                      <div className={classes.formActions}>
                        <BaseButton
                          loading={false}
                          type='submit'
                          color='primary'
                          text='Set Code'
                          className={classes.formBtn}
                        />
                      </div>
                    </FormControl>
                  </form>
                )}
              </Formik>
            </>
          ) : (
            <>
              {currentGame.isMyTurn || !gameHistory.length ? (
                <Formik
                  initialValues={{
                    proposeValue: '',
                  }}
                  validationSchema={proposeValueSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    proposeNumber(values.proposeValue);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit} className={classes.formContainer}>
                      <FormControl>
                        <div className={classes.formTextFieldsContainer}>
                          <BaseTextField
                            label='proposeValue'
                            onChange={handleChange}
                            type='text'
                            placeholder={'Assume code here'}
                            baseClass={classes.formTextField}
                          />
                        </div>

                        <div className={classes.formActions}>
                          <BaseButton
                            loading={false}
                            type='submit'
                            color='primary'
                            text='Assume Code'
                            className={classes.formBtn}
                          />
                        </div>
                      </FormControl>
                    </form>
                  )}
                </Formik>
              ) : (
                <>
                  <div className={classes.loaderContainer}>
                    <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
                  </div>
                </>
              )}
            </>
          )}
        </Box>
        <Box>
          <div className={classes.proposalsWrapper}>
            <div className={classes.isMyProposals}>
              {gameHistory
                .filter((e) => e.isMy)
                .map((element) => {
                  return (
                    <div className={classes.proposeWrapper}>
                      <div className={classes.proposeValueWrapper}> {element.proposeValue} </div>
                      <div className={classes.rightNumbersWrapper}> {element.rightNumbers}</div>
                    </div>
                  );
                })}
            </div>
            <div className={classes.isOpponentProposals}>
              {gameHistory
                .filter((e) => !e.isMy)
                .map((element) => {
                  return (
                    <div className={classes.proposeWrapper}>
                      <div className={classes.proposeValueWrapper}> {element.proposeValue} </div>
                      <div className={classes.rightNumbersWrapper}> {element.rightNumbers}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default GamesSubscriber;
