import { UserDTO } from '@/app/users/users.reducer';
import UsersSlider from '@/components/sliders/users-slider.component';
import { useAppBreakpoints } from '@/hooks';

import { Breakpoint, CircularProgress, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import React, { Fragment, useCallback, useRef } from 'react';
import UserBasicInfo from './components/user-basic-info.component';
import UserGeneralInfo from './components/user-general-info.component';
import { useTheme } from '@mui/material/styles';
import BaseBadge from '@/components/base/base-badge.component';
import MuilirowSlider from '@/components/sliders/multirow.slider.component';
import SlidersService from '@/services/sliders.service';
import { useAppSelector } from '@/app/hooks';
import useUserProfile from '@/hooks/useUserProfile';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  aboutContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0,1fr) 268px',
    gap: 32,
    margin: '20px 0',
    [theme.breakpoints.down('xl')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      gap: 16,
    },
    [theme.breakpoints.down('lg')]: {
      gap: 20,
    },
  },
}));

const UserProfile = () => {
  const classes = useStyles();
  const { lg, sm, lgAndUp } = useAppBreakpoints();
  const { palette } = useTheme();

  const [isLoading, currentProfile] = useUserProfile();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const stylesOverrides = {
    contentWrapper: {
      padding: 0,
      maxWidth: lg ? 738 : 968,
    },
    contentLoader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  const aboutMeRef = useRef();

  let width = 'xl';
  if (!lg) width = 'md';
  if (sm) width = 'sm-';
  if (lgAndUp) width = 'xl';

  const renderContent = useCallback(() => {
    if (isLoading) {
      return <CircularProgress color='primary' size={48} />;
    } else {
      return (
        <>
          <UserGeneralInfo currentProfile={currentProfile as UserDTO} />
          <div className={classes.aboutContainer} ref={aboutMeRef}>
            <UserBasicInfo currentProfile={currentProfile as UserDTO} />
          </div>
        </>
      );
    }
  }, [isLoading, currentProfile, classes.aboutContainer, currentUser, palette.radioSelected.main]);

  return (
    <Container
      maxWidth={width as Breakpoint}
      className={classes.contentWrapper}
      style={isLoading ? stylesOverrides.contentLoader : stylesOverrides.contentWrapper}
    >
      {renderContent()}
    </Container>
  );
};

export default UserProfile;
