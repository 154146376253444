import getImageByAvatar from '@/hooks/getImageByAvatar';
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflow: "hidden",
    width: "100%",
  },
  sizeDefault: {
    width: 158,
    height: 194,
  },
  sizeSquare: {
    height: 125,
    width: "100%",
  },
  sizeLarge: {
    width: 252,
    height: 358,
  },
  sizeMedium: {
    width: 114,
    height: 140,
  },
  imageWrapper: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: 10,
    height: "100%",
  },
}));

type AvatarSize = "default" | "square" | "large" | "medium";
type AvatarProps = {
  url: string;
  size?: AvatarSize;
  onClick?: () => void;
  children?: React.ReactNode;
  containerClass?: string;
};
const Avatar = React.forwardRef<any, AvatarProps>(function Avatar(
  {
    url,
    size = "default",
    children = null,
    containerClass = "",
    onClick = () => {},
  }: AvatarProps,
  ref
) {
  const classes = useStyles();

  const avatarSizes = {
    default: classes.sizeDefault,
    square: classes.sizeSquare,
    large: classes.sizeLarge,
    medium: classes.sizeMedium,
  };
  return (
    <div className={containerClass}>
      <div
        className={clsx(classes.container, avatarSizes[size])}
        ref={ref}
        onClick={onClick}
      >
        <div
          className={classes.imageWrapper}
          style={{ backgroundImage: `url("${getImageByAvatar(url)}")` }}
        >
          {children}
        </div>
      </div>
    </div>
  );
});

export default Avatar;
