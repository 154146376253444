import { useAppSelector } from "@/app/hooks";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  PopoverProps,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

type DialogProfileOptionsProps = {
  anchorEl: PopoverProps["anchorEl"];
  setAnchorEl: React.Dispatch<React.SetStateAction<null>>;
  handleLogout: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  dialogInner: {
    "& > .MuiTypography-root": {
      color: "inherit",
    },

    display: "flex",
    flexDirection: "column",
    width: 180,
    padding: 0,
    paddingTop: 0,
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  listItem: {},
  listItemButton: {
    "&:hover > .MuiListItemText-root	": {
      color: "#004FC6",
    },
  },
}));

const stylesOverrides = {
  dialogInner: {
    paddingTop: 0,
  },
  listItemButton: {
    padding: "6px 16px",
  },
};

const DialogProfileOptions = ({
  anchorEl,
  setAnchorEl,
  handleLogout,
}: DialogProfileOptionsProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.users.currentUser?.id);

  const profileOptionsToItem = [
    {
      id: 1,
      name: "My page",
      action: () => navigate(`/user/${userId}`),
    },
    {
      id: 2,
      name: "Settings",
      action: () => navigate(`/user/settings`),
    },
    {
      id: 3,
      name: "Payments",
      action: () => navigate('/user/payments'),
    },
    {
      id: 4,
      name: "Order history",
      action: () => navigate('/user/order-history'),
    },
    {
      id: 5,
      name: "Logout",
      action: handleLogout,
    },
  ];

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      transformOrigin={{
        vertical: -15,
        horizontal: -15,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      PaperProps={{
        style: {
          borderRadius: 10,
          overflow: "initial",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          mt: "10px",
          "&::before": {
            backgroundColor: "white",
            content: '""',
            display: "block",
            position: "absolute",
            width: 12,
            height: 12,
            top: -16,
            transform: "rotate(45deg)",
            left: "calc(50% - 6px)",
          },
        }}
      />
      <List className={classes.dialogInner} style={stylesOverrides.dialogInner}>
        {profileOptionsToItem.flatMap(({ name, id, action }) => {

          return (
            <Fragment key={name}>
              <ListItem disablePadding className={classes.listItem}>
                <ListItemButton
                  onClick={() => {
                    handleClose();
                    action();
                  }}
                  className={classes.listItemButton}
                  style={stylesOverrides.listItemButton}
                >
                  <ListItemText primary={name} color="inherit" />
                </ListItemButton>
              </ListItem>
              {id == profileOptionsToItem.length - 1 ? <Divider /> : null}
            </Fragment>
          )
        })}
      </List>
    </Popover>
  );
};

export default DialogProfileOptions;
