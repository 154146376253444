import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { UserDTO } from '@/app/users/users.reducer';
import BaseButton from '@/components/base/button.component';
import SecondaryTextField from '@/components/base/secondary-text-field.component';
import { DateOfBirth } from '@/services/utils';
import { UserProfile } from '@/types/user-profile.types';
import { editUserSchema } from '@/utils/validationSchemas';
import { FormControl, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React, { Fragment, useMemo } from 'react';
import { updateCurrentUserRequest } from '../../../app/users/users.actions';

export interface MainSettingType {
  name: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
  },
  inputAppendElement: {
    flexShrink: 0,
    padding: '0 6px',
    minWidth: 118,

    [theme.breakpoints.down('md')]: {
      minWidth: 80,
    },
  },
  formControl: {
    '& > $inputField:not(:first-child)': {
      marginTop: 20,
    },
  },
  inputField: {},
  saveButton: {
    width: '100%',
    maxWidth: 144,
    marginTop: 32,
  },
  datePickerContainer: {
    paddingLeft: 20,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
}));

const overrideStyles = {
  pageTitle: {
    marginBottom: 24,
  },
};

const EditMainInfo = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);
  const { profile: currentUser }: UserDTO = useAppSelector((state) => state.users.currentUser) || {};

  const initialData: MainSettingType = {
    name: currentUser.name,
  };

  return (
    <Fragment>
      <Typography variant='h3' style={overrideStyles.pageTitle}>
        Main Info
      </Typography>
      <Formik
        initialValues={initialData}
        validateOnChange={false}
        validationSchema={editUserSchema}
        onSubmit={(values, { setSubmitting }) => {
          dispatch(
            updateCurrentUserRequest({
              ...values,
            } as unknown as UserProfile)
          );
        }}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className={classes.formContainer}>
            <FormControl fullWidth className={classes.formControl}>
              <div className={classes.inputField}>
                <SecondaryTextField
                  label='name'
                  placeholder='Name'
                  prepend={
                    <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                      Your name:
                    </Typography>
                  }
                  onChange={handleChange}
                  type='text'
                />
              </div>

              <div style={{ marginTop: 32 }}>
                <BaseButton
                  loading={userIsLoading}
                  color='primary'
                  text='Save'
                  className={classes.saveButton}
                  // textStyle={classes.headerTextStyle}
                  onClick={() => handleSubmit()}
                />
              </div>
            </FormControl>
          </form>
        )}
      </Formik>
    </Fragment>
  );
};

export default EditMainInfo;
