import { useAppSelector } from '@/app/hooks';
import { Container, Link } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  greyParagraph: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#0AFB4C',
    }
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  verySmallItem: {
    display: 'flex',
    padding: '6px 0px 6px 60px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
  additionalText: {
    padding: '10px 40px 0 40px',
  },
  responsiveTable: {
    '& td': {
      border: '1px solid #eee',
    },
    '& tr': {
      border: '1px solid #eee',
    },
    '& th': {
      border: '1px solid #eee',
    }
  },
  link: {
    display: 'inline-block',
  }
}));

const CookiePolicy = () => {
  const classes = useStyles();
  const { siteName } = useAppSelector((state) => state.users.settings);

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>COOKIE POLICY</div>
        <div className={classes.paragraph}>
          <div>
            This policy describes our use of cookies. For our use of your
            personal data, please read out{' '}
            <Link href={'/privacy-policy'} underline='none'>
              <div className={classes.link}> Privacy Policy</div>
            </Link>.
          </div>
          <div>
            Our goal is to provide reliable services that are leading in the
            industry so you can focus on finding serious relationship. The {siteName} website is committed to protect your personal information. Our
            approach to privacy is to provide you with clear information about
            how we process data. Therefore, we have tried to minimize technical
            and legal vocabulary.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            This section describes how we use cookies and similar technologies.
            By agreeing to us with this cookie technology notice, you agree that
            we can distinguish you along with other users of our website and
            applications for the purposes outlined below. Your acceptance helps
            us deliver the services you request, great user experience, content
            adapted to your needs and interests, as well as advertising based on
            your preferences. For example, we can identify and memorize
            important information, among which are preferable settings, which
            helps you to make it easier to view our website.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>What are cookies and similar technologies?</div>
        </div>
        <div className={classes.paragraph}>
          <div>
            A cookie is a small file of letters and numbers that we save using
            your browser on the hard disk of your mobile device or computer.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>There are three main types of cookies:</div>
          <div>
            Session cookies: these files are designed for a specific visit to
            the website and are limited to sending session identifiers (random
            numbers generated by the server), so when you go to a new page or
            leave it, you do not need to re-enter the information. Session
            cookies are not stored on your device and are not erased when you
            close your browser;
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            Persistent cookies: these files record information about your
            preferences and store them in the browser’s memory or on your mobile
            device;
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            Third-party cookies: such files are placed on the network by someone
            else, but not by us. These files can collect information and data on
            several sites or sessions at once. We can also automatically collect
            and store specific information about your cooperation with our
            website or application. Such information includes the IP address,
            type of browser, Internet service provider, links and exit pages,
            operating system, date and time stamps, and other related data.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>Other technologies</div>
        </div>
        <div className={classes.paragraph}>
          <div>
            In parallel with cookies, we can use other similar technologies: Web
            beacons: small graphic images included on our website or emails or
            messages from third parties to provide analytical information.
            Social widgets: these are buttons or icons that provide us with
            social networks of third parties that can collect information about
            viewing and will be received by a third party and controlled by a
            third party.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            Software development kits: these are mobile applications for
            software developers for mobile applications that we use in our
            applications. They allow us to collect information about the
            activity of the application and the device on which it works. Local
            shared objects: sometimes they are called flash files, they can be
            stored in your browser. They are used to save your preferences and
            usage considerations.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>Cookies we use</div>
          <Table className={classes.responsiveTable}>
            <Thead>
              <Tr>
                <Th>Name of Cookie</Th>
                <Th>Why we use it</Th>
                <Th>Expiration</Th>
                <Th>Essential? (yes/no)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Access-token</Td>
                <Td>Authorization token. It’s necessary for access to the personal account</Td>
                <Td>1 month</Td>
                <Td>Yes</Td>
              </Tr>
              <Tr>
                <Td>Chat-token</Td>
                <Td>Token for chat. It is used to decline the invite</Td>
                <Td>Session</Td>
                <Td>Yes</Td>
              </Tr>
              <Tr>
                <Td>locale</Td>
                <Td>Site interface language</Td>
                <Td>1 month</Td>
                <Td>Yes</Td>
              </Tr>
            </Tbody>
          </Table>

          <div>Third party cookies</div>
          <div>
          <Table className={classes.responsiveTable}>
            <Thead>
              <Tr>
                <Th>Name of cookie</Th>
                <Th>Why it is used</Th>
                <Th>Company</Th>
                <Th>Third party privacy policies</Th>
                <Th>User controls</Th>
                <Th>Essential? (yes/no)</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Facebook</Td>
                <Td>Advertising/Marketing/social media functionality</Td>
                <Td>Facebook</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://www.facebook.com/about/privacy/update'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
              <Tr>
                <Td>Google Analytics</Td>
                <Td>Advertising/Marketing</Td>
                <Td>Google</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://policies.google.com/privacy/update'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
              <Tr>
                <Td>Mixpanel</Td>
                <Td>Mobile analytics</Td>
                <Td>Mixpanel</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://mixpanel.com/legal/privacy-policy/'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
              <Tr>
                <Td>Yandex. Metrika</Td>
                <Td>Advertising/Marketing</Td>
                <Td>Yandex</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://yandex.ru/legal/confidential/'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
              <Tr>
                <Td>Hubspot</Td>
                <Td>Advertising/Marketing</Td>
                <Td>Hub spot</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://legal.hubspot.com/privacy-policy'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
              <Tr>
                <Td>Survicate</Td>
                <Td>User survey</Td>
                <Td>Survicate</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://help.survicate.com/account-and-billing/privacy-policy'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
              <Tr>
                <Td>Intercom</Td>
                <Td>Live chat</Td>
                <Td>Intercom</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://www.intercom.com/terms-and-policies%2523privacy'>Find out more</a></Td>
                <Td>Through browser settings</Td>
                <Td>No</Td>
              </Tr>
            </Tbody>
          </Table>
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            Other similar technologies used in our apps
          </div>
          <div>

          <Table className={classes.responsiveTable}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Why we use it</Th>
                <Th>Third party privacy policy</Th>
                <Th>User control</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Mixpanel</Td>
                <Td>Marketing push notifications (iOS and Android), A/B testing</Td>
                <Td><a target='_blank' rel="noreferrer" href='https://mixpanel.com/legal/privacy-policy/'>Find out more</a></Td>
                <Td><a target='_blank' rel="noreferrer" href='https://help.mixpanel.com/hc/articles/360000679006-Managing-Personal-Information'>Find out more</a></Td>
              </Tr>
            </Tbody>
          </Table>

          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            You must confirm that if we allow third parties, as indicated above,
            to refuse cookies, in this case we do not control either the cookies
            or any data generated. Please review the third party privacy policy
            for information on what sort of information they collect and how
            they use it.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>Refusing or deleting cookies</div>
        </div>
        <div className={classes.paragraph}>
          <div>
            To configure your browser settings, click the following links:
            <ul>
              <li>Edge</li>
              <li>Google Chrome</li>
              <li>Mozilla</li>
              <li>Firefox</li>
              <li>Safari</li>
            </ul>
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>
            Please note that you will not be able to use all the functions of
            our website or applications if you delete or refuse cookies.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>Mobile Opt-out</div>
        </div>
        <div className={classes.paragraph}>
          <div>
            When you access our services via our applications, you can enable
            the “do not track” function to monitor interest-based ads on your
            iOS or Android mobile device by selecting the option
          </div>
          <div>
            “Restrict adding tracking” in the privacy section of your settings
            on iOS or using advertising preferences on Android devices (for
            example, in Google settings). This will not prevent the display of
            advertisements, but will mean that they will no longer be
            personalized for your interests.
          </div>
          <div>
            To stop using Google Analytics, go to
            https://tools.google.com/dlpage/gaoptout.
          </div>
          <div>
            Additional information on managing cookies can be found at
            www.allaboutcookies.org.
          </div>
        </div>
        <div className={classes.paragraph}>
          <div>If you are a resident of the EU:</div>
        </div>
        <div className={classes.paragraph}>
          <div>
            For more information on managing cookies, visit
            www.youronlinechoices.eu, which provides additional information
            about behavioral advertising and online privacy.
          </div>
        </div>
        <div className={classes.breakline}></div>
      </div>
    </Container>
  );
};

export default CookiePolicy;
