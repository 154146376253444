import DialogForgotPassword from "@/components/dialogs/forgot-password.dialog.component";
import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import { useQuery } from "@/hooks/useQuery";
import { StorageService } from "@/services/storage.service";
import jwtDecode from "jwt-decode";
import React, { Fragment, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MainBanner from "./components/banner";

const Home = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [isModalShown, showModal, hideModal] = useBinarySwitcher();

  const resetModalParams = useCallback(() => {
    navigate("/");
    hideModal();
  }, [hideModal, navigate]);

  useEffect(() => {
    if (Boolean(query.get("resetPassword"))) {
      showModal();
    }
  }, [query, showModal]);

  useEffect(() => {
    const userToken = StorageService.getToken();
    if (userToken) {
      const { id: userId }: any = jwtDecode(userToken);
      navigate(`/user/${userId}`);
    }
  }, [navigate]);

  return (
    <Fragment>
      <DialogForgotPassword
        open={isModalShown}
        closeHandler={resetModalParams}
      />
      <MainBanner />
    </Fragment>
  );
};

export default Home;
