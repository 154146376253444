import { ISettings, UserProfile } from '@/types/user-profile.types';
import { AnyAction } from 'redux';
import {
  CLEAR_USER_REQUEST,
  CLEAR_USER_SUCCESS,
  GET_AVAILABLE_USERS_FAILURE,
  GET_AVAILABLE_USERS_REQUEST,
  GET_AVAILABLE_USERS_SUCCESS,
  GET_OWNER_PROFILE_FAILURE,
  GET_OWNER_PROFILE_REQUEST,
  GET_OWNER_PROFILE_SUCCESS,
  GET_SEARCH_USERS_FAILURE,
  GET_SEARCH_USERS_REQUEST,
  GET_SEARCH_USERS_SUCCESS,
  GET_USER_PROFILE_FAILURE,
  GET_USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  UPDATE_CURRENT_USER_PROFILE_FAILURE,
  UPDATE_CURRENT_USER_PROFILE_REQUEST,
  UPDATE_CURRENT_USER_PROFILE_SUCCESS,
  SET_BOOKMARKS_REQUEST,
  SET_BOOKMARKS_SUCCESS,
  SET_BOOKMARKS_FAILURE,
  GET_BOOKMARKS_REQUEST,
  GET_BOOKMARKS_SUCCESS,
  GET_BOOKMARKS_FAILURE,
  DELETE_BOOKMARKS_REQUEST,
  DELETE_BOOKMARKS_SUCCESS,
  DELETE_BOOKMARKS_FAILURE,
  SET_UPDATE_AVAILABLE_USERS,
  SET_DISCONNECTED_AVAILABLE_USERS,
  UPDATE_BALANCE_FAILED,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_REQUEST,
  CHARGE_BALANCE_FAILED,
  CHARGE_BALANCE_SUCCESS,
  CHARGE_BALANCE_REQUEST,
  GET_SETTINGS_REQUEST,
  GET_SETTINGS_SUCCESS,
  GET_SETTINGS_FAILED,
  SET_USER_HEADER_SEARCH,
  UPDATE_CURRENT_USER_AVATAR_REQUEST,
  CLEAR_AVAILABLE_USERS_SUCCESS,
  INCOMING_INVITES_SUCCESS,
  ACCEPT_INVITE_SUCCESS,
  DECLINE_INVITE_SUCCESS,
  START_GAME_SUCCESS,
  END_GAME_SUCCESS,
  ASSUME_SUCCESS,
  USER_RIGHT_NUMBERS,
  SEND_GAME_ID,
  YOU_WON_SUCCESS,
} from './users.types';
import { countMatchingCharacters } from '@/helpers/helpers';

export const MINUS_CURRENT_TIMER_FOR_CHARGE_VALUE = 61000;

export type UserDTO = {
  id: string;
  email: string | null;
  name: string;
  iat: number;
  exp: number;
  profile: UserProfile;
};

export interface ICurrentGame {
  gameId: string | null;
  currentValue: string;
  userId: string;
  opponentUserId: string;
  credits: number;
  isMyTurn: boolean;
}

export interface IGameHistory {
  userId: string;
  opponentUserId: string;
  proposeValue: string;
  rightNumbers: number | null;
  isMy: boolean;
}

export interface ICurrentInvites {
  userId: string;
  opponentUserId: string;
  credits: number;
  isMyInvite: boolean;
}

export interface IUserState {
  userIsLoading: boolean;
  currentUser: UserDTO | null;
  searchUsers: { data: UserProfile[]; total: number };
  user: any;
  userAvailableIsLoading: boolean;
  availableUsers: Array<UserProfile>;
  bookmarks: UserProfile[];
  bookmarksLoading: boolean;
  invites: Array<UserProfile>;
  currentInvites: ICurrentInvites[];
  currentGame: ICurrentGame | null;
  gameHistory: IGameHistory[];
  wallet: {
    balance: number;
    isLoading: boolean;
  };
  settings?: ISettings;
  headerUserSearch: string | null;
}

const initialState: IUserState = {
  userIsLoading: false,
  user: {},
  currentUser: null,
  searchUsers: {
    data: [],
    total: 0,
  },
  userAvailableIsLoading: false,
  availableUsers: [],
  invites: [],
  bookmarks: [],
  currentInvites: [],
  currentGame: null,
  gameHistory: [],
  bookmarksLoading: false,
  headerUserSearch: null,
  wallet: {
    balance: 0,
    isLoading: false,
  },
  settings: {
    companyAddress: null,
    companyName: null,
    companyCountry: null,
    companyPhone: null,
    supportEmail: null,
    siteUrl: null,
    siteName: null,
    rnCompany: null,
    paymentsFlexpay: false,
    paymentsStripe: false,
    paymentsCcbill: false,
    paymentsInoviopay: false,
    paymentsInoviopay3ds2: false,
    paymentsNetvalve: false,
  },
};

const users = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_OWNER_PROFILE_REQUEST:
    case GET_OWNER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
      };

    case GET_SEARCH_USERS_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };

    case GET_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        searchUsers: action.users,
      };

    case GET_SEARCH_USERS_FAILURE:
      return {
        ...state,
        userIsLoading: false,
      };

    case UPDATE_CURRENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        currentUser: { ...state.currentUser, ...action.user },
      };

    case GET_OWNER_PROFILE_FAILURE:
    case UPDATE_CURRENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        userIsLoading: false,
      };

    case UPDATE_CURRENT_USER_AVATAR_REQUEST:
    case GET_USER_PROFILE_REQUEST:
    case UPDATE_CURRENT_USER_PROFILE_REQUEST:
      return {
        ...state,
        userIsLoading: true,
      };
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        currentUser: action.user,
      };
    case GET_USER_PROFILE_FAILURE:
      return {
        ...state,
        userIsLoading: false,
        error: action.error,
      };
    case CLEAR_USER_REQUEST:
      return {
        ...state,
        userIsLoading: false,
      };
    case CLEAR_USER_SUCCESS:
      return {
        ...state,
        userIsLoading: false,
        user: {},
        currentUser: null,
        searchUsers: {
          data: [],
          total: 0,
        },
      };

    case CLEAR_AVAILABLE_USERS_SUCCESS:
      return {
        ...state,
        userAvailableIsLoading: false,
        availableUsers: [],
      };

    case GET_AVAILABLE_USERS_REQUEST:
      return {
        ...state,
        userAvailableIsLoading: true,
      };

    case GET_AVAILABLE_USERS_SUCCESS: {
      const filteredUsers = action.users.filter((user) => user.id !== state.currentUser?.profile?.id);

      const updatedAvailableUsers = state.availableUsers.map((availableUser) => {
        const filteredUser = filteredUsers.find((user) => user.id === availableUser.id);
        if (filteredUser) {
          return { ...filteredUser, isOnline: true };
        }
        return availableUser;
      });

      const newAvailableUsers = filteredUsers.filter((filteredUser) => {
        return !state.availableUsers.some((availableUser) => availableUser.id === filteredUser.id);
      });

      const updatedAvailableUsersWithStatus = updatedAvailableUsers.map((user) => {
        if (!filteredUsers.some((filteredUser) => filteredUser.id === user.id)) {
          return { ...user, isOnline: false };
        }
        return user;
      });

      const sortedAvailableUsers = [...updatedAvailableUsersWithStatus, ...newAvailableUsers].sort((a, b) => {
        if (a.isOnline && !b.isOnline) {
          return -1;
        }
        if (!a.isOnline && b.isOnline) {
          return 1;
        }
        return 0;
      });

      return {
        ...state,
        userAvailableIsLoading: false,
        availableUsers: sortedAvailableUsers,
      };
    }

    case GET_AVAILABLE_USERS_FAILURE:
      return {
        ...state,
        userAvailableIsLoading: false,
      };

    case SET_UPDATE_AVAILABLE_USERS: {
      const { availableUsers = [] } = state;

      const updatedAvailableUsers = availableUsers.map((user) => {
        if (user.id === action.payload.id) {
          return { ...user, ...action.payload, isOnline: true };
        }
        return user;
      });

      const isUserExist = availableUsers.some((user) => user.id === action.payload.id);

      const sortedAvailableUsers = updatedAvailableUsers.sort((a, b) => {
        if (a.isOnline && !b.isOnline) {
          return -1;
        }
        if (!a.isOnline && b.isOnline) {
          return 1;
        }
        if (a.chat && a.chat.length > 0 && !(b.chat && b.chat.length > 0)) {
          return -1;
        }
        if (!(a.chat && a.chat.length > 0) && b.chat && b.chat.length > 0) {
          return 1;
        }
        return 0;
      });

      if (isUserExist) {
        return {
          ...state,
          availableUsers: sortedAvailableUsers,
        };
      }

      return {
        ...state,
        availableUsers: [...sortedAvailableUsers, action.payload],
      };
    }

    case SET_DISCONNECTED_AVAILABLE_USERS: {
      const { availableUsers = [] } = state;

      const updatedAvailableUsers = availableUsers.map((user) => {
        if (user.id === action.payload.id) {
          return { ...user, isOnline: false };
        }
        return user;
      });

      return {
        ...state,
        availableUsers: updatedAvailableUsers,
      };
    }

    case SET_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarksLoading: true,
      };
    }
    case SET_BOOKMARKS_SUCCESS: {
      return {
        ...state,
        bookmarksLoading: false,
        bookmarks: [...state.bookmarks, action.user],
      };
    }
    case SET_BOOKMARKS_FAILURE: {
      return {
        ...state,
        bookmarksLoading: false,
      };
    }
    case GET_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarksLoading: true,
      };
    }
    case GET_BOOKMARKS_SUCCESS: {
      return {
        ...state,
        bookmarksLoading: false,
        bookmarks: action.users,
      };
    }
    case GET_BOOKMARKS_FAILURE: {
      return {
        ...state,
        bookmarksLoading: false,
      };
    }
    case DELETE_BOOKMARKS_REQUEST: {
      return {
        ...state,
        bookmarksLoading: true,
      };
    }
    case DELETE_BOOKMARKS_SUCCESS: {
      const bookmarks = state.bookmarks.filter((bookmark) => bookmark.id !== action.id);
      return {
        ...state,
        bookmarksLoading: false,
        bookmarks,
      };
    }

    case DELETE_BOOKMARKS_FAILURE: {
      return {
        ...state,
        bookmarksLoading: false,
      };
    }

    case UPDATE_BALANCE_REQUEST: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: true,
        },
      };
    }

    case UPDATE_BALANCE_SUCCESS: {
      const { balance } = action.payload;
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
          balance,
        },
      };
    }

    case UPDATE_BALANCE_FAILED: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
        },
      };
    }

    case CHARGE_BALANCE_REQUEST: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: true,
        },
      };
    }

    case CHARGE_BALANCE_SUCCESS: {
      const { balance } = action.payload;
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
          balance,
        },
      };
    }

    case CHARGE_BALANCE_FAILED: {
      return {
        ...state,
        wallet: {
          ...state.wallet,
          isLoading: false,
        },
      };
    }

    case GET_SETTINGS_REQUEST: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: true,
        },
      };
    }

    case GET_SETTINGS_SUCCESS: {
      const { settings } = action.payload;
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false,
          ...settings,
        },
      };
    }

    case GET_SETTINGS_FAILED: {
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false,
        },
      };
    }

    case SET_USER_HEADER_SEARCH: {
      return {
        ...state,
        headerUserSearch: action.payload,
      };
    }

    case INCOMING_INVITES_SUCCESS: {
      return {
        ...state,
        currentInvites: [...state.currentInvites, action.payload],
      };
    }

    case DECLINE_INVITE_SUCCESS: {
      return {
        ...state,
        currentInvites: [
          ...state.currentInvites.filter(
            (element) =>
              !(
                (element.opponentUserId === action.payload.opponentUserId &&
                  element.userId === action.payload.userId) ||
                (element.opponentUserId === action.payload.userId && element.opponentUserId === action.payload.userId)
              )
          ),
        ],
      };
    }
    case ACCEPT_INVITE_SUCCESS: {
      return {
        ...state,
        currentInvites: [
          ...state.currentInvites.filter(
            (element: ICurrentInvites) =>
              !(
                (element.opponentUserId === action.payload.opponentUserId &&
                  element.userId === action.payload.userId) ||
                (element.opponentUserId === action.payload.userId && element.opponentUserId === action.payload.userId)
              )
          ),
        ],
        currentGame: {
          currentValue: null,
          userId: action.payload.userId,
          opponentUserId: action.payload.opponentUserId,
          credits: action.payload.credits,
          isMy: action.payload.isMyInvite,
        },
      };
    }
    case START_GAME_SUCCESS: {
      return {
        ...state,
        currentGame: {
          ...state.currentGame,
          ...action.payload,
        },
        gameHistory: [],
      }
    }

    case SEND_GAME_ID: {
      return {
        ...state,
        currentGame: {
          ...state.currentGame,
          ...action.payload,
        },
        gameHistory: [],
      }
    }
    case YOU_WON_SUCCESS: {
      return {
        ...state,
        currentGame: null,
        gameHistory: [],
        currentInvites: [],
      }
    }
    case END_GAME_SUCCESS: {
      return {
        ...state,
        currentGame: null,
        gameHistory: [],
      }
    }
    case ASSUME_SUCCESS: {
      return {
        ...state,
        gameHistory: [...state.gameHistory, {
          ...action.payload,
          rightNumbers: !action.payload.isMy ? countMatchingCharacters(action.payload.proposeValue, state.currentGame.currentValue) : null
        }],
        currentGame: {
          ...state.currentGame,
          isMyTurn: !action.payload.isMy,
        }
      }
    }

    case USER_RIGHT_NUMBERS: {
      const gameHistory = state.gameHistory;
      const lastElement = gameHistory[gameHistory.length - 1];

      gameHistory[gameHistory.length - 1] = {
        ...lastElement,
        rightNumbers: action.payload.rightNumbers,
      };

      return {
        ...state,
        gameHistory: gameHistory,
      }
    }

    default:
      return state;
  }
};

export default users;
