import { ReactComponent as ArrowLeftIcon } from "@/assets/icons/left-arrow.svg";
import { ReactComponent as ArrowRightIcon } from "@/assets/icons/right-arrow.svg";
import { useAppBreakpoints } from "@/hooks";
import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import { Container, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useCallback, useMemo, useRef, useState } from "react";
import { Navigation, SwiperOptions } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import BaseButton from "../base/button.component";
import SliderCard from "../base/slider-card";

export interface SliderSlidesPerView {
  sm?: number | "auto" | undefined;
  md?: number | "auto" | undefined;
  lg?: number | "auto" | undefined;
  xl?: number | "auto" | undefined;
}

type SearchSliderProps = {
  slidesPerView?: SliderSlidesPerView;
  disablePadding?: boolean;
  children: (data: number) => React.ReactNode;
  fetcher: () => Promise<any[]>;
  customSpacing?: number;
  isUnloginned?: boolean;
  data: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  ml: {
    "& > *:not(:first-child)": {
      marginLeft: 28,
    },
  },
  container: {
    marginBottom: 40,
  },
  sliderNavigation: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  sliderContent: {
    padding: "28px 0",
  },
  sliderNavigationControl: {
    display: "flex",
  },

  disabledNavigationBtn: {
    backgroundColor: "rgb(229, 229, 229) !important",
    cursor: "not-allowed !important",
    boxShadow: "none !important",
  },

  navigationBtn: {
    minHeight: 28,
    height: 28,
    minWidth: 28,
  },
}));

const SearchSlider = ({
  slidesPerView,
  children,
  disablePadding = false,
  fetcher,
  customSpacing,
  isUnloginned,
  data
}: SearchSliderProps) => {
  const classes = useStyles();
  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight, []);

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const { lg } = useAppBreakpoints();
  const defaultSlidesPerView: SliderSlidesPerView = {
    sm: 3,
    md: 4,
    lg: 4,
    xl: 5,
  };

  const modules = [Navigation];

  const slidesPreView: SliderSlidesPerView = Object.assign(
    defaultSlidesPerView,
    slidesPerView
  );

  const swiperOption: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 16,
    breakpoints: {
      0: {
        slidesPerView: 2,
      },
      470: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: slidesPreView.sm,
      },
      768: {
        slidesPerView: slidesPreView.md,
      },
      1024: {
        slidesPerView: slidesPreView.lg,
      },
      1280: {
        slidesPerView: slidesPreView.xl,
      },
    },
  };

  if (!data.length) return null;

  return (
    <Container
      disableGutters={disablePadding}
      className={classes.container}
      maxWidth={lg ? "md" : "xl"}
      style={{ padding: "0 !important" }}
      sx={{ padding: 0 }}
    >
      <div>
        <div className={classes.sliderNavigation}>
          {children(data.length)}
          <div className={clsx(classes.sliderNavigationControl, classes.ml)}>
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationPrevRef}
              Icon={<ArrowLeftIcon />}
              color="primary"
            // disableElevation
            />
            <BaseButton
              text=""
              variant="contained"
              size="small"
              ref={navigationNextRef}
              Icon={<ArrowRightIcon />}
              color="primary"
            // disableElevation
            />
          </div>
        </div>
        <div className={classes.sliderContent}>
          <Swiper
            breakpoints={swiperOption.breakpoints}
            slidesPerView={swiperOption.slidesPerView}
            modules={modules}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
              disabledClass: classes.disabledNavigationBtn,
            }}
            onBeforeInit={(swiper: any) => {
              swiper.params.navigation.prevEl = navigationPrevRef.current;
              swiper.params.navigation.nextEl = navigationNextRef.current;
            }}
          >
            {data.map((item, key) =>
              <SwiperSlide key={key} style={{ width: 170 }}>
                <SliderCard user={item}
                  key={item.id}
                  windowWidth={windowWidth}
                  windowHeight={windowHeight}
                />
              </SwiperSlide>)}
          </Swiper>
        </div>
      </div>
    </Container>
  );
};

export default SearchSlider;
