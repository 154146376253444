import { Link } from "react-router-dom";
import { useAppSelector } from "@/app/hooks";
import { UserDTO } from "@/app/users/users.reducer";
import BaseCard from "@/components/base/base-card.component";

import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as EditIcon } from "@/assets/icons/edit.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  cardOverrides: {
    padding: 28,
    flexDirection: "column",
    width: "100%",

    [theme.breakpoints.down("lg")]: {
      padding: 20,
    },
  },
  cardInner: {
    marginTop: 24,
  },
  badgeWrapper: {
    marginBottom: 24,
  },
  profileSlideWrapper: {
    marginBottom: 24,

    '& .pswp__zoom-wrap': {
      display: 'flex',
      justifyContent: 'center',
    },

    '& .pswp__img': {
      width: 'auto !important',
      position: 'relative !important'
    },
    '& .pswp__img--placeholder': {
      display: 'none'
    }
  },
  mainInfoWrapper: {
    display: "none",
    position: 'relative',
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  aboutInfoWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
    gap: 12,
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
  },
  metaInfoWrapper: {
    display: "grid",
    gap: 32,
    gridTemplateColumns: "repeat(1, minmax(0, 1fr))",

    [theme.breakpoints.between("md", "xl")]: {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
      gap: 20,
    },

    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
      gap: 16,
    },
  },
  metaCard: {
    // minHeight: 200,
    width: "100%",
    flex: "1 1 auto",
    display: "flex",
    borderRadius: 10,
    overflow: "hidden",
    flexDirection: "column",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",
  },
  metaCardTitle: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
    color: "#fff",
    padding: 20,
    fontWeight: 600,
    fontSize: 18,
    [theme.breakpoints.up("xs")]: {
      fontSize: 20,
    },
  },
  metaCardContent: {
    background: "#fff",
    padding: 20,
    flex: "1 1 auto",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  photosSliderStub: {
    display: "flex",
    alignItems: "center",
    padding: 20,
  },
  stubSubtitle: {
    display: "flex",
    alignItems: "center",
    gap: 16,
    padding: 20,
    backgroundColor: theme.palette.info.light,
    borderRadius: 10,
  },

  infoEditButton: {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000",
    marginRight: 15,
    "&:hover $infoEditIcon": {
      opacity: 0.8,
    },
  },

  infoEditButtonWhite: {
    color: "#fff",
  },

  absoluteEditButton: {
    position: 'absolute',
    right: 0,
    top: '10px'
  },

  infoEditIcon: {
    width: 28,
    height: 28,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.info.main,
    borderRadius: 9999,
    marginLeft: 8,
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0 5px'
  },
}));

type UserBasicInfoProps = {
  currentProfile: UserDTO;
};

const UserBasicInfo = ({
  currentProfile,
}: UserBasicInfoProps) => {
  const classes = useStyles();
  const { id } = useParams<{
    id: string;
  }>();
  const currentUser = useAppSelector((state) => state.users.currentUser);
  const isMyUser = id === currentUser?.id;

  return (
    <>
      <BaseCard disableMargin={true} cardOverrideStyle={classes.cardOverrides}>

        <div className={classes.mainInfoWrapper}>
        </div>
      </BaseCard>
    </>
  );
};

export default UserBasicInfo;
