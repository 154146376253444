import { signUpRequest } from '@/app/auth/auth.actions';
import { useAppDispatch } from '@/app/hooks';
import { SignupDto } from '@/services/auth.service';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, IconButton, IconButtonProps, Theme, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import SignUpLayout from '../layouts/sign-up.layout';
import './dialog.css';
import { useNavigate } from 'react-router-dom';
import { fetchDataWithTimeout } from '@/services/utils';

type DialogSignUpProps = {
  open: boolean;
  closeHandler: () => void;
} & DialogProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '28px 24px',
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
}));

type CloseIconStyledType = React.ComponentType<IconButtonProps>;
export const CloseIconStyled: CloseIconStyledType = styled((props) => (
  <IconButton {...props} size='small'>
    <CloseIcon color='inherit' />
  </IconButton>
))(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    right: 10,
    top: 10,
  },
  width: 28,
  height: 28,
  position: 'absolute',
  right: -10,
  top: -10,
  backgroundColor: '#DEDEDE',
  color: theme.palette.grey[500],
  '& > .MuiSvgIcon-root': {
    color: '#000',
  },
  ':hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

const DialogSignUp = ({ open, closeHandler }: DialogSignUpProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (values: SignupDto) =>
    dispatch(signUpRequest({ ...values, navigate }));


  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={() => closeHandler()}
    >
      <div className={classes.container}>
        <CloseIconStyled onClick={closeHandler} />
        <SignUpLayout onSubmit={handleSubmit} />
      </div>
    </Dialog>
  );
};

export default DialogSignUp;
