import { useCallback, useEffect, useMemo, useState } from "react";
import BaseCard from "@/components/base/base-card.component";
import { useContainerWidth } from "@/hooks";
import { Container, Divider, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BaseButton from "@/components/base/button.component";
import CreditCard from "./components/CreditCard";
import CreditsService from "@/services/credits.service";
import { useBinarySwitcher } from "@/hooks/useBinarySwitcher";
import DialogBuyCredits from "@/components/dialogs/buy-credits.dialog";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },

  header: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 20,
    },
  },

  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  bodyWrapper: {
    paddingLeft: '10px',
    paddingTop: '65px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      paddingTop: '33px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingTop: '18px',
    },
  },

  creditPackWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%'
  },

  creditPackImage: {
    width: 158,
    height: 194,
    position: 'absolute',
    borderRadius: 10,

    top: 'calc(50% - 97px)',
    left: '-10px',

    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 114,
    },
  },

  infoBlock: {
    overflow: ' hidden',
    position: 'relative',
    height: '100%',
    marginLeft: '169px',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    fontSize: '26px',
    lineHeight: '60px',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '124px',
      lineHeight: '45px',
    },
  },

  name: {
    marginTop: 12
  },

  price: {
    display: 'flex',
    fontWeight: '600',
    fontSize: '23px',
    alignItems: 'center',
    lineHeight: '84px',

    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
    },

    [theme.breakpoints.down('lg')]: {
      fontSize: '15px',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '23px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '17px',
    },
  },

  verticalDivider: {
    height: 24,
    width: 2,
    backgroundColor: '#000',
    margin: '0 12px',

    [theme.breakpoints.down('xl')]: {
      margin: '0 6px'
    },
  },

  actualPrice: {
    color: '#004FC6'
  },

  oldPrice: {
    color: '#808080',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '84px',
    textDecoration: 'line-through',
    marginLeft: 7,

    [theme.breakpoints.down('xl')]: {
      fontSize: '13px',
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },

    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
  },

  buyButton: {
    height: 42,
    width: 140,
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) !important'
  },

  sale: {
    width: '194px',
    height: '43px',
    background: '#0AFB4C',
    transform: 'rotate(45deg)',
    color: '#fff',

    position: 'absolute',
    right: '-60px',
    top: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '18px',
  },
}));



const Credits = () => {
  const stylesOverrides = useMemo(() => (
    {
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
    }
  ), []);

  const classes = useStyles();
  const width = useContainerWidth();

  const [chosenCreditpack, setChosenCreditPack] = useState(null);
  const [isDialogOpen, openDialog, hideDialog] = useBinarySwitcher();

  const setCreditPackHandler = useCallback((creditPack) => {
    setChosenCreditPack(creditPack);
    openDialog()
  }, [openDialog])

  const hideDialogHandler = useCallback(() => {
    setChosenCreditPack(null)
    hideDialog()
  }, [hideDialog])

  const [creditPacks, setCreditPacks] = useState([]);

  useEffect(() => {
    const fetchCreditPacks = async () => {
      const response = await CreditsService.getCreditPacks();

      setCreditPacks(response.filter(item => item.isActive));
    }

    fetchCreditPacks();
  }, [])

  const getSale = useCallback(({ price, oldPrice }) => {
    if (!oldPrice || price === oldPrice) return null;

    return <div className={classes.sale}>-{Math.round(((oldPrice - price) / oldPrice) * 100)} %</div>;
  }, [classes.sale])

  const getSign = useCallback((creditPack) => {
    if (creditPack.limit) return <div className={classes.sale}>PROMO</div>;

    return getSale(creditPack)
  }, [classes.sale, getSale])

  return <Container
    maxWidth={width}
    className={classes.contentWrapper}
    style={stylesOverrides.contentWrapper}
  >
    <DialogBuyCredits open={isDialogOpen} closeHandler={hideDialogHandler} chosenCreditpack={chosenCreditpack} />
    <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
      <div
        className={classes.cardWrapper}
      >
        <header className={classes.header}>
          <Typography variant="h2">Credits</Typography>
        </header>

      </div>
    </BaseCard>

    <div className={classes.bodyWrapper}>

      {
        creditPacks.map(creditPack => (
          <CreditCard key={creditPack.id}>
            <div className={classes.creditPackWrapper}>
              <div style={{background: creditPack.image}} className={classes.creditPackImage} />
              <div className={classes.infoBlock}>
                {getSign(creditPack)}
                <div className={classes.name}>{creditPack.name}</div>
                <Divider style={{ height: 1 }} sx={{ width: '75%' }} />
                <div className={classes.price}>
                  {creditPack.credits}
                  <div className={classes.verticalDivider} />
                  <div className={classes.actualPrice}>{creditPack.price}€</div>
                  {<div className={classes.oldPrice}>{creditPack.oldPrice ? `${creditPack.oldPrice}€` : null}</div>}

                </div>
                <BaseButton
                  text={'Buy'}
                  color='primary'
                  className={classes.buyButton}
                  onClick={() => setCreditPackHandler(creditPack)}
                />


              </div>
            </div>
          </CreditCard>)
        )
      }

    </div>
  </Container>

}

export default Credits;
