import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  greyParagraph: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#0AFB4C',
    },
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  verySmallItem: {
    display: 'flex',
    padding: '6px 0px 6px 60px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
  additionalText: {
    padding: '10px 40px 0 40px',
  },
}));

const RefundPolicy = () => {
  const classes = useStyles();
  const { siteName } = useAppSelector<ISettings>((state) => state.users.settings);
  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>Money refund policy</div>
        <div className={classes.paragraphFirst}>
          <div>
            When you acquire credits to use our {siteName} service payment for credits occurs through the online payment
            processing center that for its part transmits us your information about debiting funds from a bank card for
            a specific credit pack. After payment, your money is transferred to the internal currency of our service.
            Thus, every time you paid services on our site credits are debited from your account but not from your bank
            card. The corresponding credit amount is charged for each sent letter, for each active minute in chat, for
            gifts and for contact information requests. The cost of these services may vary at any time. If you do not
            agree with these Terms of Use by our service do not press the registration button, and if you have already
            registered, but then realized that you do not like the existing conditions stop using our service. Each user
            has the right to request the refund of money or credits. Note! The administration of {siteName} reserves the
            right to make a final decision on the payment of money or credits. The administration is not responsible for
            the non-payment of money or credits. All credits that have not been used for 365 days from the date of their
            purchase are burned and cannot be renewed or refunded due to the lack of user activity on the site.
          </div>
        </div>
        <ol start={1} className={classes.headline}>
          <li>Reimbursement of credits to your account</li>
        </ol>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.1</div>
          <div className={classes.smallContent}>
            <div>Reimbursement of credits to your account is possible in cases edited in the Terms of use.</div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.2</div>
          <div className={classes.smallContent}>
            Credit compensation is also possible in case if you have lost them due to technical problems of the site.
            You need to give some confirmation of an error in the system and then the administration of our site will
            conduct an internal investigation on your issue.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.3</div>
          <div className={classes.smallContent}>
            <div>
              Reimbursement is also possible if you have a suspicion that one of the users is trying to deceive you. In
              this case, you need to contact support and provide confirmation of your concerns. After our investigation,
              if the fact of the scam will be proven, you will be refunded the partial or full cost of credits spent on
              communication with this user.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.4</div>
          <div className={classes.smallContent}>
            <div>
              Also reimbursement may be according to other factors with which you addressed our service support through
              the communication form. Internal investigation will be conducted on which the decision of possible return
              will be made.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.5</div>
          <div className={classes.smallContent}>
            <div>
              You do not have the right for refunding if you are only discontent with the Site or any of Services
              offered through the site.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.6</div>
          <div className={classes.smallContent}>
            <div>
              letters, sms’s in dialog chat, virtual gifts, private photos sent to a lady on the site, which remain
              unread or unanswered are not eligible for a refund of credits.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.7</div>
          <div className={classes.smallContent}>
            <div>
              When you send an letters, sms’s in dialog chat, virtual gifts, private photos and they are forwarded to a
              recipient's mailbox, this means that a letters, sms’s in dialog chat, virtual gifts, private photos are
              formatted correctly and thus you are not entitled to a refund. This includes, but is not limited to, cases
              such as accidentally sending duplicate letters, virtual gifts, private photos or a chat session that
              accidentally has not been stopped.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.8</div>
          <div className={classes.smallContent}>
            <div>
              When you send an letters, sms’s in dialog chat, virtual gifts, private photos and they are forwarded to a
              recipient's mailbox, this means that a letters, sms’s in dialog chat, virtual gifts, private photos are
              formatted correctly and thus you are not entitled to a refund. This includes, but is not limited to, cases
              such as accidentally sending duplicate letters, virtual gifts, private photos or a chat session that
              accidentally has not been stopped.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.9</div>
          <div className={classes.smallContent}>
            <div>
              The Site Administration reserves the right to offer you to replace a refund by charging on your account
              bonus credits.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.10</div>
          <div className={classes.smallContent}>
            <div>
              The Site is not responsible for the loss of credits in case you have not learnt all the potentialities of
              the Site, and as a result, have not use the Services properly.
            </div>
          </div>
        </div>

        <ol start={2} className={classes.headline}>
          <li>Accrual of bonus credits.</li>
        </ol>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>2.1</div>
          <div className={classes.smallContent}>
            {siteName} can make the accrual of bonuses not even informing the user. From this it follows that some users
            can be added bonus credits without debiting additional funds. Also {siteName} has the right to make
            promotional prices for packages of services or accrue additional gift credits to users.
          </div>
        </div>
        <ol start={3} className={classes.headline}>
          <li>Return of money </li>
        </ol>
        <div className={classes.breakline}></div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.1</div>
          <div className={classes.smallContent}>
            If you decide to remove your account on {siteName} the procedure of refund is like this. If for the entire
            period of use you did not have any violations of the rules you may be returned your money minus the
            commission of the online payment center. Another important condition is that it is not 365 days from the
            date of the purchase of credits. All refund requests are accepted online and processed within 7 days. The
            administration reserves the right to make a final decision on the amount of payment. The decision of the
            administration is not subject to appeal. In case of making a positive decision on the payment, money will be
            transferred to that card from which the last payment was performed.
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>3.2</div>
          <div className={classes.smallContent}>
            <div>
              You need to directly contact the online payment center in case you want a return of funds that you lost in
              the consequence of an error of charging operation or your own error in using the site or if you are
              worried that your card data can be used in unlawful purposes. Service {siteName} never asks for your bank
              cards and your bank card information details and never takes participation in charging funds from your
              bank account.
            </div>
            <div>The processing center of online payment takes full responsibility for these operations.</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RefundPolicy;
