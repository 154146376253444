import { UserProfile } from '@/types/user-profile.types';
import { HttpService, WsService } from './http.service';
import { ErrorWrapper } from './utils';
import { StorageService } from './storage.service';

function transformObjectToQueryParams(obj) {
  let queryParams = [];

  for (let key in obj) {
    let value = obj[key];

    if (value !== null && value !== undefined && value !== '' && value !== false) {
      if (Array.isArray(value)) {
        let fromParam = `${key}From=${value[0]}`;
        let toParam = `${key}To=${value[1]}`;
        queryParams.push(fromParam);
        queryParams.push(toParam);
      } else {
        queryParams.push(`${key}=${value}`);
      }
    }
  }

  return queryParams.join('&');
}

export default class ProfilesService {
  static get entity(): string {
    return 'users';
  }

  static get availableEntity(): string {
    return 'profiles/online';
  }

  static get bookmarksEntity(): string {
    return 'bookmarks/profiles';
  }

  static get bookmarksUpdateEntity(): string {
    return 'bookmarks';
  }

  static get pinnedEntity(): string {
    return 'dialogs/pin/profile';
  }

  static async getAllProfilesByPageId(id: string): Promise<{ data: UserProfile[]; total: number }> {
    try {
      const response = await HttpService.get(`/${this.entity}?pageId=${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getProfileByPageId(id: string): Promise<UserProfile> {
    try {
      const response = await HttpService.get(`/${this.entity}/page/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getProfileById(id: string): Promise<UserProfile> {
    try {
      const token = StorageService.getToken();
      if (token) {
        const response = await HttpService.get(`/${this.entity}/${id}`);
        return response.data;
      } else {
        const response = await HttpService.get(`/${this.entity}/${id}`);
        return response.data;
      }
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getUsers(_config: object): Promise<UserProfile[]> {
    let config = Object.assign({}, _config);

    try {
      const params = `${transformObjectToQueryParams(config)}`;

      const response = await HttpService.get(`/${this.entity}${params.length ? `?${params}&limit=100` : '?limit=100'}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateAvatar(data: { avatar: string }): Promise<UserProfile> {
    try {
      const response = await HttpService.patch(`/${this.entity}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async startGameRequest(data:{ hostUserId, guestUserId, reward }): Promise<any> {
    try {
      const response = await HttpService.post(`/gaming-history/start`, data);
      return response.data;
    } catch (error: any) {
      console.log('error', error);
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async wonGame(data:{  gameId, winnerUserId, status }): Promise<any> {
    try {
      const response = await HttpService.post(`/gaming-history/end`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async updateProfile(data: UserProfile): Promise<UserProfile> {
    try {
      const response = await HttpService.patch(`/${this.entity}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getAvailableUsers(_config: object): Promise<UserProfile[]> {
    try {
      const token = StorageService.getToken();
      if (!token) {
        return [];
      }
      const response = await WsService.get(`/${this.availableEntity}`);
      return response?.data || [];
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async getBookmarks(_config: object): Promise<UserProfile[]> {
    try {
      const response = await HttpService.get(`/${this.bookmarksEntity}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async setBookmarks(id: string): Promise<void> {
    try {
      const data = {
        id,
      };
      const response = await HttpService.post(`/${this.bookmarksUpdateEntity}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteBookmarks(id: string): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.bookmarksUpdateEntity}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async setPinned(id: string): Promise<void> {
    try {
      const data = {
        id,
      };
      const response = await HttpService.post(`/${this.pinnedEntity}/${id}`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deletePinned(id: string): Promise<void> {
    try {
      const response = await HttpService.delete(`/${this.pinnedEntity}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

}
