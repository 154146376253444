import React, { Fragment } from "react";
import GeneralSnackbar from "../core/snackbar";
import Footer from "../footer.component";
import Header from "../header.component";

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Fragment>
      <GeneralSnackbar />
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
