import { useAppSelector } from '@/app/hooks';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment } from 'react';

import BaseButton from '../base/button.component';

type LogOutLayoutProps = {
  logout: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  smt: {
    cursor: 'pointer',
    [theme.breakpoints.between('lg', 'xl')]: {
      fontSize: '12px !important',
    },
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  formActions: {
    marginTop: 30,
    width: '100%',
  },
  formLogOutBtn: {
    width: '100%',
  },
}));

const LogOutLayout = ({ logout }: LogOutLayoutProps) => {
  const classes = useStyles();
  const isLoading = useAppSelector((state) => state.auth.authIsLoading);

  return (
    <Fragment>
      <div className={classes.dialogTitle}>
        <Typography variant='h2' component='h2' gutterBottom>
          Logout
        </Typography>
      </div>
      <div>
        <Typography variant='h6' component='h6' gutterBottom>
          Your chat correspondence data will be cleared. Are you sure?
        </Typography>
      </div>
      <div className={classes.formActions}>
        <BaseButton
          loading={isLoading}
          onClick={logout}
          color='primary'
          text='Log out'
          className={classes.formLogOutBtn}
        />
      </div>
    </Fragment>
  );
};

export default LogOutLayout;
