import { HttpService } from "./http.service";
import { ErrorWrapper } from "./utils";

export interface BookmarkShape {
  id: string;
  createdAt: Date;
  name: string;
  avatar: string;
  seen: Date;
  activeAt: Date;
}

export default class BookmarksService {
  static get entity(): string {
    return "bookmarks";
  }

  static async getBookmarks(): Promise<Array<BookmarkShape>> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.get(request);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async addToBookmark(
    id: string
  ): Promise<Array<boolean>> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.post(request, {
        id
      });

      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async deleteContact(
    id: string
  ): Promise<boolean> {
    const request = `/${this.entity}`

    try {
      const response = await HttpService.delete(`${request}/${id}`);
      return response.data;
    } catch (error: any) {
      const message = error.response.data
        ? error.response.data.error
        : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }
}
