export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const GET_OWNER_PROFILE_REQUEST = 'GET_OWNER_PROFILE_REQUEST';
export const GET_OWNER_PROFILE_SUCCESS = 'GET_OWNER_PROFILE_SUCCESS';
export const GET_OWNER_PROFILE_FAILURE = 'GET_OWNER_PROFILE_FAILURE';

export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const CLEAR_USER_SUCCESS = 'CLEAR_USER_SUCCESS';

export const GET_SEARCH_USERS_REQUEST = 'GET_SEARCH_USERS_REQUEST';
export const GET_SEARCH_USERS_SUCCESS = 'GET_SEARCH_USERS_SUCCESS';
export const GET_SEARCH_USERS_FAILURE = 'GET_SEARCH_USERS_FAILURE';

export const UPDATE_CURRENT_USER_PROFILE_REQUEST = 'UPDATE_CURRENT_USER_PROFILE_REQUEST';
export const UPDATE_CURRENT_USER_PROFILE_SUCCESS = 'UPDATE_CURRENT_USER_PROFILE_SUCCESS';
export const UPDATE_CURRENT_USER_PROFILE_FAILURE = 'UPDATE_CURRENT_USER_PROFILE_FAILURE';

export const UPDATE_CURRENT_USER_AVATAR_REQUEST = 'UPDATE_CURRENT_USER_AVATAR_REQUEST';
export const UPDATE_CURRENT_USER_AVATAR_SUCCESS = 'UPDATE_CURRENT_USER_AVATAR_SUCCESS';
export const UPDATE_CURRENT_USER_AVATAR_FAILURE = 'UPDATE_CURRENT_USER_AVATAR_FAILURE';

export const GET_AVAILABLE_USERS_REQUEST = 'GET_AVAILABLE_USERS_REQUEST';
export const GET_AVAILABLE_USERS_SUCCESS = 'GET_AVAILABLE_USERS_SUCCESS';
export const GET_AVAILABLE_USERS_FAILURE = 'GET_AVAILABLE_USERS_FAILURE';

//SOCKETS MESSAGE

export const SET_BOOKMARKS_REQUEST = 'SET_BOOKMARKS_REQUEST';
export const SET_BOOKMARKS_SUCCESS = 'SET_BOOKMARKS_SUCCESS';
export const SET_BOOKMARKS_FAILURE = 'SET_BOOKMARKS_FAILURE';

export const GET_BOOKMARKS_REQUEST = 'GET_BOOKMARKS_REQUEST';
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
export const GET_BOOKMARKS_FAILURE = 'GET_BOOKMARKS_FAILURE';

export const DELETE_BOOKMARKS_REQUEST = 'DELETE_BOOKMARKS_REQUEST';
export const DELETE_BOOKMARKS_SUCCESS = 'DELETE_BOOKMARKS_SUCCESS';
export const DELETE_BOOKMARKS_FAILURE = 'DELETE_BOOKMARKS_FAILURE';

export const SET_UPDATE_AVAILABLE_USERS = 'SET_UPDATE_AVAILABLE_USERS';
export const SET_DISCONNECTED_AVAILABLE_USERS = 'SET_DISCONNECTED_AVAILABLE_USERS';

export const DISCONNECT_CHAT = 'DISCONNECT_CHAT';

export const UPDATE_BALANCE_REQUEST = 'UPDATE_BALANCE_REQUEST';
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS';
export const UPDATE_BALANCE_FAILED = 'UPDATE_BALANCE_FAILED';

export const CHARGE_BALANCE_REQUEST = 'CHARGE_BALANCE_REQUEST';
export const CHARGE_BALANCE_SUCCESS = 'CHARGE_BALANCE_SUCCESS';
export const CHARGE_BALANCE_FAILED = 'CHARGE_BALANCE_FAILED';

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';

export const GET_CREDIT_PACKS_REQUEST = 'GET_CREDIT_PACKS_REQUEST';
export const GET_CREDIT_PACKS_SUCCESS = 'GET_CREDIT_PACKS_SUCCESS';
export const GET_CREDIT_PACKS_FAILED = 'GET_CREDIT_PACKS_FAILED';

export const USER_BALANCE_CHARGED_SUCCESS = 'USER_BALANCE_CHARGED_SUCCESS';

export const SET_USER_HEADER_SEARCH = 'SET_USER_HEADER_SEARCH';

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';

export const CLEAR_AVAILABLE_USERS_SUCCESS = 'CLEAR_AVAILABLE_USERS_SUCCESS';

export const SEND_INVITES_SUCCESS = 'SEND_INVITES_SUCCESS';

export const INCOMING_INVITES_SUCCESS = 'INCOMING_INVITES_SUCCESS';

export const ACCEPT_INVITE_SUCCESS = 'ACCEPT_INVITE_SUCCESS';

export const DECLINE_INVITE_SUCCESS = 'DECLINE_INVITE_SUCCESS';

export const SEND_GAME_ID = 'SEND_GAME_ID';

export const START_GAME_REQUEST = 'START_GAME_REQUEST';

export const START_GAME_SUCCESS = 'START_GAME_SUCCESS';

export const END_GAME_SUCCESS = 'END_GAME_SUCCESS';

export const ASSUME_SUCCESS = 'ASSUME_SUCCESS';

export const USER_RIGHT_NUMBERS = 'USER_RIGHT_NUMBERS';

export const YOU_WON_REQUEST = 'YOU_WON_REQUEST';

export const YOU_WON_SUCCESS = 'YOU_WON_SUCCESS';
