import { useAppSelector } from '@/app/hooks';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import { UserDTO } from '@/app/users/users.reducer';
import ProfilesService from '@/services/profiles.service';
import { UserProfile } from '@/types/user-profile.types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

type ResponseUseUserPhotos = [isLoading: boolean, profile: any];

const useUserProfile = (): ResponseUseUserPhotos => {
  const [isLoading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Pick<UserDTO, 'email' | 'name' | 'id'> | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector((state) => state.users.currentUser);

  const { id } = useParams<{
    id: string;
  }>();

  const fetchProfile = useCallback(
    async (id: string) => {
      try {
        const response = await ProfilesService.getProfileById(id);
        if (response) {
          const normalizedUser = {
            id: response.id,
            name: response.name,
            avatar: response.avatar,
            email: null,
          };
          setProfile(normalizedUser);

          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (!id) return navigate(-1);
    if (currentUser?.id === id) {
      setLoading(false);
      setProfile(currentUser);
    } else {
      try {
        fetchProfile(id);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [currentUser, fetchProfile, id, navigate]);

  return [isLoading, profile];
};

export default useUserProfile;
