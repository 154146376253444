import { useCallback, useEffect, useMemo, useState } from "react";
import BaseCard from "@/components/base/base-card.component";
import { useAppBreakpoints, useContainerWidth } from "@/hooks";
import { CircularProgress, Container, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import BaseButton from "@/components/base/button.component";
import { toggleSnackbarOpen } from "@/app/ui/ui.actions";
import { useAppDispatch } from "@/app/hooks";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import UnfoldMoreOutlined from "@mui/icons-material/UnfoldMoreOutlined";
import PaymentsService from "@/services/payments.service";
import { formatDateTimeUTC } from "@/utils/formatDate";
import { ExpandableTableRow } from "@/components/base/ExpndableRow";


const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 27,

    [theme.breakpoints.down("md")]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      marginBottom: 20,
      alignItems: 'flex-start',
    },
  },

  headerButtonWrapper: {
    display: "flex",

    [theme.breakpoints.down("sm")]: {
      marginTop: 20
    },
  },

  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  loadMoreWrapper: {
    alignSelf: "center",
    minWidth: "200px !important",
  },

  loadMoreBtn: {
    maxWidth: 200,
    minWidth: 200,
  },

  loadButtonSection: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    marginTop: '28px'
  },

  tableHeader: {
    backgroundColor: '#004FC6',
    '& th': {
      fontSize: '16px',
      lineHeight: '28px',
      borderBottom: 'none',
      color: '#ffffff',
      padding: '20px 16px',
      paddingLeft: '0',

      [theme.breakpoints.down(675)]: {
        padding: '20px 2px',
      },

      [theme.breakpoints.down("sm")]: {
        padding: '14px 10px',
      },


      '&:first-child': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        paddingLeft: '20px',

      },
      '&:last-child': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',

        "& > $headerCell": {
          borderRight: 'none',
          paddingRight: '0',
        },

      },
    }
  },

  headerCell: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRight: '1px solid white',
    paddingRight: '10px',
  },

  headerPackCell: {
    whiteSpace: 'nowrap',
  },

  tableBody: {
    '& td': {
      fontSize: '16px',
      lineHeight: '32px',
      padding: '20px 16px',
      paddingLeft: '0',

      [theme.breakpoints.down("sm")]: {
        padding: '20px 10px',
      },

      '&:first-child': {
        paddingLeft: '20px',
      },
    }
  },

  infoName: {
    lineHeight: '18px',
  },

  infoId: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },

  selectField: {
    minWidth: 246,
    [theme.breakpoints.down("md")]: {
      minWidth: 125,
      "& .MuiFilledInput-input": {
        maxWidth: '75px !important'
      },
    },
  },

  searchHeader: {
    minWidth: 246,
    padding: '0 10px',
    [theme.breakpoints.down("sm")]: {
      minWidth: 125,
      padding: '0 5px',
      "& input": {
        width: '97px !important'
      },
    },
  },

  increasePadding: {
    paddingLeft: 5
  },

  noOrders: {
    margin: '0 auto',
    border: '2px dashed #004FC6',
    padding: '10px',
    width: '150px'
  }
}));

const columns: { id: string; label: string }[] = [
  { id: "creditPackId", label: "S/N" },
  { id: "creditPackName", label: "Pack Name" },
  { id: "credits", label: "Credits" },
  { id: "price", label: "Price" },
  { id: "createdAt", label: "Date" },
];

const mobileColumns: { id: string; label: string }[] = [
  { id: "creditPackName", label: "Pack Name" },
  { id: "createdAt", label: "Date" },
  { id: "arrow", label: "" },
];

const PaymentHistory = () => {
  const stylesOverrides = useMemo(() => (
    {
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
    }
  ), []);

  const classes = useStyles();
  const width = useContainerWidth();
  const dispatch = useAppDispatch();
  const { xs } = useAppBreakpoints();

  const [paymentItems, setPaymentItems] = useState([]);
  const [isPaginationOver, setIsPaginationOver] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [paginationOffset, setPaginationOffset] = useState<number>(0);

  const [sortBy, setSortBy] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  const handleSortBy = (id: string) => {
    setSortBy(id);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  useEffect(() => {
    setPaginationOffset(0);
    setPaymentItems([]);
    setIsPaginationOver(false);
    loadMore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortOrder])

  const loadMore = useCallback(
    async (offset = 0) => {
      setIsLoading(true);

      try {

        const response = await PaymentsService.getPayments(offset, sortBy, sortOrder);

        if (response.length < 20) {
          setIsPaginationOver(true);
        }

        setPaymentItems(!offset ? response : paymentItems.concat(response));
      } catch {
        dispatch(
          toggleSnackbarOpen(
            "Something goes wrong during request execution",
            "error"
          )
        )
      }

      setPaginationOffset(offset);

      setIsLoading(false);
    },
    [ dispatch, paymentItems, sortBy, sortOrder]
  );

  const getLoadButton = useMemo(() => {
    if (isPaginationOver || !paymentItems.length) return null;
    if (isLoading) return <CircularProgress color="secondary" style={{ margin: "0 auto" }} />;

    return <BaseButton
      text="Load More (20)"
      color="primary"
      wrapperClass={classes.loadMoreWrapper}
      className={classes.loadMoreBtn}
      onClick={() => loadMore(paginationOffset + 20)}
    />

  }, [classes.loadMoreBtn, classes.loadMoreWrapper, isLoading, isPaginationOver, loadMore, paymentItems.length, paginationOffset])

  const formatedCreatedAtDate = useCallback((createdAt: Date) => {
    const dateTime = formatDateTimeUTC(createdAt);
    return dateTime;
  }, []);

  const getTableCell = useCallback((row: any, column: any) => {
    if (column.id === 'createdAt') {
      return <>
        <div>{formatedCreatedAtDate(row[column.id]).time}</div>
        <div>{formatedCreatedAtDate(row[column.id]).date}</div>
      </>;
    }

    return row[column.id]
  }, [formatedCreatedAtDate])

  const isAnyOrderItems = useMemo(() => !!paymentItems?.length, [paymentItems])

  return <Container
    maxWidth={width}
    className={classes.contentWrapper}
    style={stylesOverrides.contentWrapper}
  >
    <BaseCard wrapperOverrideStyle={{ margin: 0, paddingBottom: 10 }}>
      <div
        className={classes.cardWrapper}
      >
        <header className={classes.header}>
          <Typography variant="h2">Payments History</Typography>
        </header>

        {
          isAnyOrderItems ?
            xs ? (
              // mobile table
              <TableContainer>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {mobileColumns.map((column, index) => (
                        <TableCell
                          key={column.id}
                          onClick={() => index === 2 ? () => { } : handleSortBy(column.id)}
                        >
                          {
                            index === 2 ?
                              <></> :
                              <div className={classes.headerCell}>
                                {column.label}
                                <UnfoldMoreOutlined />
                              </div>
                          }

                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {paymentItems.map(row => (
                      <ExpandableTableRow
                        key={row.name}
                        expandComponent={{
                          'S/N': row.creditPackId,
                          'Pack name': `${row.creditPackName}`,
                          'Credits': `${row.credits}`,
                          'Price': `${row.price}`,
                        }}
                      >
                        {mobileColumns.flatMap((column, index) => (
                          index === 2 ? [] :
                            <TableCell key={column.id}>
                              {getTableCell(row, column)}
                            </TableCell>

                        ))}
                      </ExpandableTableRow>
                    ))}

                  </TableBody>

                </Table>
              </TableContainer>) :
              <TableContainer>
                <Table>
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      {columns.map((column, index) => (
                        <TableCell
                          key={column.id}
                          onClick={() => handleSortBy(column.id)}
                        >
                          <div className={`${classes.headerCell} ${index === 1 ? classes.headerPackCell : '' }`}>
                            {column.label}
                            <UnfoldMoreOutlined />
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {paymentItems.map((row) => (
                      <TableRow key={row.creditPackId}>
                        {columns.map((column) => (
                          <TableCell key={column.id}>
                            {getTableCell(row, column)}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer> :
            <div className={classes.noOrders}>There are no payments</div>
        }


        <div className={classes.loadButtonSection}>
          {getLoadButton}
        </div>
      </div>
    </BaseCard>
  </Container>
}

export default PaymentHistory;

