import React, { useState, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { acceptInvite, declineInvite } from '@/app/users/users.actions';
import { Modal, Box, Typography, Button } from '@mui/material';
import Socket from '@/services/Socket';

const InvitesSubscriber = () => {
  const dispatch = useAppDispatch();
  const currentInvites = useAppSelector((state) => state.users.currentInvites);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentInvites.length > 0) {
      setOpen(true);
    }
  }, [currentInvites]);

  const handleAccept = useCallback(() => {
    dispatch(
      acceptInvite({
        userId: currentInvites[0].userId,
        opponentUserId: currentInvites[0].opponentUserId,
        isMyInvite: currentInvites[0].isMyInvite,
        credits: currentInvites[0].credits,
      })
    );
    setOpen(false);
  }, [currentInvites, dispatch]);

  const handleDecline = useCallback(() => {
    dispatch(
      declineInvite({
        userId: currentInvites[0].userId,
        opponentUserId: currentInvites[0].opponentUserId,
        isMyInvite: currentInvites[0].isMyInvite,
        credits: currentInvites[0].credits,
      })
    );
    setOpen(false);
  }, [currentInvites, dispatch]);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby='invite-modal-title'
      aria-describedby='invite-modal-description'
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id='invite-modal-title' variant='h6' component='h2'>
          Invite
        </Typography>
        <Typography id='invite-modal-description' sx={{ mt: 2 }}>
          You wanna start game: {currentInvites[0]?.userId}
        </Typography>
        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
          <Button variant='contained' color='primary' onClick={handleAccept}>
            Accept
          </Button>
          <Button variant='contained' color='secondary' onClick={handleDecline}>
            Decline
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InvitesSubscriber;
