import { Divider, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { UserDTO } from '@/app/users/users.reducer';
import { ReactComponent as AddUserIcon } from '@/assets/icons/contacts-plus.svg';
import { ReactComponent as ContactsIcon } from '@/assets/icons/new-mail-contacts-purple.svg';
import Upload from '@mui/icons-material/Upload';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

import { useAppBreakpoints } from '@/hooks';
import BaseCard from '@/components/base/base-card.component';
import BaseButton from '@/components/base/button.component';
import useProfileRelation from '@/hooks/useProfileRelation';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import SendInviteModalModal from './send-invite-modal.component';
import getImageByAvatar from '@/hooks/getImageByAvatar';
import AvatarSelectionModal from './avatar-selection.modal';

const useStyles = makeStyles<Theme, { avatar: string }>((theme: Theme) => ({
  marginBlockUtil: {
    marginBottom: 24,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 8,
    },
  },

  generalInfoWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },

  mainWrapper: {
    display: 'flex',
    width: '100%',
  },

  photoWrapper: {
    maxWidth: 252,
    aspectRatio: '252 / 358',
    width: '100%',
    position: 'relative',
    overflow: 'hidden',

    [theme.breakpoints.down('md')]: {
      maxWidth: 72,
      maxHeight: 76,
    },
  },
  photoWrapperHover: {
    '&:hover $uploadPhoto	': {
      bottom: 0,
    },
  },
  uploadPhoto: {
    fontWeight: 800,
    backgroundColor: 'rgba(0,0,0,.5)',
    width: '100%',
    height: 45,
    position: 'absolute',
    bottom: -45,
    transition: 'all .3s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: 'white',

    '&:hover': {
      color: 'rgb(80, 62, 182)',
    },
  },
  photoInner: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
  },
  profilePhoto: {
    width: '100px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 10,
    height: '100px',
    backgroundImage: (props) => {
      return `url("${getImageByAvatar(props.avatar)}")`;
    },
  },
  infoWrapper: {
    width: '100%',
    marginLeft: 28,
    [theme.breakpoints.down('md')]: {
      marginLeft: 16,
      marginBottom: 6,
    },
  },
  profileTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
    },
  },
  profileUsername: {
    fontWeight: 700,
    fontSize: 34,
    overflowWrap: 'anywhere',

    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  id: {
    fontSize: 14,
    lineHeight: '20px',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '16px',
    },
  },
  generaInfoCard: {
    width: '100%',
    display: 'flex',
    padding: '28px 28px 32px',

    [theme.breakpoints.down('md')]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
  infoEditIcon: {
    minWidth: 28,
    maxWidth: 28,
    height: 28,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.info.main,
    borderRadius: 9999,
    marginLeft: 8,
  },
  infoEditButton: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#000',
    marginRight: 15,
    '&:hover $infoEditIcon': {
      opacity: 0.8,
    },
  },
  profileStatus: {
    display: 'block',
  },
  profileMetaInfoDesktop: {
    display: 'flex',
    marginTop: 24,
    gap: 22,
    flexWrap: 'wrap',
  },
  myProfileMetaContainer: {
    display: 'flex',
    width: '100%',
  },
  myProfileMetaAction: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  myProfileMetaInfo: {
    display: 'flex',
    marginLeft: 9,
    flexDirection: 'column',
  },
  profileListInfo: {
    display: 'block',
    paddingTop: 16,
    paddingBottom: 24,

    [theme.breakpoints.down('xl')]: {
      paddingBottom: 0,
    },

    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  // class="grid grid-cols-2 gap-x-1 my-auto"
  profileMetaInfo: {
    display: 'none',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    columnGap: 1,
    marginTop: 'auto',
    marginBottom: 'auto',

    [theme.breakpoints.up('md')]: {
      dispaly: 'grid',
    },
  },
  // class="flex items-center space-x-2"
  profileMetaInfoItem: {
    display: 'flex',
    alignItems: 'center',
  },

  profileMetaInfoItemIcon: {
    flexShrink: 0,
    width: 32,
    height: 32,

    [theme.breakpoints.down('md')]: {
      width: 18,
      height: 18,
    },
  },

  // class="text-xs md:text-base xl:text-sm leading-none"
  profileMetaText: {
    marginLeft: 8,

    fontSize: '0.875rem',
    lineHeight: '1.25rem',

    [theme.breakpoints.down('xl')]: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
    },
  },

  // class="xl:text-lg !leading-none"
  profileMetaCounter: {
    fontSize: 18,
    lineHeight: '28px',

    [theme.breakpoints.down('xl')]: {
      fontSize: 16,
      lineHeight: '18px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      lineHeight: '18px',
    },
  },
  profileMetaDivider: {
    display: 'inline',
    [theme.breakpoints.down('md')]: {
      visibility: 'hidden',
    },
  },
  profileStatusMobile: {
    display: 'none',
    margin: '16px 0',

    [theme.breakpoints.down('md')]: {
      display: 'flex',
    },
  },
  uploadWrapper: {
    marginBottom: 15,
  },

  metaCtaIcon: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },

  whiteCameraIcon: {
    marginLeft: '20px !importnant',
  },

  photoButtonContainer: {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },

  avatarContainer: {
    transform: 'translateX(10px)',
    marginBottom: 20,
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: 10,
      opacity: 0,
      transition: 'opacity',
      backgroundColor: 'linear-gradient(to right bottom, rgb(80, 62, 182), rgb(234, 65, 96))',
      '&:focus, &:hover': {
        opacity: 0.7,
      },
    },
  },
  avatarStatuses: {
    display: 'flex',
    flexDirection: 'column',

    position: 'absolute',
    bottom: 6,
    left: 6,
    zIndex: 1,
  },
  cardOverrides: {
    flexDirection: 'column',
  },
  onlineStatus: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    [theme.breakpoints.down('md')]: {
      bottom: 4,
      left: 4,
    },
  },
  status: {
    [theme.breakpoints.down('md')]: {
      display: 'none !important',
    },
  },
  editWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  aboutMeIcon: {
    cursor: 'pointer',
    height: 16,
  },
  takePhotoButton: {
    width: '250px !important',
    height: '42px !important',
    marginLeft: '10px !important',
  },
}));

type UserGeneralInfoProps = {
  currentProfile: UserDTO;
};

const UserGeneralInfo = ({ currentProfile }: UserGeneralInfoProps) => {
  const [isMyUser] = useProfileRelation();
  const dispatch = useAppDispatch();

  const currentAvatar = useAppSelector((state) => state?.users?.currentUser?.avatar);

  const classes = useStyles({ avatar: currentAvatar });
  const { mdAndUp, smAndDown } = useAppBreakpoints();
  const navigate = useNavigate();

  const bookMarksCount = useAppSelector((state) => state.users.bookmarks) || [];
  const [openInviteModal, setOpenInviteModal] = useState(false);

  const metaActions = useMemo(() => {
    return [
      {
        icon: <PlayCircleIcon />,
        onClick: async () => {
          setOpenInviteModal(true);
        },
      },
    ];
  }, [currentProfile?.id, dispatch]);

  console.log('isMyUser', isMyUser);

  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <SendInviteModalModal
        handleClose={() => {
          setOpenInviteModal(false);
        }}
        userId={currentProfile?.id}
        open={openInviteModal}
      />
      <AvatarSelectionModal
        isOpen={isModalVisible}
        handleClose={() => {
          setModalVisible(false);
        }}
      />
      <BaseCard cardOverrideStyle={classes.cardOverrides}>
        <div className={classes.mainWrapper}>
          <div className={clsx(classes.photoWrapper && classes.photoWrapperHover)}>
            <div className={classes.photoInner}>
              <div className={classes.profilePhoto} />
              {isMyUser && (
                <div className={classes.uploadPhoto} onClick={() => setModalVisible(true)}>
                  <Upload />
                  {mdAndUp && 'Update avatar'}
                </div>
              )}
            </div>
          </div>

          <div className={classes.infoWrapper}>
            <div className={clsx(classes.profileTitle, classes.marginBlockUtil)}>
              <div>
                <div className={classes.profileUsername}>{currentProfile?.name}</div>
                <div className={classes.id}>ID: {currentProfile?.id}</div>
              </div>
            </div>

            {mdAndUp && <Divider sx={{ height: 24 }} orientation='horizontal' />}

            <div className={classes.profileMetaInfoDesktop}>
              {isMyUser ? (
                <div className={classes.myProfileMetaContainer}>
                  <div className={classes.myProfileMetaAction}>
                    <BaseButton
                      inverted
                      text=''
                      className={classes.metaCtaIcon}
                      textStyle=''
                      onClick={() => {
                        navigate(`/user/contacts`);
                      }}
                      Icon={<ContactsIcon />}
                    />
                    <div className={classes.myProfileMetaInfo}>
                      {bookMarksCount.length}
                      <span>contact{bookMarksCount.length > 1 ? 's' : ''}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {metaActions.map((action, idx) => (
                    <BaseButton
                      key={idx}
                      inverted
                      text=''
                      className={classes.metaCtaIcon}
                      textStyle=''
                      onClick={action.onClick}
                      Icon={action.icon}
                    />
                  ))}
                </>
              )}
            </div>

            <div className={classes.profileMetaInfo}>
              <div className={classes.profileMetaInfoItem}>
                <AddUserIcon className={classes.profileMetaInfoItemIcon} />
                <p className={classes.profileMetaText}>
                  <span className={classes.profileMetaCounter}>22</span>
                  <br className={classes.profileMetaDivider} />
                  contacts
                </p>
              </div>
            </div>
          </div>
        </div>
      </BaseCard>
    </>
  );
};

export default UserGeneralInfo;
