import BaseCard from '@/components/base/base-card.component';
import { useAppBreakpoints, useContainerWidth } from '@/hooks';
import {
  Container,
  Theme,
  Slider,
  Checkbox,
  FormControlLabel,
  Typography,
  CircularProgress,
  Collapse,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import PrimaryBaseTextField from '@/components/base/text-field-base.component';
import BaseButton from '@/components/base/button.component';
import { ReactComponent as ArrowIcon } from '@/assets/icons/option-arrow.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/trash.svg';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { getSearchUsersRequest, setHeaderUserSearch } from '@/app/users/users.actions';
import BaseBadge from '@/components/base/base-badge.component';
import SlidersService from '@/services/sliders.service';
import SearchSlider from '@/components/sliders/search-slider.component';

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  searchItem: {
    minWidth: 174,
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  formRow: {
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',

    '&>div': {
      flexWrap: 'wrap',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        width: '100%',
      },
    },
  },
  selectField: {
    margin: '0',
    width: 'auto',
    minWidth: 176,
  },
  searchButton: {
    flex: '1 1 180px',
    maxWidth: 180,
    marginLeft: 'auto',
    display: 'flex',
    gap: 12,
  },
  searchButtonInner: {
    width: '100%',
  },
  searchButtonBottom: {
    width: 150,
  },
  sliderRow: {},
  resetButton: {
    maxWidth: 42,
    maxHeight: 42,

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
    minWidth: '0 !important',
  },
  optionViewBtn: {
    cursor: 'pointer',
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  optionsWrapper: {
    display: 'flex',
    margin: '0 auto',
    marginTop: 22,
    alignItems: 'center !important',

    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      marginBottom: 22,
    },
  },
  resetText: {
    fontWeight: '600 !important',
    fontSize: '14px !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
  mobileCheckboxesSection: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 22,

    '&>label': {
      marginRight: 10,
      marginBottom: 10,
    },
  },
  optionsSelectContainer: {
    marginLeft: 'auto',
    display: 'flex',
    gap: 28,
    [theme.breakpoints.down('md')]: {
      fontSize: '10px !important',
    },
  },
  searchTitle: {
    fontWeight: '600',
    fontSize: '22px !important',
    lineHeight: '84px',
    color: '#000000',
  },
  noUsers: {
    margin: '0 auto',
    border: '2px dashed #004FC6',
    padding: '10px',
    width: '142px',
  },
}));

const stylesOverrides = {
  contentWrapper: {
    padding: 0,
    maxWidth: 968,
  },
};

const SearchUsers = () => {
  const classes = useStyles();
  const { smAndDown, mdAndUp } = useAppBreakpoints();

  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const userIsLoading = useAppSelector((state) => state.users.userIsLoading);
  const searchedUsers = useAppSelector((state) => state.users.searchUsers);
  const searchedHeaderUserId = useAppSelector((state) => state.users.headerUserSearch);

  const dispatch = useAppDispatch();
  const width = useContainerWidth();

  useEffect(() => {
    if (searchedHeaderUserId) {
      isNaN(searchedHeaderUserId)
        ? dispatch(getSearchUsersRequest({ name: searchedHeaderUserId }))
        : dispatch(getSearchUsersRequest({ pageId: searchedHeaderUserId }));

      dispatch(setHeaderUserSearch(null));
    } else {
      dispatch(getSearchUsersRequest({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Container maxWidth={width} className={classes.contentWrapper} style={stylesOverrides.contentWrapper}>
      <BaseCard wrapperOverrideStyle={{ margin: 0 }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Formik
            initialValues={{
              pageId: searchedHeaderUserId || '',
              isOnline: false,
            }}
            onSubmit={(values) => {
              const tempValues: any = { ...values };
              if (tempValues.pageId) {
                if (isNaN(tempValues.pageId)) {
                  tempValues.name = tempValues.pageId;
                  delete tempValues.pageId;
                }
              }

              dispatch(getSearchUsersRequest(tempValues));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    paddingBottom: 24,
                    borderBottom: '1px solid #E7E6E6',
                  }}
                >
                  <div className={classes.formRow}>
                    <PrimaryBaseTextField
                      label='Name or ID'
                      inputClassName=''
                      onChange={(event) => setFieldValue('pageId', event.target.value)}
                      value={values.pageId}
                    />
                    {mdAndUp && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.isOnline}
                              name='isOnline'
                              onChange={handleChange}
                              color='warning'
                            />
                          }
                          label='Online'
                        />

                        <div className={classes.searchButton} style={{ flexWrap: 'nowrap' }}>
                          <BaseButton
                            color='primary'
                            text='Search'
                            loading={userIsLoading}
                            className={classes.searchButtonInner}
                            onClick={(e: any) => handleSubmit()}
                          />

                          <BaseButton
                            inverted
                            text=''
                            className={classes.resetButton}
                            textStyle={classes.resetText}
                            onClick={() => resetForm()}
                            Icon={<TrashIcon />}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <div className={classes.optionsWrapper}>
                  {smAndDown && (
                    <BaseButton
                      color='primary'
                      text='Search'
                      loading={userIsLoading}
                      className={classes.searchButtonBottom}
                      onClick={(e: any) => handleSubmit()}
                    />
                  )}
                  <button
                    type='button'
                    className={classes.optionViewBtn}
                    onClick={() => setOptionsOpen(!isOptionsOpen)}
                  >
                    <Typography variant='link'>Advanced</Typography>
                    <ArrowIcon
                      style={{
                        marginLeft: 6,
                        width: 18,
                        height: 18,
                        transform: isOptionsOpen ? 'rotate(180deg)' : '',
                      }}
                    />
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </BaseCard>

      <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
        {userIsLoading && <CircularProgress color='secondary' style={{ margin: '0 auto' }} />}

        {!searchedUsers.total ? <div className={classes.noUsers}>There are no users</div> : null}

        {searchedUsers.data.length > 0 && !userIsLoading && (
          <SearchSlider
            fetcher={async () => {
              return [];
            }}
            data={searchedUsers.data}
          >
            {(data) => (
              <>
                <BaseBadge value={data} color='#0AFB4C'>
                  <Typography className={classes.searchTitle} variant='sliderTitle'>
                    Search Results
                  </Typography>
                </BaseBadge>
              </>
            )}
          </SearchSlider>
        )}
      </div>
    </Container>
  );
};

export default SearchUsers;
