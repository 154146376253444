export const TOGGLE_SNACKBAR_OPEN = 'TOGGLE_SNACKBAR_OPEN';
export const TOGGLE_SNACKBAR_CLOSE = 'TOGGLE_SNACKBAR_CLOSE';

export const TOGGLE_AVAILABLE_USERS_BAR = 'TOGGLE_AVAILABLE_USERS_BAR';
export const TOGGLE_LAST_USERS_BAR = 'TOGGLE_LAST_USERS_BAR';

export const TOGGLE_VIDEO_CHAT_OPEN = 'TOGGLE_VIDEO_CHAT_OPEN';
export const TOGGLE_MYSTREAM = 'TOGGLE_MYSTREAM';

export const TOGGLE_AUDIO = 'TOGGLE_AUDIO';

export const CLOSE_NOT_ENOUGH_BALANCE = 'CLOSE_NOT_ENOUGH_BALANCE';

export const OPEN_NOT_ENOUGH_BALANCE = 'OPEN_NOT_ENOUGH_BALANCE';
