import { UserDTO } from '@/app/users/users.reducer';
import { ReactComponent as ChatIcon } from '@/assets/icons/drawer-icons/chat.svg';
import { ReactComponent as PaymentsIcon } from '@/assets/icons/drawer-icons/coins.svg';
import { ReactComponent as ContactsIcon } from '@/assets/icons/drawer-icons/contacts.svg';
import { ReactComponent as GiftsIcon } from '@/assets/icons/drawer-icons/gifts.svg';
import { ReactComponent as GuestsIcon } from '@/assets/icons/drawer-icons/guests.svg';
import { ReactComponent as LogoutIcon } from '@/assets/icons/drawer-icons/logout.svg';
import { ReactComponent as MailIcon } from '@/assets/icons/drawer-icons/mail.svg';
import { ReactComponent as DialogIcon } from '@/assets/icons/drawer-icons/sms-dialog.svg';
import { ReactComponent as MenIcon } from '@/assets/icons/drawer-icons/men.svg';
import { ReactComponent as NewsIcon } from '@/assets/icons/drawer-icons/news.svg';
import { ReactComponent as NotificationsIcon } from '@/assets/icons/drawer-icons/notifications.svg';
import { ReactComponent as SettingsIcon } from '@/assets/icons/drawer-icons/settings.svg';
import { ReactComponent as TimerIcon } from '@/assets/icons/drawer-icons/timer.svg';
import { ReactComponent as WomanIcon } from '@/assets/icons/drawer-icons/women.svg';
import { useAppBreakpoints } from '@/hooks';
import { Badge, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

type DrawerLinkProps = {
  label: string;
  route: string;
  iconName: string;
  user: UserDTO | undefined;
  counter: boolean;
  count: string | null;
  onToggleMenu?: (event?: any) => void;
  handleLogout?: () => void;
  unreadCount?: number;
  openTab?: () => void;
  openDialogTab?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '7px 30px',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: ' Readex Pro',
    textDecoration: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  label: {
    display: 'inline-block',
  },
  activeLink: {
    color: theme.palette.primary.main,
    '& path': {
      fill: theme.palette.primary.main,
    },
  },
  defaultLink: {
    color: '#000',

    '& path': {
      fill: '#004FC6',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 14,
    minWidth: 16,
  },
  counter: {
    display: 'inline-block',
  },
}));

const DrawerLink = ({
  route,
  label,
  iconName,
  user,
  counter,
  count,
  onToggleMenu,
  unreadCount,
  handleLogout = () => {},
  openTab = () => {},
  openDialogTab = () => {},
}: DrawerLinkProps) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const { lgAndDown } = useAppBreakpoints();
  const theme: Theme = useTheme();

  const active = useMemo(() => {
    if (location.pathname.includes('/user/messages')) {
      return route.includes('/user/messages');
    }

    return location.pathname.includes(route);
  }, [location.pathname, route]);

  const iconset = {
    men: <MenIcon />,
    woman: <WomanIcon />,
    chat: <ChatIcon />,
    contacts: <ContactsIcon />,
    gifts: <GiftsIcon />,
    guests: <GuestsIcon />,
    mail: <MailIcon />,
    dialog: <DialogIcon />,
    news: <NewsIcon />,
    notifications: <NotificationsIcon />,
    profile: <TimerIcon />,

    settings: <SettingsIcon />,
    payments: <PaymentsIcon />,
    logout: <LogoutIcon />,
  };

  return (
    <div>
      <NavLink
        onClick={(event) => {
          if (route === 'logout') {
            event.preventDefault();
            handleLogout();
          }
          if (route === `/chat/${user?.profile?.id}`) {
            event.preventDefault();
            openTab();
            onToggleMenu();
            return;
          }
          if (route === `/dialog/${user?.profile?.id}`) {
            event.preventDefault();
            openDialogTab();
            onToggleMenu();
            return;
          }
          if (lgAndDown) {
            event.preventDefault();
            navigate(route);
            //@ts-ignore
            onToggleMenu();
          }
        }}
        to={route}
        className={clsx(classes.link, active ? classes.activeLink : classes.defaultLink)}
      >
        <div className={clsx(classes.iconWrapper, active ? classes.activeLink : classes.defaultLink)}>
          <Badge badgeContent={unreadCount} color='primary' style={{ padding: '2px 4px' }}>
            {/*// @ts-ignore */}
            {iconset[iconName]}
          </Badge>
        </div>
        <Typography
          fontSize='inherit'
          className={classes.label}
          style={{
            marginLeft: 10,
            color: active ? theme.palette.primary.main : '#000',
          }}
        >
          {label}
        </Typography>
        {counter && count && (
          <Typography
            fontSize='inherit'
            className={classes.counter}
            style={{
              marginLeft: 5,
              color: active ? theme.palette.primary.main : '#000',
            }}
          >
            ({count})
          </Typography>
        )}
      </NavLink>
    </div>
  );
};

export default DrawerLink;
