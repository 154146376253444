import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import objectSupport from "dayjs/plugin/objectSupport";
import * as yup from "yup";
import { object, string } from "yup";
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);

//About me
export const TEXT_AREA_EDIT_MAX = 1000;

export const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(1, "Seems a bit short").required(),
  name: yup.string().required(),
  agreed: yup.boolean().isTrue("Agreement is required for registration"),
});

export const resetPasswordSchema = yup.object().shape({
  code: yup.string().required(),
  password: yup.string().min(1, "Seems a bit short").required(),
});

export const editUserSchema = object({
  name: string().required().label("Name"),
});

export const aboutUserSchema = object({
  aboutMe: string().nullable().max(TEXT_AREA_EDIT_MAX).label("About Me"),
});


export const settingsUserSchema = object({
  password: yup.string()
    .min(1, 'Password must be 8 symbols min')
    .required('Enter password'),
    resetPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'Password mismatch')
    .required('Confirm password'),
});


export const validationSchemaLogin = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(1, "Seems a bit short").required(),
});

export const validationSchemaForgotPassword = yup.object().shape({
  email: yup.string().email().required(),
});

export const contactUsSchema = yup.object().shape({
  email: yup.string().email().required(),
  subject: yup.string(),
  message: yup.string(),
});