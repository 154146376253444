import AppBootstrap from '@/components/core/app-bootstrap';
import React from 'react';
import SocketSubscriber from './components/SocketSubscriber';
import MainRouter from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import InvitesSubscriber from './components/invitesSubscriber';
import GamesSubscriber from './components/GamesSubscriber';

function App() {
  return (
    <AppBootstrap>
      <Router>
        <SocketSubscriber />
        <InvitesSubscriber />
        <GamesSubscriber />
        <MainRouter />
      </Router>
    </AppBootstrap>
  );
}

export default App;
