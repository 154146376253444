import { Button } from '@mui/material';
import { useCallback } from 'react';

import { useAppSelector } from '@/app/hooks';
import { closeNotEnoughBalance } from '@/app/ui/ui.actions';
import { useDispatch } from 'react-redux';

import { Dialog, Theme, Typography, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '18px 10px',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },
  dialogButtonWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  dialogButton: {},
}));

const BalanceCheckComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isNotEnoughBalance = useAppSelector((state) => state.ui.isNotEnoughBalance);
  const notEnoughBalance = useAppSelector((state) => state.ui.notEnoughBalance);

  const closeNotEnoughBalanceHandler = useCallback(() => {
    dispatch(closeNotEnoughBalance());
  }, [dispatch]);

  const handleOpenCredits = useCallback(() => {
    window.open('/user/credits');
    dispatch(closeNotEnoughBalance());
  }, [dispatch]);

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
        paper: classes.paper,
      }}
      TransitionComponent={Zoom}
      open={isNotEnoughBalance}
      onClose={closeNotEnoughBalanceHandler}
    >
      <div className={classes.container}>
        <div className={classes.dialogTitle}>Insufficient funds</div>
        <Typography variant='h6'>
          Insufficient funds to perform this action. You should have at least {notEnoughBalance}
        </Typography>
        <div className={classes.dialogButtonWrapper}>
          <Button variant='contained' color='primary' onClick={handleOpenCredits}>
            Go to Credits
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default BalanceCheckComponent;
