import { combineReducers } from "redux";
import { PersistConfig, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./auth/auth.reducer";
import ui from "./ui/ui.reducer";
import users, { IUserState } from "./users/users.reducer";
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

const usersConfig: PersistConfig<Omit<IUserState, 'usersWithActiveTextChat'>> = {
  key: "users",
  storage,
  blacklist: ["currentGame", "gameHistory", "currentInvites"],
  stateReconciler: autoMergeLevel2,
};

const conf: PersistConfig<any> = {
  key: "root",
  storage,
  blacklist: ["ui", "mails", "users", "gifts"],
  stateReconciler: autoMergeLevel2,
};

const rootReducer = persistReducer(
  conf,
  combineReducers({
    auth,
    users: persistReducer(usersConfig, users),
    ui,
  })
);

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;