import { UserDTO } from '@/app/users/users.reducer';
import React from 'react';
import DrawerLink from './drawer-link.component';
import { useNavigate } from 'react-router-dom';
import { Badge, Typography } from '@mui/material';
import clsx from 'clsx';
import { ReactComponent as LogoutIcon } from '@/assets/icons/drawer-icons/logout.svg';
import { Theme } from '@emotion/react';
import { makeStyles } from '@mui/styles';

type DrawerBottomProps = {
  user: UserDTO | undefined;
  onToggleMenu?: (event?: any) => void;
  handleLogout?: () => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '7px 30px',
    fontSize: 16,
    fontWeight: 500,
    fontFamily: ' Readex Pro',
    textDecoration: 'none',
    cursor: 'pointer',
    boxSizing: 'border-box',
  },
  label: {
    display: 'inline-block',
  },
  defaultLink: {
    color: '#000',

    '& path': {
      fill: '#004FC6',
    },
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 14,
    minWidth: 16,
  },
  counter: {
    display: 'inline-block',
  },
}));

const DrawerBottom = ({ user, onToggleMenu = () => {}, handleLogout }: DrawerBottomProps) => {
  const navigate = useNavigate();

  const navItems = [
    {
      route: '/user/settings',
      label: 'Settings',
      iconName: 'settings',
      counter: false,
      count: null,
    },
    {
      route: '/user/payments',
      label: 'Payments',
      iconName: 'payments',
      counter: false,
      count: null,
    },
  ];

  const classes = useStyles();

  return (
    <div style={{ paddingTop: 25 }}>
      {navItems.flatMap(({ route, iconName, label, counter, count }) => {

        return (
          <DrawerLink
            handleLogout={handleLogout}
            onToggleMenu={onToggleMenu}
            route={route}
            label={label}
            iconName={iconName}
            key={label}
            user={user}
            counter={counter}
            count={`${count}` || null}
          />
        );
      })}

      <div className={clsx(classes.iconWrapper, classes.link)} onClick={handleLogout}>
        <Badge color='primary' style={{ padding: '2px 4px' }}>
          {/*// @ts-ignore */}
          {<LogoutIcon />}
        </Badge>
        <Typography
          fontSize='inherit'
          className={classes.label}
          style={{
            marginLeft: 10,
            color: '#000',
          }}
        >
          Log Out
        </Typography>
      </div>
    </div>
  );
};

export default DrawerBottom;
