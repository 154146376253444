import { useAppSelector } from '@/app/hooks';
import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    '& table, th, td': {
      border: '1px solid black',
      borderCollapse: 'collapse',
      padding: '5px',
    },
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  greyParagraph: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#0AFB4C',
    },
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  verySmallItem: {
    display: 'flex',
    padding: '6px 0px 6px 60px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
  additionalText: {
    padding: '10px 40px 0 40px',
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();
  const { siteName, companyName, companyAddress } = useAppSelector((state) => state.users.settings);

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>Privacy Policy</div>
        <div className={classes.paragraphFirst}>
          <div>Contents</div>
          <div>
            <ul>
              <li>About this Privacy Policy</li>
              <li>Information We May Collect and How We Collect It</li>
              <li>How We Use Information</li>
              <li>How We Disclose Information</li>
              <li>Interest-Based Advertising</li>
              <li>Your Rights and Choices</li>
              <li>Data Retention</li>
              <li>Security</li>
              <li>Use By Minors</li>
              <li>Additional Information for Residents of the EEA, United Kingdom, Switzerland, and Brazil</li>
              <li>Additional Information for Residents of California, Virginia, Connecticut, Utah, and Colorado</li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>
        <ol start={1} className={classes.headline}>
          <li>About this Privacy Policy</li>
        </ol>
        <div className={classes.paragraph}>
          This mmside OÜ põhikiri Privacy Policy (“Privacy Policy”) describes how and when we may collect, use, secure,
          and share information collected in connection with our games, mobile applications, websites, content,
          products, and any related services (collectively, the “Services”), and your choices regarding our processing
          of that information. For purposes of this Privacy Policy, “mmside OÜ põhikiri” (or “we” or “us”) refers to
          mmside OÜ põhikiri and its parents, subsidiaries, representatives, affiliates, officers, and directors for
          websites and applications that link to this Privacy Policy or otherwise make it available to you.
        </div>
        <div className={classes.paragraph}>
          Please read this Privacy Policy carefully. We may update it from time to time by posting a new version on
          grubgame.com with an updated date (the “Effective Date”). If we make material changes, we will notify you,
          prior to the changes becoming effective, through the Services or by other means if you’ve provided us
          additional contact information (e.g., using the email address associated with your Account, as defined in our
          Terms of Service). Your continued use of the Services after the Effective Date will be an acknowledgement of
          our updated practices.
        </div>
        <div className={classes.paragraph}>
          If there is any difference between the English version and any other language version of this Privacy Policy,
          the English version will apply (to the extent of the difference and to the extent permitted by applicable
          law).
        </div>
        <div className={classes.paragraph}>Information We May Collect and How We Collect It</div>
        <div className={classes.paragraph}>
          We may collect various types of information, including information you provide or make available to us
          directly, information collected from your devices (including on web and mobile), and information collected
          from other sources, like social networking sites and gaming partners. Some of this information may be
          considered “personal information” or “personal data” under applicable privacy or data protection laws.
        </div>
        <div className={classes.paragraph}>Information You Provide</div>
        <div className={classes.paragraph}>You may provide information directly to mmside OÜ põhikiri when you:</div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              Use or access the Services, including when you create a Numerical Polymath (as defined in our Terms) or
              make in-app purchases;
            </li>
            <li>Create a Numerical Polymath Account for logging in across multiple games or Services;</li>
            <li>
              Connect with or access our Services using a third-party ID or log-in, such as an ID for a social
              networking site or gaming service;
            </li>
            <li>
              Communicate with or contact others using our Services, including when you use chat, “invite a friend,”
              “email this page,” or use other similar features;
            </li>
            <li>Contact us, including for customer or technical support;</li>
            <li>Participate in mmside OÜ põhikiri offers, contests, or special events;</li>
            <li>Subscribe to newsletters or sign up to hear about current or upcoming products; and/or</li>
            <li>Complete surveys offered by us or on our behalf.</li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          Depending on how you choose to use the Services, you may provide us with the following types of information:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              Contact information such as your first and last name, username or handle, third-party service ID, email
              address, phone number, home address or shipping address, and other contact information;
            </li>
            <li>Images such as your photo, avatar, or other image;</li>
            <li>Biographic and Demographic information such as your date of birth, gender, and time zone.</li>
            <li>Transaction information such as purchase or payment information; and/or</li>
            <li>
              Other information such as chat records, friends’ names and contact information, and location information
              you choose to share (for example, when you connect with our Services through social features).
            </li>
          </ol>
        </div>
        <div className={classes.paragraph}>Information Collected or Generated When You Use our Services</div>
        <div className={classes.paragraph}>
          When you access or use our Services, we collect information from and about you and the device(s) you use. This
          may include the following information:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              IP address, unique device identifiers, advertising identifiers, device serial numbers, and similar
              identifiers;
            </li>
            <li>
              Device information such as hardware model, device software platform/OS and firmware, mobile carrier,
              preferred languages, device motion information, mobile network information, and in some cases, browser
              type and version, Internet service provider, referring/exit pages and URLs, or clickstream information;
            </li>
            <li>Approximate location inferred from IP address;</li>
            <li>In-game purchase information; and/or</li>
            <li>
              Game scores and achievements, and other information as reasonably required to offer and enhance our
              Services.
            </li>
            <li>
              Inferences, such as those drawn from any of the personal information we collect, to better understand your
              preferences.
            </li>
          </ol>
        </div>
        <div className={classes.paragraph}>Information Collected Using Cookies and Similar Technologies</div>
        <div className={classes.paragraph}>
          We and our partners use various tools to collect information when you visit our sites and Services, including
          cookies, pixels, software development kits, advertising identifiers, and other similar technologies. Some of
          these technologies store information in the browser or on your device. Other technologies may use
          network-related or other information to recognize your device (e.g., IP address). Our Services use these
          technologies, for example, when you first request a web page and information is then stored on your computer
          or other device so the website or app can access information when you make subsequent requests for pages from
          that Service. These technologies may also be used to collect and store information about your usage of the
          Services, such as pages you have visited, your search history, and content you have viewed.
        </div>
        <div className={classes.paragraph}>
          Depending on the Services you use, we and our partners may also use these technologies to gather information
          about how you view and use our Services and content, and to connect your activity with other information we
          store about you. Some of these partners include:
        </div>
        <div className={classes.paragraph}>
          Google Analytics. For more information about how Google uses your personal information (including for its own
          purposes, e.g., for profiling or linking it to other information), please visit Google Analytics’ Privacy
          Policy. To learn more about how to opt-out of Google Analytics’ use of your information, please click here.
        </div>
        <div className={classes.paragraph}>
          The use of these technologies helps us serve you better by understanding what you're interested in, tracking
          trends, measuring the effectiveness of ads, saving your preferences, and storing information you may want to
          retrieve on a regular basis. We also allow specific, approved partners to collect information from your
          browser or device for advertising and measurement purposes using their own similar tools.
        </div>
        <div className={classes.paragraph}>
          At any time, you can control the use of cookies by adjusting the settings on your browser. However, if you
          choose to disable some or all cookies, please note that by blocking any or all cookies, you may not have
          access to certain features, content, or personalization available through our Services. For information about
          how you can manage your cookie and similar technologies preferences, please review the section of this Privacy
          Policy titled "Interest-Based Advertising."
        </div>
        <div className={classes.paragraph}>
          <b>How We Use Information</b>
        </div>
        <div className={classes.paragraph}>
          We may use the information we collect for a number of business and commercial purposes, including to:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              Perform internal operations necessary to develop, provide, maintain, and improve our Services, including
              troubleshooting, providing customer and technical support, and conducting data analysis, testing, and
              research;
            </li>
            <li>
              Understand how you interact with our Services and personalize the product experience and content
              (including advertising and messaging campaigns), as well as offer discounts or personalized pricing in
              accordance with applicable law;
            </li>
            <li>
              Maintain the safety, security, and integrity of our Services, for example by authenticating players and
              providing account security and fraud detection;
            </li>
            <li>
              Send you news and/or marketing communications (by email, phone, or text) subject to your marketing
              preferences and choices, including information about mmside OÜ põhikiri or third-party offerings we think
              may be of interest to you;
            </li>
            <li>Administer contests, surveys, and sweepstakes you enter, notify contest winners, and award prizes;</li>
            <li>
              Fulfill a purchase or send you order confirmations or other requested product or Service information;
            </li>
            <li>
              Facilitate your communication with friends and other players, and enable features that allow you to share
              information with other people (for example, if you choose to use friend invite features); and/or
            </li>
            <li>
              Comply with legal requirements and/or investigate or address claims or disputes relating to your use of
              the Services.
            </li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          We may use the information we collect for a number of business and commercial purposes, including to:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              Perform internal operations necessary to develop, provide, maintain, and improve our Services, including
              troubleshooting, providing customer and technical support, and conducting data analysis, testing, and
              research;
            </li>
            <li>
              Understand how you interact with our Services and personalize the product experience and content
              (including advertising and messaging campaigns), as well as offer discounts or personalized pricing in
              accordance with applicable law;
            </li>
            <li>
              Maintain the safety, security, and integrity of our Services, for example by authenticating players and
              providing account security and fraud detection;
            </li>
            <li>
              Send you news and/or marketing communications (by email, phone, or text) subject to your marketing
              preferences and choices, including information about mmside OÜ põhikiri or third-party offerings we think
              may be of interest to you;
            </li>
            <li>Administer contests, surveys, and sweepstakes you enter, notify contest winners, and award prizes;</li>
            <li>
              Fulfill a purchase or send you order confirmations or other requested product or Service information;
            </li>
            <li>
              Facilitate your communication with friends and other players, and enable features that allow you to share
              information with other people (for example, if you choose to use friend invite features); and/or
            </li>
            <li>
              Comply with legal requirements and/or investigate or address claims or disputes relating to your use of
              the Services.
            </li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          <b>How We Disclose Information</b>
        </div>
        <div className={classes.paragraph}>
          At mmside OÜ põhikiri, we are in the business of creating great games, not selling information. Subject to
          your legal rights, we may disclose the information we collect about you, and may enable you to share this
          information, with a variety of third parties in various ways, including:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              With vendors, consultants, business partners, payment processors, and other third-party service providers
              as necessary to carry out work on our behalf and otherwise provide the Services;
            </li>
            <li>
              With other players, to enable you to interact with them and to share gameplay-related information such as
              high scores and other leaderboard information;
            </li>
            <li>
              With the general public, if you choose to share information when contributing content to our Services
              through a forum, blogs, social features, or the like;
            </li>
            <li>
              Where you choose to take advantage of social sharing features or related tools that let you share games
              and other actions you take on our Services, with the public, social media platforms, and/or your social
              media friends or contacts;
            </li>
            <li>With others who share your device, if you make your Account available to them through that device;</li>
            <li>With other parties engaging in sponsorships and co-branded opportunities and promotions;</li>
            <li>
              With specific third parties if we believe disclosure is required by applicable law, regulation, or legal
              process;
            </li>
            <li>
              With law enforcement officials, government authorities, or other third parties if we believe your actions
              are inconsistent with our terms or other policies, and/or are fraudulent or in violation of applicable
              law, or as we determine necessary to protect the rights, property, or safety of mmside OÜ põhikiri or
              others;
            </li>
            <li>
              In connection with an actual or proposed merger, consolidation, restructuring, sale of company stock
              and/or assets or other corporate change, including during the course of any due diligence process; and/or
            </li>
            <li>
              In an aggregated or pseudonymous form or otherwise in a form that cannot reasonably be used to identify
              you.
            </li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          In some cases, we may enable you to access third-party products or services through our Services. Please note
          that those products or services are offered by people or companies not affiliated with or controlled by us,
          and we are not responsible for their actions, their terms, their use of information, or their products or
          services. If you submit your information to a third party, that third party’s treatment of your information is
          governed by their privacy policy, which may differ from this Privacy Policy and which we encourage you to
          review.
        </div>
        <div className={classes.paragraph}>
          We also share information with your consent or at your direction. For example, we may share your personal
          information if you opt in to receiving information from mmside OÜ põhikiri’s business partners.
        </div>
        <div className={classes.paragraph}>
          <b>Interest-Based Advertising</b>
        </div>
        <div className={classes.paragraph}>
          We may share some of your information with certain third-party partners to make advertising more relevant, to
          measure its effectiveness, and to help recognize your devices and serve ads. We or they may also employ
          cookies or similar technologies, as described above, to ensure you receive appropriate advertising and
          personalized experiences. These third-party advertising partners include, but are not limited to Meta, Google,
          AppLovin, LiveRamp, Criteo, IronSource, Microsoft, and their partners. Click each partner’s name for more
          information regarding their privacy practices.
        </div>
        <div className={classes.paragraph}>
          If you prefer to opt out of having your web viewing behavior used for interest-based advertising, you can
          learn how to do so by clicking here, or if located in the European Economic Area (“EEA”), the United Kingdom,
          or Switzerland, by clicking here. If you would prefer to opt out of having information about your mobile app
          usage used for interest-based advertising, you may also do so through your device settings such as (“Allow
          Apps to Request to Track” on iOS and “Opt Out of Ads Personalization” on Android). Please note that even if
          you disable personalized advertising, you may continue to receive ads that are not based on your preferences.
        </div>
        <div className={classes.paragraph}>Links to Other Websites</div>
        <div className={classes.paragraph}>
          The Services may contain links to, or advertisements concerning, other websites. Other websites may also
          reference, advertise, or link to mmside OÜ põhikiri. mmside OÜ põhikiri is not responsible for the privacy
          practices or the content of such other websites. You should check the applicable privacy policy of any website
          you access through the Services.
        </div>
        <div className={classes.paragraph}>Your Rights and Choices</div>
        <div className={classes.paragraph}>
          You may be able to access, modify, and/or correct certain personal and other information available to you in
          your Account or in-app. Depending on where you reside, you may have additional rights, including those listed
          below in the sections called “Additional Information for Residents of the EEA, United Kingdom, Switzerland,
          and Brazil” and “Additional Information for Residents of California, Virginia, Connecticut, Utah, and
          Colorado.”
        </div>
        <div className={classes.paragraph}>How to Exercise Your Rights</div>
        <div className={classes.paragraph}>
          To protect the privacy of your information, mmside OÜ põhikiri verifies your identity and processes rights
          requests through our in-game help ticket system. If you wish to exercise any of the rights described above,
          please follow the instructions below and do not delete the game until your request is fulfilled:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>Open the mmside OÜ põhikiri game(s) for which you wish to exercise your rights;</li>
            <li>Visit the game’s settings menu;</li>
            <li>Select “Contact Us,” to submit a support ticket;</li>
            <li>Follow the prompts as provided by the Support team;</li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          If you have trouble with any of the steps described above, you can contact grubgamechoice@gmail.com for help. We
          may contact you to confirm your choices and to ask for additional information as needed to verify your
          identity and process your request.
        </div>
        <div className={classes.paragraph}>Communication Preferences</div>
        <div className={classes.paragraph}>
          Email, phone, and text: In order to opt-out of email communications, please click the “unsubscribe” link at
          the bottom of the email or contact us at grubgamechoice@gmail.com. If you receive an unwanted text message from
          us, you may opt out of receiving future text messages from us by following the instructions in the text
          message you have received from us or by otherwise contacting us as set forth below in the “Contact Us”
          section.
        </div>
        <div className={classes.paragraph}>
          Push Notifications: In connection with certain Services, you may choose to opt in to receiving push
          notifications. If you no longer want to receive them, you can turn them off at the device level.
        </div>
        <div className={classes.paragraph}>“Do Not Track” / “Global Privacy Control”</div>
        <div className={classes.paragraph}>
          Do Not Track (“DNT”) is a privacy preference that players can set in certain web browsers. Additionally, some
          browsers or plug-ins use a “Global Privacy Control” (“GPC”), which you can learn more about at
          https://globalprivacycontrol.org/. Please note that we do not respond to or honor DNT signals or similar
          mechanisms transmitted by web browsers. However, if our site detects a GPC signal from your device, we will
          interpret it as a request to opt out of selling or sharing your personal information and to opt out of
          targeted advertising under applicable state law. We will also interpret it as a request to limit the sale or
          sharing of personal information for targeted advertising for residents of the European Economic Area, the UK,
          and Brazil.
        </div>
        <div className={classes.paragraph}>
          <b>Data Retention</b>
        </div>
        <div className={classes.paragraph}>
          We may retain your personal information for as long as you have an Account with us. Following the closure of
          your Account, we may retain personal information where required by law or where we have an ongoing legitimate
          business need to do so. For example, we may retain such information to provide you a service you have
          requested, to comply with applicable legal, tax or accounting requirements, or where necessary for fraud
          prevention, dispute resolution, enforcement of our contracts and terms, or for safety reasons (e.g., we may
          retain certain information about an Account to prevent that player from opening a new Account).
        </div>
        <div className={classes.paragraph}>Your Rights and Choices</div>
        <div className={classes.paragraph}>
          You may be able to access, modify, and/or correct certain personal and other information available to you in
          your Account or in-app. Depending on where you reside, you may have additional rights, including those listed
          below in the sections called “Additional Information for Residents of the EEA, United Kingdom, Switzerland,
          and Brazil” and “Additional Information for Residents of California, Virginia, Connecticut, Utah, and
          Colorado.”
        </div>
        <div className={classes.paragraph}>How to Exercise Your Rights</div>
        <div className={classes.paragraph}>
          To protect the privacy of your information, mmside OÜ põhikiri verifies your identity and processes rights
          requests through our in-game help ticket system. If you wish to exercise any of the rights described above,
          please follow the instructions below and do not delete the game until your request is fulfilled:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>Open the Numerical Polymath;</li>
            <li>Visit the game’s settings menu;</li>
            <li>Select “Contact Us,” to submit a support ticket;</li>
            <li>Follow the prompts as provided by the Support team;</li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          If you have trouble with any of the steps described above, you can contact grubgamechoice@gmail.com for help. We
          may contact you to confirm your choices and to ask for additional information as needed to verify your
          identity and process your request.
        </div>
        <div className={classes.paragraph}>Communication Preferences</div>
        <div className={classes.paragraph}>
          Email, phone, and text: In order to opt-out of email communications, please click the “unsubscribe” link at
          the bottom of the email or contact us at grubgamechoice@gmail.com. If you receive an unwanted text message from
          us, you may opt out of receiving future text messages from us by following the instructions in the text
          message you have received from us or by otherwise contacting us as set forth below in the “Contact Us”
          section.
        </div>
        <div className={classes.paragraph}>
          Push Notifications: In connection with certain Services, you may choose to opt in to receiving push
          notifications. If you no longer want to receive them, you can turn them off at the device level.
        </div>
        <div className={classes.paragraph}>“Do Not Track” / “Global Privacy Control”</div>
        <div className={classes.paragraph}>
          Do Not Track (“DNT”) is a privacy preference that players can set in certain web browsers. Additionally, some
          browsers or plug-ins use a “Global Privacy Control” (“GPC”), which you can learn more about at
          https://globalprivacycontrol.org/. Please note that we do not respond to or honor DNT signals or similar
          mechanisms transmitted by web browsers. However, if our site detects a GPC signal from your device, we will
          interpret it as a request to opt out of selling or sharing your personal information and to opt out of
          targeted advertising under applicable state law. We will also interpret it as a request to limit the sale or
          sharing of personal information for targeted advertising for residents of the European Economic Area, the UK,
          and Brazil.
        </div>
        <div className={classes.paragraph}>Security</div>
        <div className={classes.paragraph}>
          We follow generally accepted industry standards and maintain reasonable safeguards designed to secure the
          information in our possession, and take steps to ensure only those at mmside OÜ põhikiri with a legitimate
          business need to access your personal information are permitted to do so. We also take reasonable steps to
          assure that third parties to whom we transfer information will provide protections appropriate to that type of
          information. If you have reason to believe that your personal information is no longer secure, please
          immediately contact us as set forth below in the “Contact Us” section.
        </div>
        <div className={classes.paragraph}>Use By Minors</div>
        <div className={classes.paragraph}>
          Our Services are designed for a general audience and require all players to be above the minimum age to
          consent for data processing (for example, 13 years old in the United States, and 16 years old in some parts of
          the EU) as a condition of access. An exception to this is our game Numerical Polymath, which allows younger
          players to play. As such, we take additional steps to limit access to certain features of STUMBLE GUYS based
          on players’ ages, and also limit the personal information collection and sharing of such younger players’
          information to only what is necessary to support our internal operations.
        </div>
        <div className={classes.paragraph}>
          If you are a parent or guardian of a minor, and believe that minor has uploaded personal information in
          connection with our Services without your consent, or if you wish to review information collected from your
          minor child, or have that information modified or deleted, you may contact us as described in “Contact Us”
          below. If we become aware that a minor has provided us with personal information in violation of applicable
          law, we will delete any personal information we have collected, unless we have a legal obligation to keep it,
          and revert them to the underage experience, if applicable.
        </div>
        <div className={classes.paragraph}>
          <b>Additional Information for Residents of the EEA, United Kingdom, Switzerland, and Brazil</b>
        </div>
        <div className={classes.paragraph}>
          If you are a resident of a member country of the EEA, the United Kingdom, Switzerland, or Brazil, then in
          accordance with applicable law, you may have the following rights:{' '}
        </div>
        <div className={classes.paragraph}>
          <b>Access:</b> to obtain confirmation regarding whether we process your personal information, and to and
          obtain a copy of the personal information we have collected about you. (Note that this right only applies to
          personal information relating to you, and not to any other player or person.) Rectification or Correction: to
          correct your personal information where it is inaccurate, incomplete, or outdated.
        </div>
        <div className={classes.paragraph}>
          <b>Erasure (deletion):</b> to request that mmside OÜ põhikiri delete your personal information, for example
          when it is no longer necessary to fulfill the purposes for which mmside OÜ põhikiri collected it. This right
          may be limited to the extent that mmside OÜ põhikiri is permitted or required by applicable law to retain your
          information. (Note that if you request deletion of your personal information, you may no longer be able to use
          or access the Services. If you share devices with someone else who uses the Services, that person may also
          lose game progress or related Account information and access. If you decide to use or access the Services
          again, mmside OÜ põhikiri may consider this a new Account and may collect personal information associated with
          that Account in accordance with this Privacy Policy.)
        </div>
        <div className={classes.paragraph}>
          <b>Restriction or objection to processing:</b> to object to mmside OÜ põhikiri’s processing of your personal
          information, or request that mmside OÜ põhikiri restrict that processing. Because the information that mmside
          OÜ põhikiri collects is generally required to provide the Services to you, if you object to the processing of
          your personal information, this may have the same effect as requesting deletion of this information. This
          right may be limited to the extent that mmside OÜ põhikiri is permitted or required by applicable law to
          continue processing the information. (Note that if you object to the processing of your personal information,
          you and others who use your devices to access the Services may no longer be able to use or access the
          Services.){' '}
        </div>
        <div className={classes.paragraph}>
          <b>Portability:</b> to receive a copy of your personal information in a structured, commonly used, and
          machine-readable format so that you can transmit it elsewhere.
        </div>
        <div className={classes.paragraph}>
          <b>Request to be informed:</b> to request information about third parties with which your personal information
          has been shared.
        </div>
        <div className={classes.paragraph}>
          Request review: to review decisions taken exclusively based on automated processing if these decisions could
          affect your data subject rights.
        </div>
        <div className={classes.paragraph}>To exercise these rights, please see “Your Rights and Choices” above.</div>
        <div className={classes.paragraph}>
          In addition to the rights described above, if we have collected and processed certain types of personal
          information with your consent, you may withdraw that consent at any time in the manner described when you
          provided consent. Withdrawing your consent will not affect the lawfulness of any processing conducted prior to
          then, nor will it affect any processing based on lawful grounds other than consent.
        </div>
        <div className={classes.paragraph}>
          You may have the right to file a complaint with a data protection authority about our collection and use of
          your personal information. For more information, contact your local data protection authority. We will
          cooperate with the appropriate governmental authorities to resolve any privacy-related complaints that cannot
          be amicably resolved between you and us.
        </div>
        <div className={classes.paragraph}>Legal Basis for Processing Personal Information</div>
        <div className={classes.paragraph}>
          If you are from the EEA, the United Kingdom, or Switzerland, our legal basis for collecting and using your
          personal information will depend on the type of information and the context in which we collect it. In
          general, we collect personal information from you only:{' '}
        </div>
        <div className={classes.paragraph}>
          Where we have your consent to do so, such as before we use or share your information for advertising that is
          targeted based on your browsing history, online behavior, or other personal information that we or third
          parties may have collected about you. If you consent to our use of your personal information for any purpose,
          you have the right to withdraw consent at any time by contacting us, as explained above;
        </div>
        <div className={classes.paragraph}>Where we need it to perform a contract with you;</div>
        <div className={classes.paragraph}>
          Where it is in our legitimate interests and not overridden by your data protection interests or fundamental
          rights and freedoms. We have a legitimate interest in processing your personal information, for example, for
          the following purposes:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>Product and Service development, management, delivery, and enhancement;</li>
            <li>Fraud detection and prevention;</li>
            <li>Network and system security;</li>
            <li>General corporate operations;</li>
            <li>Compliance with applicable foreign laws;</li>
            <li>Communications regarding your purchases; and</li>
            <li>Non-targeted marketing and advertising.</li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          Where we have a legal obligation to collect personal information from you or may otherwise need it to protect
          your vital interests or those of another person.
        </div>
        <div className={classes.paragraph}>
          If you have questions about the legal basis on which we collect and use your personal information, please
          contact us at grubgamechoice@gmail.com.
        </div>
        <div className={classes.paragraph}>International data transfer</div>
        <div className={classes.paragraph}>
          Your personal information might be transferred to a country outside the EEA, the United Kingdom or
          Switzerland, including, but not limited to, the United States or other countries, which may have data
          protection laws that are different from the laws where you live. We endeavor to safeguard your information
          consistent with the requirements of applicable laws.
        </div>
        <div className={classes.paragraph}>
          If we transfer personal information which originates in the EEA, Switzerland, and/or the United Kingdom to a
          country that has not been found to provide an adequate level of protection under applicable data protection
          laws, one of the safeguards we may use to support such transfer is the EU Standard Contractual Clauses or
          similar contractual safeguards, and, if necessary, the implementation of further technical and organizational
          measures.
        </div>
        <div className={classes.paragraph}>
          <b>Additional Information for Residents of California, Virginia, Connecticut, Utah, and Colorado</b>
        </div>
        <div className={classes.paragraph}>Information We Collect, Use, and Disclose</div>
        <div className={classes.paragraph}>
          All sources of personal information collected are noted in the “Information We May Collect and How We Collect
          It” section above and retained pursuant to “Data Retention” above. The purposes for our collection of this
          information are described in “How We Use Information” above.
        </div>
        <div className={classes.paragraph}>
          In the past 12 months, we have disclosed (or have enabled you to disclose) the following categories of
          personal information to third parties for business or commercial purposes:
        </div>
        <div className={classes.paragraph}>
          <table style={{ border: '1px solid' }}>
            <tr>
              <td>CATEGORY</td>
              <td>CATEGORIES OF PARTIES TO WHOM INFORMATION IS DISCLOSED</td>
              <td>CATEGORIES OF THIRD PARTIES TO WHOM INFORMATION IS SOLD AND/OR SHARED</td>
            </tr>
            <tr>
              <td>
                Identifiers and contact information, including first and last name, email address, phone number, home
                address or shipping address, and your friends’ names and contact information.
              </td>
              <td>
                Vendors, such as cloud service providers (such as our email service providers and cloud-based survey and
                customer service tools we use) and payment processors. Affiliates for purposes of business operations
                and support.
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>
                Identifiers such as username or handle, third party service ID, IP address, unique device identifier,
                advertising identifier, device serial number.
              </td>
              <td>
                Vendors, such as cloud service providers (such as our email service providers and cloud-based survey and
                customer service tools we use) and payment processors.Affiliates for purposes of business operations and
                support.Other players in multiplayer games (username only).
              </td>
              <td>Analytics providers and advertising networks.</td>
            </tr>
            <tr>
              <td>
                Internet or other electronic network activity information, such as hardware model, device software
                platform/OS and firmware, mobile carrier, preferred languages, device motion information, and mobile
                network information, and in some cases, browser type, Internet service provider, referring/exit pages
                and URLs, and clickstream information.
              </td>
              <td>
                Vendors, such as cloud service providers (such as our email service providers and cloud-based survey and
                customer service tools we use).Affiliates for purposes of business operations and support.
              </td>
              <td>Analytics providers and advertising networks.</td>
            </tr>
            <tr>
              <td>Commercial information, such as information regarding in-game purchase payment method.</td>
              <td>
                Vendors, such as cloud service providers (including cloud-based customer service and survey tools we
                use), payment processors, and business partners.Affiliates for purposes of business operations and
                support.
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>
                Commercial information, such as information regarding in-game purchase events and value of purchases.
              </td>
              <td>
                Vendors, such as cloud service providers (including cloud-based customer service and survey tools we
                use), payment processors, and business partners.Affiliates for purposes of business operations and
                support.
              </td>
              <td>Advertising networks.</td>
            </tr>
            <tr>
              <td>
                Demographic information, such as your gender, age, date of birth, and language.Visual information, such
                as your profile picture and the profile pictures of your friends or contacts.
              </td>
              <td>
                Vendors, such as cloud service providers (including cloud-based customer service and survey tools we
                use).Affiliates for purposes of business operations and support.Service providers that enable image
                functionality on our behalf.Affiliates for purposes of business operations and support. Other players,
                depending on your sharing preferences.
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>
                Visual information, such as your profile picture and the profile pictures of your friends or contacts.
              </td>
              <td>
                Service providers that enable image functionality on our behalf.Affiliates for purposes of business
                operations and support. Other players, depending on your sharing preferences.
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>Non-precise geolocation information.</td>
              <td>
                Vendors, such as cloud service providers (such as our email service providers and cloud-based survey and
                customer service tools we use) and payment processors.Affiliates for purposes of business operations and
                support.
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>
                Other information you may share if you use social features when accessing our Services, including the
                organizations, links, and other information included on your social profile page.
              </td>
              <td>
                Service providers, and other providers or partners that support our operational business
                purposes.Affiliates for purposes of business operations and support. Other players, depending on your
                sharing preferences.
              </td>
              <td>N/A</td>
            </tr>
            <tr>
              <td>
                Inferences derived from personal information listed above in this chart, such as your characteristics
                and preferences.
              </td>
              <td>
                Vendors, such as cloud service providers (such as our email service providers and cloud-based survey and
                customer service tools we use) and payment processors.Affiliates for purposes of business operations and
                support.
              </td>
              <td>Analytics providers and advertising networks.</td>
            </tr>
          </table>
        </div>
        <div className={classes.paragraph}>
          Note that we do not have any knowledge of sales or sharing of personal information of minors (e.g., for the
          purpose of this section, under 16 years of age in California). While our Services may allow you to create an
          account, we do not otherwise use or disclose any sensitive personal information.
        </div>
        <div className={classes.paragraph}>
          <b>Your Privacy Rights Under Applicable State Law</b>
        </div>
        <div className={classes.paragraph}>
          Subject to applicable law, if you are a resident of California, Virginia, Connecticut, Utah, or Colorado, you
          may have the following rights:
        </div>
        <div className={classes.paragraph}>
          <ol>
            <li>
              <b>To Know and Access:</b> to obtain a copy of the specific pieces of personal information we have
              collected about you. (Note that this right only applies to personal information relating to you, and not
              to any other player.)
            </li>
            <li>
              <b>Deletion:</b> to request that mmside OÜ põhikiri delete your personal information. This right may be
              limited to the extent that mmside OÜ põhikiri is permitted or required by applicable law to retain
              information. (Note that if you request deletion of your personal information, you may no longer be able to
              use or access the Services, and that if you share devices with someone else who uses the Services, that
              person may lose game progress or related Account information and access. If you decide to use or access
              the Services again, Scopely may consider this a new Account, and may collect personal information
              associated with that Account in accordance with this Privacy Policy.)
            </li>
            <li>
              <b>Opt-Out of "Sale" and Certain Sharing Practices:</b> depending on the site you are visiting or the app
              you are visiting and your relationship with us, you have the right to opt-out of certain information
              sharing practices with Third Parties who do not act as our Service Providers. In some states, like
              California, this information sharing may qualify as a “share” or a “sale,” while in other states, like
              Virginia, this information sharing may qualify as “targeted advertising” (collectively, “personalized
              advertising”). If you wish to opt-out of personalized advertising associated with our games, please by
              contacting us as set forth below.
            </li>
            <li>
              <b>Correction:</b> to request the correction of inaccurate personal information that we may have on file
              about you.
            </li>
            <li>
              <b>Obtain additional details regarding mmside OÜ põhikiri’s information practices:</b> You may have the
              right to request disclosures regarding mmside OÜ põhikiri’s information practices. (Note that this
              information is generally available in this Privacy Policy).
            </li>
          </ol>
        </div>
        <div className={classes.paragraph}>
          If you wish to exercise any of the rights described above, please follow the instructions in “Your Rights and
          Choices” above. We will not discriminate against you, in terms of price or services that we offer, if you
          submit any of the requests listed above.
        </div>
        <div className={classes.paragraph}>
          Verification and Appeal Process: We are required by law to take reasonable steps to verify your identity prior
          to responding to your request. Please note that your exercise of these rights is subject to certain exemptions
          to safeguard the public interest (e.g., the prevention or detection of crime) and our interests (e.g., the
          maintenance of legal privilege). Please do not delete our game until your request is fulfilled. If we are
          unable to process your request via our in-game help ticket system, we may verify your request by asking for
          information sufficient to confirm your identity, such as your name, email address, and information about your
          interactions with us or with one of our games. Requests to exercise these rights may be granted in whole, in
          part, or not at all, depending on the scope and nature of the request and as permitted by applicable law.
          Where required by applicable law, we will notify you if we reject your request, and notify you of the reasons
          we are unable to honor your request.
        </div>
        <div className={classes.paragraph}>
          If you are a resident of Colorado, Virginia, or Connecticut, you have the right to appeal a request we deny
          when we have verified your identity and still decline to honor your request. The process for that appeal will
          be sent to you separately if your request is denied.
        </div>
        <div className={classes.paragraph}>
          Authorized Agent: Californian players may use an authorized agent on their behalf to exercise a privacy right
          discussed above. If you are an authorized agent acting on behalf of a Californian player to communicate with
          us or to exercise a privacy right discussed above, you must be able to demonstrate that you have the requisite
          authorization to act on behalf of the player, and have sufficient access to that player’s laptop, desktop, or
          mobile device to exercise that player’s right digitally. If you are an authorized agent trying to exercise
          rights on behalf of a Numerical Polymath player, then you can make a request on the player’s behalf by
          contacting us as set forth below in the “Contact Us” section with supporting verification information with
          proof that you have access to the player’s interface (including, for certain requests, written permission from
          the player) and proof of your own identity.
        </div>
        <div className={classes.paragraph}>
          Refer-a-Friend, Promotions, and Similar Incentive Programs: We may offer referral programs, promotions such as
          sweepstakes, or other incentivized information collection programs. We may offer incentives to you such as
          discounts or promotional items or credit in connection with these programs, wherein you provide personal
          information regarding your friends or colleagues (such as their email address) and receive rewards when they
          sign up to use our Services. (The referred party may also receive rewards for signing up via your referral.)
          Alternatively, we may ask you to provide your personal information in exchange for a reward or participation
          in a promotion. These programs are entirely voluntary and allow us to grow our business and provide additional
          benefits to you. The value of your information to us depends on how you ultimately use our Services, whereas
          the value of the referred party’s information to us depends on whether the referred party ultimately uses our
          Services. Said value will be reflected in the incentive offered in connection with each program. You may
          withdraw from the incentives at any time. In order to withdraw from the financial incentive, please contact us
          at grubgamechoice@gmail.com.
        </div>
        <div className={classes.paragraph}>
          Shine the Light Disclosure: California residents are entitled to ask us for a notice that identifies the
          categories of personal customer information that we share with our affiliates and/or third parties for
          marketing purposes, and provides contact information for such affiliates and/or third parties. If you are a
          California resident and would like this information, please submit a written request to us using the contact
          information below in the “Contact Us” section.
        </div>
        <div className={classes.paragraph}>
          <b>Contact Us</b>
        </div>
        <div className={classes.paragraph}>
          If you have questions or concerns about this Policy or our information practices, you can contact us by email
          at grubgamechoice@gmail.com, or by postal mail at: mmside OÜ põhikiri, Tallinn, Eesti Vabariik ja aadress on Jõe
          tn 4c, Tallinn, 10151. If we receive a formal written complaint from you, we will attempt to contact you
          directly to resolve your concerns.
        </div>
        <div className={classes.breakline}></div>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
