import { ReactComponent as DefaultLogo } from '@/assets/logo/default.svg';
import { ReactComponent as SmallLogo } from '@/assets/logo/small.svg';
import { ReactComponent as VerticalLogo } from '@/assets/logo/vertical.svg';
import React from 'react';

type WdLogoProps = {
  vertical?: boolean;
  small?: boolean;
};
const WdLogo = ({ vertical = false, small = false }: WdLogoProps) => {
  if (vertical) return <VerticalLogo style={{ width: '200px', height: '100px', }} />;
  if (small) return <SmallLogo style={{ width: '50px', height: '100px', }} />;
  return <DefaultLogo style={{ width: '200px', height: '100px', }} />;
};

export default WdLogo;
