import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import CreditsService from '@/services/credits.service';
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogProps,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  Zoom,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BaseButton from '../base/button.component';
import { CloseIconStyled } from './sign-up-dialog.component';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '35px 45px',

    [theme.breakpoints.down('sm')]: {
      padding: '40px 12px',
    },
  },
  dialogPaper: {
    overflowY: 'visible',
  },
  dialogTitle: {
    textAlign: 'center',
    height: 48,
  },

  buyButton: {
    height: 42,
    width: 140,
  },

  link: {
    color: '#0AFB4C',
  },

  info: {
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  },

  check: {
    marginLeft: 15,
  },

  site: {
    marginTop: 15,
    marginBottom: 10,
    textAlign: 'center',
  },

  city: {
    fontSize: '12px',
    color: 'grey',
    textAlign: 'center',
  },

  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  choosePaymentProviderContainer: {
    marginTop: 15,
  },
}));

type DialogWriteMailProps = {
  open: boolean;
  closeHandler: () => void;
  chosenCreditpack: any;
} & DialogProps;

const DialogBuyCredits = ({ open, closeHandler, chosenCreditpack }: DialogWriteMailProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    companyAddress,
    siteUrl,
    paymentsFlexpay,
    paymentsStripe,
    paymentsCcbill,
    paymentsInoviopay,
    paymentsInoviopay3ds2,
    paymentsNetvalve,
  } = useAppSelector<ISettings>((state) => state.users.settings);


  const [isAgreed, setIsAgreed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentLink, setPaymentLink] = useState(null);

  const [chosenProvider, setChosenProvider] = useState(() => {
    if (paymentsInoviopay3ds2) {
      return 'inoviopay3ds2';
    } else if (paymentsFlexpay) {
      return 'flexpay';
    } else if (paymentsStripe) {
      return 'stripe';
    } else if (paymentsCcbill) {
      return 'ccbill';
    } else if (paymentsInoviopay) {
      return 'inoviopay';
    } else if (paymentsNetvalve) {
      return 'netvalve';
    } else {
      return '';
    }
  });

  const closeWithResetHandler = useCallback(() => {
    setIsAgreed(false);
    setIsLoading(false);
    closeHandler();
  }, [closeHandler]);

  const openLinkHandler = useCallback(() => {
    if (paymentLink) {
      window.open(paymentLink, '_blank');
      closeWithResetHandler();
    }
  }, [closeWithResetHandler, paymentLink]);

  const handleProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenProvider(event.target.value);
  };

  useEffect(() => {
    const fetchPaymentLink = async () => {
      setIsLoading(true);

      try {
        const res = await CreditsService.getPaymentLink(chosenCreditpack.id, chosenProvider);

        setPaymentLink(res.url);
      } catch (e) {
        closeWithResetHandler();
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }

      setIsLoading(false);
    };

    if (chosenCreditpack?.id && chosenProvider) {
      fetchPaymentLink();
    }
  }, [chosenCreditpack?.id, chosenProvider, closeWithResetHandler, dispatch]);

  return (
    <>
      <Dialog
        classes={{
          root: classes.dialogPaper,
        }}
        TransitionComponent={Zoom}
        open={open}
        onClose={closeWithResetHandler}
      >
        <div className={classes.container}>
          <div className={classes.choosePaymentProviderContainer}>
            <RadioGroup
              aria-label='paymentProvider'
              name='paymentProvider'
              value={chosenProvider}
              onChange={handleProviderChange}
            >
              <>
                {paymentsInoviopay3ds2 && (
                  <FormControlLabel value='inoviopay3ds2' control={<Radio />} label='Inoviopay with 3ds' />
                )}
                {paymentsFlexpay && <FormControlLabel value='flexpay' control={<Radio />} label='Flexpay' />}
                {paymentsStripe && <FormControlLabel value='stripe' control={<Radio />} label='Stripe' />}
                {paymentsCcbill && <FormControlLabel value='ccbill' control={<Radio />} label='CCBill' />}
                {paymentsInoviopay && <FormControlLabel value='inoviopay' control={<Radio />} label='Inoviopay' />}
                {paymentsNetvalve && <FormControlLabel value='netvalve' control={<Radio />} label='Netvalve' />}
              </>
            </RadioGroup>
          </div>
          <div className={classes.dialogTitle}>
            <Typography variant='h2' component='h2' gutterBottom>
              To buy {chosenCreditpack?.credits} credits for {chosenCreditpack?.price}€
            </Typography>
          </div>
          {!paymentsFlexpay &&
            !paymentsStripe &&
            !paymentsCcbill &&
            !paymentsInoviopay &&
            !paymentsInoviopay3ds2 &&
            !paymentsNetvalve && (
              <>
                <div>
                  Due to issues with our payment system, we are temporarily unable to accept payments.
                </div>
              </>
            )}

          {isLoading ? (
            <div className={classes.loader}>
              <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
            </div>
          ) : (
            <div>
              <div className={classes.info}>
                <BaseButton
                  text={'Buy'}
                  color='primary'
                  className={classes.buyButton}
                  onClick={openLinkHandler}
                  disabled={!isAgreed}
                />
                <div className={classes.check}>
                  <Checkbox
                    checked={isAgreed}
                    value={isAgreed}
                    name='isAgreed'
                    onChange={() => setIsAgreed(!isAgreed)}
                    color='primary'
                  />
                  I agree with{' '}
                  <Link to={'/terms-of-service'} className={classes.link}>
                    terms of use
                  </Link>
                  {', '}
                  <Link to={'/privacy-policy'} className={classes.link}>
                    refund policy
                  </Link>{' '}
                  and{' '}
                  <Link to={'/refund-policy'} className={classes.link}>
                    refund policy
                  </Link>
                </div>
              </div>

              <Divider style={{ height: 1 }} sx={{ width: '100%' }} />
              <div className={classes.site}>
                <Link to={'/'} className={classes.link}>
                  {siteUrl}
                </Link>
              </div>
              <div className={classes.city}>{companyAddress}</div>
            </div>
          )}
        </div>
        <CloseIconStyled onClick={closeWithResetHandler} />
      </Dialog>
    </>
  );
};

export default DialogBuyCredits;
