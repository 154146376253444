import React, { useCallback } from 'react';
import { Modal, Box, Typography, Button, TextField } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import Socket from '@/services/Socket';
import { EventNamesEnum, EventTypeEnum } from '@/utils/ws.enum';
import { useAppSelector } from '@/app/hooks';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SendInviteModalModal = ({ open, handleClose, userId }) => {

const currentUserId = useAppSelector((state) => state.users.currentUser?.id);

  const sendInvite = useCallback(async (credits: number) => {
    console.log('send invites', userId);

    if (Socket && Socket.socket) {
        Socket.emit(EventNamesEnum.dataSend, {
          toUserId: userId,
          data: {
            type: EventTypeEnum.invite,
            data: {
                opponentUserId: currentUserId,
                userId,
                credits,
            }
          }
        });
      }
  }, [userId, currentUserId]);
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
      <Box sx={style}>
        <Typography id='modal-title' variant='h6' component='h2'>
          Input number credits, to invite player to play game
        </Typography>
        <Formik
          initialValues={{ number: '' }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              console.log(JSON.stringify(values, null, 2));
              sendInvite(Number(values.number));
              setSubmitting(false);
              handleClose();
            }, 400);
          }}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form>
              <Field
                as={TextField}
                name='number'
                type='number'
                label='Credits'
                fullWidth
                margin='normal'
                error={touched.number && !!errors.number}
                helperText={touched.number && errors.number}
              />
              <Button type='submit' fullWidth variant='contained' color='primary' disabled={isSubmitting}>
                send
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
};

export default SendInviteModalModal;
