import { UserDTO } from "@/app/users/users.reducer";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";
import DrawerLink from "./drawer-link.component";

type DrawerNavProps = {
  user: UserDTO;
  onToggleMenu?: (event: any) => void;
  mobile?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    padding: "36px 0",
  },
}));

const DrawerNav = ({
  user,
  onToggleMenu = () => { },
  mobile = false,
}: DrawerNavProps) => {

  const classes = useStyles();

  const navItems = user?.id ? [
    {
      route: "/user/search",
      label: "Users",
      iconName: "men",
      counter: false,
    },
    {
      route: "/user/contacts",
      label: "Contacts",
      iconName: "contacts",
      counter: false,
      count: 0,
      unreadCount: 0,
    },
    {
      route: "/user/notifications",
      label: "Notifications",
      iconName: "notifications",
      counter: false,
    },
    {
      route: "/user/order-history",
      label: "Order history",
      iconName: "profile",
      counter: false,
    },
  ] : [];

  return (
    <div
      className={classes.container}
      style={mobile ? { paddingBottom: 23, paddingTop: 27 } : {}}
    >
      {navItems.map(({ route, iconName, label, counter, count, unreadCount }) => {

        return (
          <DrawerLink
            onToggleMenu={onToggleMenu}
            route={route}
            label={label}
            iconName={iconName}
            key={iconName}
            user={user}
            counter={counter}
            count={`${count}` || null}
            unreadCount={unreadCount}
          />
        )
      })}
    </div>
  );
};

export default DrawerNav;
