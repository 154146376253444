import { ReactComponent as FacebookIcon } from "@/assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "@/assets/icons/instagram.svg";
import { ReactComponent as PinterestIcon } from "@/assets/icons/pinterest.svg";
import { ReactComponent as TwitterIcon } from "@/assets/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "@/assets/icons/youtube.svg";
import lpbImage from "@/assets/images/logos/lpb.svg";
import mastercardImage from "@/assets/images/logos/mastercard.svg";
import visaImage from "@/assets/images/logos/visa.svg";
import { MenuItemTypes } from "@/types/menu-item.types";

export const footerMenu1: MenuItemTypes[] = [
  {
    path: "/about",
    name: "About",
    icon: null,
  },
  {
    path: "/faq",
    name: "F.A.Q.",
    icon: null,
  },
  {
    path: "/contacts",
    name: "Contact Us",
    icon: null,
  },
];

export const footerMenu2: MenuItemTypes[] = [
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
  },
  // {
  //   path: "/anti-scam-policy",
  //   name: "Anti-Scam Policy",
  // },
  // {
  //   path: "/refund-policy",
  //   name: "Refund Policy",
  // },
  {
    path: "/terms-of-service",
    name: "Terms of service",
  },
  // {
  //   path: "/cookie-policy",
  //   name: "Cookie Policy",
  // },
  // {
  //   path: "/complaint-policy",
  //   name: "Complaint Policy",
  // },
  // {
  //   path: "/cancellation-policy",
  //   name: "Cancellation Policy"
  // }
];

export const footerListSocial: MenuItemTypes[] = [
  {
    path: "https://www.facebook.com/",
    name: "Facebook",
    icon: FacebookIcon,
  },
  {
    path: "https://twitter.com/boardgames",
    name: "Twitter",
    icon: TwitterIcon,
  },
  {
    path: "https://www.youtube.com/",
    name: "Youtube",
    icon: YoutubeIcon,
  },
  {
    path: "https://www.instagram.com/boardgames/",
    name: "Instagram",
    icon: InstagramIcon,
  },
];

export const footerListServices: MenuItemTypes[] = [
  // {
  //   path: "/",
  //   name: "Segpay",
  //   imageUrl: segpayImage,
  // },
  {
    path: "/",
    name: "Visa",
    imageUrl: visaImage,
  },
  {
    path: "/",
    name: "Mastercard",
    imageUrl: mastercardImage,
  },
  // {
  //   path: "/",
  //   name: "LPB",
  //   imageUrl: lpbImage,
  // },
];
