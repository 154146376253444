import React, { useEffect, useState } from 'react';
import { Typography, Avatar } from '@mui/material';

import SwipeableViews from 'react-swipeable-views';
import getImageByAvatar from '@/hooks/getImageByAvatar';
const reviews = [
  {
    author: 'Isabella Ingley',
    rating: 4,
    comment: 'Fun twist of the board game',
    avatar: 'woman1',
  },
  {
    author: 'Dillon Buchak',
    rating: 5,
    comment: 'What a creative game love this',
    avatar: 'man1',
  },
  {
    author: 'Tracey Parker',
    rating: 4,
    comment: 'Simle, but nice and relaxing. Love it.',
    avatar: 'woman2',
  },
];

export const ReviewBlock = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (activeIndex + 1) % reviews.length;
      setActiveIndex(nextIndex);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [activeIndex, reviews.length]);
  return (
    <div style={{ marginBottom: 100 }}>
      <Typography align='center' marginBottom={10} variant='h4'>
        Reviews
      </Typography>
      <SwipeableViews index={activeIndex} onChangeIndex={setActiveIndex}>
        {reviews.map((review, index) => (
          <div
            key={index}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}
          >
            <Avatar sx={{ width: 64, height: 64, marginBottom: 2 }} alt={review.author} src={getImageByAvatar(review.avatar)} />
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
              {review.author}
            </Typography>
            <Typography variant='body2'>{review.comment}</Typography>
          </div>
        ))}
      </SwipeableViews>
    </div>
  );
};
