import { useAppBreakpoints, useContainerWidth } from '@/hooks';
import { Badge, CircularProgress, Container, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import BaseCard from '@/components/base/base-card.component';
import { toggleSnackbarOpen } from '@/app/ui/ui.actions';
import { useAppDispatch } from '@/app/hooks';
import ContactCard from '@/pages/contacts/components/contact-card.component';
import MobileContactCard from '@/pages/contacts/components/mobile-contact-card.component';
import { useBinarySwitcher } from '@/hooks/useBinarySwitcher';
import useBlankAvatar from '@/hooks/useBlankAvatar';
import BookmarksService from '@/services/bookmarks.service';

const useStyles = makeStyles((theme: Theme) => ({
  cardOverrides: {
    paddingBottom: 0,
  },
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down('xl')]: {
      marginTop: 24,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 14,
    },
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    marginBottom: 27,
    [theme.breakpoints.down('md')]: {
      marginBottom: 32,
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
  noNotifications: {
    margin: '0 auto',
    border: '2px dashed #004FC6',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  loader: {
    margin: '0 auto',
    padding: '10px',
    width: '200px',
    display: 'flex',
    justifyContent: 'center',
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, 170px)',
    gridGap: '20px',
  },
  label: {
    display: 'inline-block',
  },
  badge: {
    '& > .MuiBadge-standard': {
      top: 'calc(100% - 13px)',
      right: '-22px',

      [theme.breakpoints.down('md')]: {
        top: 'calc(100% - 11px)',
      },
    },
  },
  metaCtaIcon: {
    maxWidth: 32,
    maxHeight: 32,
    minHeight: '32px !important',
    minWidth: '0 !important',

    '& > .MuiButton-startIcon': {
      margin: 0,
    },
  },
}));

const Contacts = () => {
  const classes = useStyles();
  const width = useContainerWidth();
  const { xs, mdAndDown } = useAppBreakpoints();
  const dispatch = useAppDispatch();

  const stylesOverrides = useMemo(
    () => ({
      contentWrapper: {
        padding: 0,
        maxWidth: 968,
      },
      subHeaderButton: {
        width: '100%',
        height: xs ? 24 : 36,
        minHeight: xs ? 24 : 36,
      },
    }),
    [xs]
  );

  const [contacts, setContacts] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const windowWidth = useMemo(() => window.innerWidth, []);
  const windowHeight = useMemo(() => window.innerHeight, []);
  const [isWriteDialogOpen, openDialog, hideDialog] = useBinarySwitcher();
  const [isGiftDialogOpen, openGiftDialog, hideGiftDialog] = useBinarySwitcher();

  const [currentDialogUser, setCurrentDialogUser] = useState(null);

  const openDialoghandler = useCallback(
    ({ id, name, avatar }: { id: string; name: string; avatar: string }) => {
      setCurrentDialogUser({
        id,
        name,
        avatar,
      });

      openDialog();
    },
    [openDialog]
  );

  const openGiftDialoghandler = useCallback(
    ({ id, name, avatar }: { id: string; name: string; avatar: string }) => {
      setCurrentDialogUser({
        id,
        name,
        avatar,
      });

      openGiftDialog();
    },
    [openGiftDialog]
  );

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await BookmarksService.getBookmarks();

        setContacts(response);
      } catch {
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }

      setIsLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const deleteHandler = useCallback(
    async (id: string) => {
      try {
        await BookmarksService.deleteContact(id);

        setContacts(contacts.filter((contact) => contact.id !== id));

        dispatch(toggleSnackbarOpen('Success!', 'success'));
      } catch {
        dispatch(toggleSnackbarOpen('Something goes wrong during request execution', 'error'));
      }
    },
    [contacts, dispatch]
  );

  const contentBody = useMemo(() => {
    if (isLoading)
      return (
        <div className={classes.loader}>
          <CircularProgress color='secondary' style={{ margin: '0 auto' }} />
        </div>
      );
    if (!contacts?.length) return <div className={classes.noNotifications}>No contacts</div>;

    if (mdAndDown) {
      return (
        <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
          <div className={classes.cardWrapper}>
            {contacts.map((item) => (
              <MobileContactCard
                key={item.id}
                user={item}
                windowWidth={windowWidth}
                windowHeight={windowHeight}
                deleteHandler={deleteHandler}
              />
            ))}
          </div>
        </BaseCard>
      );
    }

    return (
      <div className={classes.cardContainer}>
        {contacts.map((item) => (
          <ContactCard
            user={item}
            key={item.id}
            windowWidth={windowWidth}
            windowHeight={windowHeight}
            deleteHandler={deleteHandler}
          />
        ))}
      </div>
    );
  }, [classes.cardContainer, classes.cardOverrides, classes.cardWrapper, classes.loader, classes.noNotifications, contacts, deleteHandler, isLoading, mdAndDown, windowHeight, windowWidth]);

  return (
    <>
      <Container maxWidth={width} className={classes.contentWrapper} style={stylesOverrides.contentWrapper}>
        <BaseCard wrapperOverrideStyle={{ margin: 0, marginBottom: 10 }} cardOverrideStyle={classes.cardOverrides}>
          <div className={classes.cardWrapper}>
            <header className={classes.header}>
              <Badge badgeContent={contacts?.length} color='primary' className={classes.badge}>
                {/* @ts-ignore */}
                <Typography variant='h2'>Contacts</Typography>
              </Badge>
            </header>
          </div>
        </BaseCard>

        <div>{contentBody}</div>
      </Container>
    </>
  );
};

export default Contacts;
