import React from 'react';
import { Container } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    paddingBottom: '33px',
    width: '100%',
    textAlign: 'center',
  },
  paragraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    paddingBottom: '10px',
    paddingLeft: '40px',
  },
  headline: {
    fontWeight: '600',
    fontSize: '22px',
    lineHeight: '56px',
    color: '#000000',
    marginTop: '54px',
    '& > ::marker': {
      color: '#0AFB4C',
    },
  },
  listItem: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
  },
  smallItem: {
    display: 'flex',
    padding: '12px 0px 12px 40px',
  },
  sml: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    marginRight: '6px',
    minWidth: '40px',
  },
  smallContent: {
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#808080',
    '& > b': {
      color: 'black',
    },
  },
}));

const ComplaintPolicy = () => {
  const classes = useStyles();
  const { companyAddress, siteName } = useAppSelector<ISettings>((state) => state.users.settings);

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.headTitle}>Complaint Policy & Procedures</div>
        <div className={classes.paragraph}>
          Every {siteName} user has the right to complain, taking into account the following:
        </div>
        <ol start={1} className={classes.headline}>
          <li>Disputes</li>
        </ol>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.1</div>
          <div className={classes.smallContent}>
            <div>If You do not want other users to see Your Content, do not post it to {siteName}.</div>
            <div>
              If someone, based on Your personal information and/or personal content, created an account at our service,
              You have the right to inform our service about it through feedback with the current and dated
              video-message, attached to Your message (with the identity document presented in the camera frame), and we
              will deactivate this account within 14 calendar days.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.2</div>
          <div className={classes.smallContent}>
            <div>
              You acknowledge that all transactions involving Your profile on our web service and applications,
              including the purchase of goods or services, occur territorially in {companyAddress}.
            </div>
            <div>
              You agree that any disputes or claims, in any way connected with Your visit or use of the website and/or
              its services, or associated with the use of any products and/or services of the company {siteName}, You
              will first try to resolve these disputes informally by contacting us. If the dispute is not resolved
              within 30 days after Your appeal, You or our company must resolve any claims, related to these terms,
              services or web service of our company, by appealing to the arbitration court, located territorially at
              the location of our company. The decision of this court will be final and binding, unless You have the
              right to assert Your claims in a small lawsuit court.
            </div>
          </div>
        </div>
        <div className={classes.smallItem}>
          <div className={classes.sml}>1.3</div>
          <div className={classes.smallContent}>
            <div>Prohibition of group suits.</div>
            <div>
              You have the right to regulate the disputes with us individually but You have no right to take any legal
              actions both joint or group.
            </div>
          </div>
        </div>
        <div className={classes.breakline}></div>
      </div>
    </Container>
  );
};

export default ComplaintPolicy;
